<template>
  <div class="tailwind">
    <NotificationBar
      :messages="messages"
      position="bottom"
      :offset="20"
      @clear-message="clearMessage"
    ></NotificationBar>
    <div
      v-if="showFilters"
      ref="mobileFilterOverlay"
      class="max-sm:#fixed max-sm:#right-0 max-sm:#bg-[#24212b75] max-sm:#z-[4] max-sm:#top-[59px] max-sm:#h-full max-sm:#w-full max-sm:#transition-all max-sm:#opacity-0 max-sm:#hidden"
      :class="{ 'max-sm:#opacity-100 max-sm:!#block': showMobileFilters }"
      @click="closeMobileFilters($event)"
    >
      <div
        class="#flex #justify-between #items-end #px-[15px] md:#px-[30px] #my-[30px] max-sm:#bg-[#fff] max-sm:#block max-sm:#h-full max-sm:#ml-auto max-sm:#mr-0 max-sm:#mt-0 max-sm:#max-w-[320px] max-sm:#overflow-auto max-sm:#pb-[170px] max-sm:#pt-[30px] max-sm:#relative max-sm:#right-[0] max-sm:#w-full max-sm:#translate-x-full sm:#flex-wrap sm:#gap-x-4 sm:#gap-y-4"
        :class="{ 'max-sm:!#translate-x-0': showMobileFilters }"
      >
        <div
          class="#flex #flex-col #flex-1 sm:#flex-row #justify-start #items-start sm:#items-center #gap-x-8 #gap-y-8"
        >
          <div class="#flex #justify-between #items-center #w-full sm:#hidden">
            <p class="#text-xl #font-medium">
              {{ __("Filteren") }}
            </p>
            <svg-use
              id="xmark"
              type="solid"
              class="#w-[1em] #h-[1em] #fill-inkdropdark #box-content #p-[5px] #right-0 #left-auto #ml-auto #rounded-[4px] #cursor-pointer hover:#fill-vividmagenta"
              @click="showMobileFilters = false"
            ></svg-use>
          </div>
          <div class="#w-full sm:#max-w-[210px]">
            <p class="#mb-2 #font-medium">
              {{ __("Status") }}
            </p>
            <InputDropdown
              v-model="sortByStatus"
              :items="statusSortingOptions"
              class="#w-full"
            ></InputDropdown>
          </div>
          <div>
            <p class="#mb-2 #font-medium">
              {{ __("Datum") }}
            </p>
            <div class="#flex #flex-col sm:#flex-row #justify-start #items-end #gap-x-8 #gap-y-8">
              <div class="#flex #items-end #justify-start #gap-x-4">
                <DatePicker
                  ref="daterangefrom"
                  v-model="sortByDate.from"
                  :placeholder="__('Van')"
                  :max-date="sortByDate.to"
                ></DatePicker>
                <DatePicker
                  ref="daterangeto"
                  v-model="sortByDate.to"
                  :placeholder="__('Tot')"
                  :min-date="sortByDate.from"
                ></DatePicker>
              </div>
              <div class="#w-full #block sm:#hidden">
                <p class="#mb-2 #font-medium">
                  {{ __("Sorteren") }}
                </p>
                <InputDropdown
                  v-model="mobileSorting"
                  :items="sortingDropdownOptions"
                  class="#w-full"
                ></InputDropdown>
              </div>
              <div class="#flex #gap-x-4">
                <Button
                  :text="__('Filter')"
                  btn-class="secondary"
                  :disabled="gettingOrders"
                  @click="filterOrders"
                ></Button>
                <Button v-if="activeFilters" btn-class="grey" @click="deleteFilters">
                  <svg-use
                    id="xmark"
                    type="solid"
                    class="#fill-inkdropdark #h-[0.9em] !#w-[0.9em]"
                  ></svg-use>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Button text="CSV" btn-class="grey" class="#hidden sm:#block" :href="csvUrl">
          <svg-use
            id="arrow-down-to-bracket"
            type="regular"
            class="#inline #fill-inkdropdark #h-[0.9em] !#w-[0.9em] #mt-[-2px]"
          ></svg-use>
        </Button>
      </div>
    </div>
    <div
      v-if="showFilters"
      class="#group #flex #items-center #right-0 #left-auto #ml-auto #w-max #mr-[15px] #cursor-pointer sm:#hidden"
      @click="showMobileFilters = !showMobileFilters"
    >
      <p
        class="#border-b-[1px] #border-dotted #border-transparent group-hover:#text-vividmagenta group-hover:#border-current"
      >
        {{ __("Filteren") }}
      </p>
      <svg-use
        id="filter-list"
        type="solid"
        class="#w-[1em] #h-[1em] #fill-inkdropdark #box-content #px-[2px] #pt-[3px] #rounded-[4px] group-hover:#fill-vividmagenta"
      ></svg-use>
    </div>
    <table class="#border-separate #overflow-hidden #table-fixed">
      <thead>
        <tr>
          <th class="#pb-[5px] #w-[55%] #min-w-0 sm:#w-[45%] #pl-[15px] sm:#pl-[30px]">
            {{ __("Overzicht") }}
          </th>
          <th class="#pb-[5px] #hidden sm:#table-cell #w-1/5 min-[992px]:#w-[16%]">
            <div
              class="#flex #justify-start #items-center #gap-x-2"
              :class="{ '#group #cursor-pointer': showFilters }"
              @click="sortOrders('posted_datetime')"
            >
              <p
                class="#border-dotted #border-transparent #border-b-[1px] group-hover:#border-current"
              >
                {{ __("Datum") }}
              </p>
              <div
                v-if="showFilters"
                class="#flex #flex-col #items-center #justify-center #mt-[4px]"
              >
                <svg-use
                  id="angle-up"
                  type="regular"
                  class="#w-[1em] #h-[0.7em] #fill-inkdropdark #box-content #px-[2px] #pt-[3px] #rounded-[4px] group-hover:#fill-vividmagenta group-hover:#bg-inkdroplight"
                  :class="{
                    '#fill-vividmagenta #bg-inkdroplight':
                      filters.column === 'posted_datetime' && filters.direction === 'asc',
                  }"
                ></svg-use>
                <svg-use
                  id="angle-down"
                  type="regular"
                  class="#w-[1em] #h-[0.7em] #fill-inkdropdark #box-content #px-[2px] #pb-[3px] #rounded-[4px] group-hover:#fill-vividmagenta group-hover:#bg-inkdroplight"
                  :class="{
                    '#fill-vividmagenta #bg-inkdroplight':
                      filters.column === 'posted_datetime' && filters.direction === 'desc',
                  }"
                ></svg-use>
              </div>
            </div>
          </th>
          <th class="#pb-[5px] #w-[45%] sm:#w-[35%] min-[992px]:#w-[24%]">Status</th>
          <th class="#pb-[5px] #hidden #w-[15%] min-[992px]:#table-cell">
            <div
              class="#flex #justify-start #items-center #gap-x-2"
              :class="{ '#group #cursor-pointer': showFilters }"
              @click="sortOrders('amount')"
            >
              <p
                class="#border-dotted #border-transparent #border-b-[1px] group-hover:#border-current"
              >
                {{ __("Bedrag") }}
              </p>
              <div
                v-if="showFilters"
                class="#flex #flex-col #items-center #justify-center #mt-[4px]"
              >
                <svg-use
                  id="angle-up"
                  type="regular"
                  class="#w-[1em] #h-[0.7em] #fill-inkdropdark #box-content #px-[2px] #pt-[3px] #rounded-[4px] group-hover:#fill-vividmagenta group-hover:#bg-inkdroplight"
                  :class="{
                    '#fill-vividmagenta #bg-inkdroplight':
                      filters.column === 'amount' && filters.direction === 'asc',
                  }"
                ></svg-use>
                <svg-use
                  id="angle-down"
                  type="regular"
                  class="#w-[1em] #h-[0.7em] #fill-inkdropdark #box-content #px-[2px] #pb-[3px] #rounded-[4px] group-hover:#fill-vividmagenta group-hover:#bg-inkdroplight"
                  :class="{
                    '#fill-vividmagenta #bg-inkdroplight':
                      filters.column === 'amount' && filters.direction === 'desc',
                  }"
                ></svg-use>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody v-if="orders && !gettingOrders">
        <OrderRow
          v-for="order in orders"
          :key="order.id"
          :order="order"
          :open="autoOpen ? true : openRow === order.id"
          :auto-open="autoOpen"
          @click="toggleThisRow($event, order.id)"
          @message="addMessage"
        >
        </OrderRow>
      </tbody>
      <tbody v-if="loading">
        <tr v-for="(loader, i) in loadingRows" :key="i" class="#h-[90px]">
          <td class="#pl-[15px] md:#pl-[30px] #border-t-[1px] #border-solid #border-[#d5d5d5]">
            <div class="#flex #justify-start #items-center #gap-x-[20px]">
              <div
                class="#w-20 #h-24 #rounded-[4px] #overflow-hidden #border #border-solid #border-inkdroplight"
              >
                <div class="#relative #grid #w-full #h-full #bg-[#d5d5d5] #gap-[1px] #grid-cols-1">
                  <div class="#relative #w-full #h-full form-loader-animation"></div>
                </div>
              </div>
              <div class="#space-y-2 #w-full #max-w-[calc(100%-78px)] #pr-4">
                <div class="#w-2/5 #min-w-0 #h-6 #rounded-[4px] form-loader-animation"></div>
                <div class="#w-4/5 #min-w-0 #h-6 #rounded-[4px] form-loader-animation"></div>
              </div>
            </div>
          </td>
          <td class="#hidden sm:#table-cell #border-t-[1px] #border-solid #border-[#d5d5d5]">
            <div class="#w-40 #min-w-0 #h-6 #rounded-[4px] form-loader-animation"></div>
          </td>
          <td class="#border-t-[1px] #border-solid #border-[#d5d5d5]">
            <div
              class="#h-4 #w-[100px] min-[450px]:#w-[150px] #relative #rounded-full #mb-3 form-loader-animation"
            ></div>
            <div class="#w-40 #min-w-0 #h-6 #rounded-[4px] form-loader-animation"></div>
          </td>
          <td
            class="#hidden min-[992px]:#table-cell #pr-[15px] md:#pr-[30px] #border-t-[1px] #border-solid #border-[#d5d5d5]"
          >
            <div class="#w-32 #min-w-0 #h-6 #rounded-[4px] form-loader-animation"></div>
          </td>
        </tr>
      </tbody>
    </table>
    <ToolTip ref="orderAgain">
      <div class="#max-w-[215px]">
        <p class="#mb-4">{{ __("Aantal") }}</p>
        <div class="#flex #justify-between #gap-x-4">
          <InputField
            v-model="orderAgain.quantity"
            class="#max-w-[80px]"
            :input-class="{ '!#border-[#d9534f]': orderAgain.article.missing || orderAgain.error }"
            @keyup.enter="reorder"
          ></InputField>
          <Button
            :text="__('Toevoegen')"
            btn-class="secondary"
            :disabled="orderAgain.loading"
            @click="reorder"
          >
            <svg-use
              id="cart-shopping"
              type="solid"
              class="#h-[1em] #w-[1em] #fill-white #mt-[6px]"
            >
            </svg-use>
          </Button>
        </div>
        <p
          v-if="orderAgain.article.missing"
          class="#w-full #mt-2 #text-sm #text-[#d9534f] #font-regular"
        >
          <span class="#font-medium">{{ __("Let op!") }}</span>
          {{ __("Er ontbreken bestanden bij dit artikel. Controleer de opdracht goed.") }}
        </p>
        <p v-if="orderAgain.error" class="#w-full #mt-2 #text-sm #text-[#d9534f] #font-regular">
          {{ orderAgain.error }}
        </p>
      </div>
    </ToolTip>
    <LightBox ref="lightbox"></LightBox>
    <PaginationButtons
      class="#my-6"
      :pages="pages"
      :page="currentPage"
      :disabled="gettingOrders"
      @change="goToPage"
    ></PaginationButtons>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SvgUse from "../components/SvgUse.vue";
import Button from "../components/Button.vue";
import OrderRow from "../components/OrderRow.vue";
import InputDropdown from "../components/InputDropdown.vue";
import DatePicker from "../components/DatePicker.vue";
import NotificationBar from "../components/NotificationBar.vue";
import { lang } from "../mixins";
import PaginationButtons from "../components/PaginationButtons.vue";
import ToolTip from "../components/ToolTip.vue";
import InputField from "../components/InputField.vue";
import LightBox from "../components/LightBox.vue";
import { removeNotNumbers } from "../helpers.js";

export default {
  components: {
    SvgUse,
    Button,
    OrderRow,
    InputDropdown,
    DatePicker,
    NotificationBar,
    PaginationButtons,
    ToolTip,
    InputField,
    LightBox,
  },
  mixins: [lang],
  provide() {
    return {
      toggleReOrder: this.toggleReOrder,
      openLightbox: this.openLightbox,
      showDetails: this.showDetails,
    };
  },
  props: {
    showFilters: {
      type: Boolean,
      default: true,
    },
    showDetails: {
      type: Boolean,
      default: true,
    },
    autoOpen: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingRows: {
      type: Number,
      default: 5,
    },
    fetchOrders: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      orders: null,
      openRow: null,
      filters: {
        from: null,
        to: null,
        column: "id",
        direction: "desc",
        status: null,
      },
      sortByStatus: null,
      sortByDate: {
        from: null,
        to: null,
      },
      statusSortingOptions: {
        alle: "Alle",
      },
      gettingOrders: false,
      showMobileFilters: false,
      sortingDropdownOptions: {
        date_descending: "Datum(aflopend)",
        date_ascending: "Datum(oplopend)",
        price_descending: "Prijs(aflopend)",
        price_ascending: "Prijs(oplopend)",
      },
      mobileSorting: null,
      currentPage: 1,
      pages: null,
      messages: [],
      orderAgain: {
        error: null,
        quantity: 0,
        loading: false,
        article: {
          id: null,
          missing: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["route"]),
    firstPage() {
      return this.currentPage === 1;
    },
    lastPage() {
      return this.currentPage === this.pages;
    },
    csvUrl() {
      let route = this.route("orders.download") + "?download_csv=1";

      if (this.filters.from) {
        route += `&from=${this.filters.from}`;
      }

      if (this.filters.to) {
        route += `&to=${this.filters.to}`;
      }

      return route;
    },
    activeFilters() {
      if (
        !this.filters.from &&
        !this.filters.to &&
        this.filters.column === "id" &&
        this.filters.status === null
      ) {
        return false;
      }

      return true;
    },
  },
  watch: {
    "orderAgain.quantity"(newValue) {
      this.orderAgain.error = null;
      this.orderAgain.quantity = removeNotNumbers(newValue.toString());
    },
  },
  methods: {
    async getOrders() {
      if (this.mobileSorting === "date_descending") {
        this.filters.column = "posted_datetime";
        this.filters.direction = "desc";
      } else if (this.mobileSorting === "date_ascending") {
        this.filters.column = "posted_datetime";
        this.filters.direction = "asc";
      } else if (this.mobileSorting === "price_descending") {
        this.filters.column = "amount";
        this.filters.direction = "desc";
      } else if (this.mobileSorting === "price_ascending") {
        this.filters.column = "amount";
        this.filters.direction = "asc";
      }

      let obj = {
        from: this.sortByDate.from ? Math.floor(this.sortByDate.from.getTime() / 1000) : null,
        to: this.sortByDate.to ? Math.floor(this.sortByDate.to.getTime() / 1000) : null,
        column: this.filters.column,
        direction: this.filters.direction,
        status: this.sortByStatus,
        page: this.currentPage,
      };

      this.orders = [];

      let response = await this.fetchOrders(obj);

      if (response.ok) {
        let object = await response.json();
        this.statusSortingOptions = object.statuses;
        this.orders = object.orders;
        this.pages = object.number_of_pages;
        this.currentPage = object.current;
        this.showMobileFilters = false;
        this.mobileSorting = null;
        this.filters.status = obj.status;
        this.filters.from = obj.from;
        this.filters.to = obj.to;
      }
    },
    async deleteFilters() {
      this.gettingOrders = true;
      Object.keys(this.filters).forEach((i) => {
        this.filters[i] = null;
      });
      this.sortByStatus = null;
      this.filters.column = "id";
      this.filters.direction = "desc";
      this.sortByDate.from = null;
      this.sortByDate.to = null;
      this.currentPage = 1;
      this.$refs.daterangeto.clearAll();
      this.$refs.daterangefrom.clearAll();
      await this.getOrders();
      this.gettingOrders = false;
    },
    async sortOrders(column) {
      if (!this.showFilters) {
        return;
      }
      this.gettingOrders = true;
      if (column === this.filters.column && this.filters.direction === "desc") {
        this.filters.direction = "asc";
      } else {
        this.filters.direction = "desc";
      }
      this.filters.column = column;
      this.currentPage = 1;
      await this.getOrders();
      this.gettingOrders = false;
    },
    async filterOrders() {
      this.gettingOrders = true;
      this.currentPage = 1;
      await this.getOrders();
      this.gettingOrders = false;
    },
    toggleThisRow(e, order) {
      if (order === this.openRow) {
        this.openRow = null;
      } else {
        this.openRow = order;
      }
    },
    closeMobileFilters(e) {
      if (e.target === this.$refs.mobileFilterOverlay) {
        this.showMobileFilters = false;
      }
    },
    async goToPage(page) {
      this.currentPage = page;
      this.gettingOrders = true;
      window.scrollTo(0, 0);
      await this.getOrders();
      this.gettingOrders = false;
    },
    nextPage() {
      this.currentPage = this.currentPage + 1;
      this.getOrders();
    },
    previousPage() {
      if (this.currentPage === 0) {
        return;
      }
      this.currentPage = this.currentPage - 1;
      this.getOrders();
    },
    addMessage(obj) {
      this.messages.push(obj);
    },
    clearMessage(id) {
      this.messages = this.messages.filter((message) => {
        return id !== message.id;
      });
    },
    toggleReOrder(anchor, id, quantity, missing) {
      this.$refs.orderAgain.toggle(anchor);
      this.orderAgain.article.id = id;
      this.orderAgain.article.missing = missing;
      this.orderAgain.quantity = quantity;
    },
    createMessage(message) {
      let obj = {
        label: message,
        id: Date.now() + Math.floor(Math.random() * 1000),
      };
      this.addMessage(obj);
    },
    async reorder() {
      if (/^\d+$/.test(this.orderAgain.quantity) === false && this.orderAgain.quantity) {
        this.orderAgain.error = this.__("De oplage moet een heel getal zijn.");
        return;
      }
      if (this.orderAgain.quantity <= 0) {
        this.orderAgain.error = this.__("De oplage moet minimaal 1 zijn.");
        return;
      }
      this.orderAgain.error = null;

      const obj = {
        article: this.orderAgain.article.id,
        quantity: this.orderAgain.quantity,
        missing: this.orderAgain.article.missing,
      };
      this.orderAgain.loading = true;
      let response = await fetch(this.route("articles.reorder"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(obj),
      });
      if (response.ok) {
        response = await response.json();
        await this.emitter.emit("refresh-cart");
        this.createMessage(response.message);
        this.$refs.orderAgain.closePopup();
      } else if (!response.ok) {
        await response.text();
      }
      this.orderAgain.loading = false;
    },
    openLightbox(article, images) {
      if (article.missing || !article.img) {
        return;
      }
      this.$refs.lightbox.open(article.img, images);
    },
  },
};
</script>
