<template>
  <span class="spinner" :class="s.container">
    <span class="rect1" />
    <span class="rect2" />
    <span class="rect3" />
  </span>
</template>

<style module="s">
.container {
  display: block;
}
</style>
