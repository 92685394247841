import isEqual from "lodash.isequal";
import { useStore } from "vuex";

export default {
  namespaced: true,
  state: () => ({
    initialized: false,
    tooltip: {
      open: false,
      title: "",
      content: "",
      anchor: null,
    },
    form: {},
    fileinfo: {},
    checks: [],
  }),
  mutations: {
    isInitialized(state) {
      state.initialized = true;
    },
    openTooltip(state, payload) {
      state.tooltip.title = payload.title;
      state.tooltip.content = payload.content;
      state.tooltip.anchor = payload.anchor;
      state.tooltip.open = true;
    },
    closeTooltip(state) {
      state.tooltip.open = false;
    },
    updateItem(state, payload) {
      if (state.initialized && state.form[payload.name] != payload.value) {
        console.log({ name: payload.name, from: state.form[payload.name], to: payload.value });
      }
      state.form[payload.name] = payload.value;
    },
    deleteItem(state, name) {
      if (state.initialized && state.form[name] !== undefined) {
        console.log({ name: name, from: state.form[name], to: undefined });
      }
      delete state.form[name];
    },
    setFileinfo(state, payload) {
      if (isEqual(payload, state.fileinfo)) {
        return;
      }

      Object.keys(state.fileinfo).forEach((key) => delete state.fileinfo[key]);
      Object.keys(payload).forEach((key) => (state.fileinfo[key] = payload[key]));
    },
    deleteFileinfo(state) {
      if (Object.keys(state.fileinfo).length === 0) {
        return;
      }

      Object.keys(state.fileinfo).forEach((key) => delete state.fileinfo[key]);
    },
    addCheck(state, payload) {
      const duplicate = state.checks.find((item) => {
        if (item.option.key !== payload.option.key) {
          return false;
        }
        if (item.section !== payload.section) {
          return false;
        }
        if (item.fn.toString() !== payload.fn.toString()) {
          return false;
        }

        return true;
      });

      if (duplicate) {
        return;
      }

      state.checks.push(payload);
    },
  },
  getters: {
    getValue:
      (state) =>
      (option, fallback = null) => {
        if (typeof option === "string") {
          return state.form[option] ?? fallback;
        }

        return state.form[option.key] ?? fallback;
      },
    getFileinfo: (state) => () => {
      return state.fileinfo;
    },
    isChecked: (state, getters) => (option) => {
      if (!option.value) {
        return false;
      }

      return getters.getValue(option) === option.value;
    },
    isEmpty: (state, getters) => (option) => {
      return getters.getValue(option) === null || getters.getValue(option) === undefined;
    },
    isInt: (state, getters) => (option) => {
      const val = getters.getValue(option);

      return Number.isInteger(val) && val < Number.MAX_SAFE_INTEGER;
    },
    isString: (state, getters) => (option) => {
      const val = getters.getValue(option);

      return typeof val === "string";
    },
  },
};

export function useOrderForm() {
  const store = useStore();

  return {
    getValue: store.getters["order/getValue"],
    getFileinfo: store.getters["order/getFileinfo"],
    isChecked: store.getters["order/isChecked"],
    isEmpty: store.getters["order/isEmpty"],
    isInt: store.getters["order/isInt"],
    isString: store.getters["order/isString"],
    isInitialized: () => store.commit("order/isInitialized"),
    updateItem: (payload) => store.commit("order/updateItem", payload),
    deleteItem: (name) => store.commit("order/deleteItem", name),
    openTooltip: (payload) => store.commit("order/openTooltip", payload),
    setFileinfo: (payload) => store.commit("order/setFileinfo", payload),
    deleteFileinfo: () => store.commit("order/deleteFileinfo"),
    addCheck: (payload) => store.commit("order/addCheck", payload),
  };
}
