<template>
  <CarouselWidget
    :items="products"
    class="#w-full #justify-between"
    :container-classes="'#max-w-[1180px]'"
  >
    <a
      v-for="product in products"
      :key="product.title"
      :href="product.alternative_link"
      class="#relative #p-[5px] #rounded-[6px] #overflow-hidden #min-w-full #w-1/4 sm:#min-w-[50%] lg:#min-w-[25%] #aspect-square #group"
    >
      <img
        :src="product.image"
        alt=""
        class="#w-full #rounded-[6px] #relative #object-cover #z-0 #left-0 #bottom-0"
      />
      <div class="#flex #justify-start #items-start #gap-x-4 #mt-6">
        <h4
          class="#z-[2] #relative #font-bold #text-xl #text-inkdropblack group-hover:#text-vividmagenta"
        >
          {{ product.title }}
        </h4>
        <svg-use
          id="angle-right"
          type="regular"
          class="#inline-block #mt-[4px] #w-[1em] #h-[1.5em] #fill-vividmagenta #opacity-0 group-hover:#opacity-100"
        />
      </div> </a
  ></CarouselWidget>
</template>

<script>
import SvgUse from "../components/SvgUse.vue";
import CarouselWidget from "../components/CarouselWidget.vue";
import { mobile } from "../mixins";

export default {
  components: {
    SvgUse,
    CarouselWidget,
  },
  mixins: [mobile],
  data() {
    return {
      products: window.featured_products_carousel || [],
    };
  },
};
</script>
