<script setup>
import { computed, ref, useTemplateRef } from "vue";
import { useOptionProps } from "./composables/props";
import { useOrderForm } from "../modules/orderModule";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    default: null,
  },
  ...useOptionProps(),
});

const form = useOrderForm();

const tooltipHover = ref(false);

const rowClasses = computed(() => {
  let classes = {
    "big-spacing-top": props.first,
    "border-bottom": props.divider && props.last,
  };

  if (props.name && props.value) {
    classes[`tr_${props.name}_${props.value}`] = true;
  } else if (props.name) {
    classes[`tr_${props.name}`] = true;
  }

  return classes;
});

const container = useTemplateRef("container");
defineExpose({ container });

const openTooltip = () => {
  form.openTooltip({
    title: props.tooltipTitle,
    content: props.tooltipContent,
    anchor: container.value,
  });
};
</script>

<template>
  <tr
    ref="container"
    :class="rowClasses"
    @mouseover="tooltipHover = true"
    @mouseleave="tooltipHover = false"
  >
    <td width="24%">
      {{ first ? section : "" }}
    </td>
    <td width="57%">
      <slot></slot>
    </td>
    <td width="19%">
      <a
        v-if="props.tooltipTitle"
        tabindex="-1"
        href="javascript:;"
        class="infobtnleft open-popover infoBoxTxt_sizeA6"
        @click.prevent="openTooltip"
      >
        <i
          :style="{ display: tooltipHover ? 'block' : 'none' }"
          class="far fa-info-circle info-image"
        ></i>
      </a>
    </td>
  </tr>
</template>
