<template>
  <h3 class="#font-serif #text-lg #mb-[10px]">
    {{ __("Facturen") }}
  </h3>
  <div v-if="invoices.length > 0">
    <a
      v-for="invoice in invoices"
      :key="invoice.invoice_id"
      :href="invoice.link"
      class="#group #p-3 #-mx-3 #flex #items-center #flex-wrap #gap-x-3 #cursor-pointer #transition-all #rounded-[4px] hover:#bg-inkdroplight"
    >
      <div class="#flex #items-center #justify-start #gap-x-2">
        <svg-use
          id="file-lines"
          type="solid"
          class="#w-[1em] #h-[1em] #fill-inkdropdark #transition-all group-hover:#fill-vividmagenta"
        ></svg-use>
        <p class="#transition-all group-hover:#text-vividmagenta #inline-block #w-28">
          {{ invoice.invoice_id }}
        </p>
      </div>
      <p :class="invoice.amount_left === 0 ? '#text-[#707070]' : '#text-[#eb980c]'">
        {{ invoice.amount_left === 0 ? __("Betaald") : __("Openstaand") }}
      </p>
      <p v-if="invoice.your_ref == orderId" class="#text-right">
        €{{ invoice.amount_left === 0 ? invoice.amount_total_incl : invoice.amount_left }}
      </p>
      <p v-else class="#text-[12px]">(verzamelfactuur)</p>
    </a>
  </div>
  <p v-else-if="invoices.length === 0 && loaded" class="#text-[#707070] #text-sm">
    {{ __("Er zijn geen facturen gevonden") }}
  </p>
  <a
    v-else-if="!loadingInvoices && !loaded"
    href="javascript:;"
    class="#border-b-[1px] #border-dotted #border-current hover:!#text-vividmagenta"
    @click="$emit('click')"
  >
    {{ __("Bekijk mijn facturen") }}
  </a>
  <LoaderSpinner v-if="loadingInvoices"></LoaderSpinner>
</template>

<script>
import LoaderSpinner from "./LoaderSpinner.vue";
import { lang } from "../mixins";
import SvgUse from "../components/SvgUse.vue";

export default {
  components: {
    LoaderSpinner,
    SvgUse,
  },
  mixins: [lang],
  props: {
    invoices: {
      type: Array,
      default: () => [],
    },
    loadingInvoices: {
      type: Boolean,
      default: false,
    },
    loaded: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: Number,
      default: 0
    }
  },
  emits: ["click"],
};
</script>
