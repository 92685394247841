<template>
  <NotificationBar
    :messages="messages"
    :errors="errorMessages"
    :offset="notificationBarOffset"
    position="bottom"
    @clear-all-messages="clearMessages"
    @clear-message="clearMessage"
  ></NotificationBar>
  <div class="#max-w-[1200px] #box-content #mx-auto #mb-[15px]">
    <WhiteContainer>
      <OrderProgress :current-page="4"></OrderProgress>
    </WhiteContainer>
  </div>
  <div
    class="#flex #flex-col #items-start #gap-x-[15px] #gap-y-[15px] #max-w-[1200px] #box-content #mx-auto md:#flex-row"
  >
    <div class="#w-full #flex #flex-col #gap-y-[15px]">
      <WhiteContainer>
        <h3 class="#font-medium #text-[24px] #mb-[10px]">
          {{ __("Betaalmethode") }}
        </h3>
        <form ref="payment_form" method="POST">
          <PaymentMethod
            v-for="(option, index) in paymentOptions"
            :key="index"
            v-model:selected-method="selectedPaymentMethod"
            v-model:selected-bank="selectedBank"
            :option="option"
            :bank-list="bankList"
            :on-account-status="onAccountStatus"
          ></PaymentMethod>
        </form>
        <div class="#mt-8 md:#hidden">
          <CouponCode
            ref="coupon_code_mobile"
            v-model="form.coupon"
            :error="couponError[0]"
            @submit="postCouponCode"
          ></CouponCode>
          <InvoiceOptions
            v-model:reference="invoiceReference"
            v-model:copy="invoiceCopy"
            :customer="customer"
            :reference-error="firstError('reference')"
          ></InvoiceOptions>
          <div
            v-if="coupon.code"
            class="#flex #items-center #justify-between #my-4 #bg-inkdroplight #p-[10px] #rounded-[4px] #text-sm"
          >
            <div>
              <p>
                {{ __("Kortingscode") }} <span class="#font-medium">{{ coupon.code }}</span>
              </p>
            </div>
            <a href="javascript:;" @click="destroyCouponCode">
              <svg-use
                id="trash"
                type="solid"
                class="#blcok #w-[1em] #h-[1em] #mt-[2px] #fill-red-600 #cursor-pointer #transition-all hover:!#opacity-50"
              />
            </a>
          </div>
          <div class="#space-y-3 #mt-14 #mb-6">
            <div v-if="showTerms" ref="mobileTerms" class="#scroll-mt-[120px]">
              <CheckBox v-model="agreeTerms" :error="termsError">
                <span>
                  {{ __("Ik ga akkoord met de") }}
                  <a
                    href="/voorwaarden"
                    target="blank"
                    class="#border-dotted #border-b-[1px] #border-b-current hover:#text-vividmagenta"
                  >
                    {{ __("voorwaarden") }}
                  </a>
                </span>
                <span class="#text-vividmagenta">*</span></CheckBox
              >
            </div>
            <CheckBox v-model="newsLetter">
              <span>{{ __("Nieuwsbrief ontvangen") }}</span>
            </CheckBox>
          </div>
          <Button
            :text="paymentButtonText"
            btn-class="secondary"
            size="lg"
            :disabled-styling="
              (selectedPaymentMethod === 'account' && onAccountStatus < 1) ||
              errorMessages.length > 0 ||
              !selectedPaymentMethod
            "
            :disabled="processing"
            @click="postForm"
          ></Button>
          <p v-if="termsError" class="#text-sm #font-medium #text-[#d9534f] #mt-2">
            {{ __("Je moet eerst akkoord gaan met de voorwaarden") }}
          </p>
        </div>
      </WhiteContainer>
      <WhiteContainer class="#block md:#hidden">
        <h3 class="#font-serif #text-lg #mb-[10px]">
          {{ __("Verwachte leverdatum") }}
        </h3>
        <p>{{ deliveryTimeslot }}</p>
      </WhiteContainer>
      <WhiteContainer class="#block md:#hidden">
        <a
          :href="route('article3')"
          class="#group #inline-flex #justify-start #items-center #gap-x-4 #mb-[10px]"
        >
          <h3 class="#font-serif #text-lg">
            {{ __("Afleveradres") }}
          </h3>
          <svg-use
            id="pen"
            type="solid"
            class="#h-[15px] #w-[15px] #fill-inkdropdark group-hover:#fill-vividmagenta"
          />
        </a>
        <div class="#flex #justify-between #items-center #mb-[20px]">
          <div class="#text-sm #leading-7 #space-y-2">
            <p v-for="(deliveryLine, index) in deliveryData" :key="index" v-html="deliveryLine"></p>
          </div>
        </div>
        <a
          :href="route('article2')"
          class="#group #inline-flex #justify-start #items-center #gap-x-4 #mb-[10px]"
        >
          <h3 class="#font-serif #text-lg">
            {{ __("Factuurgegevens") }}
          </h3>
          <svg-use
            id="pen"
            type="solid"
            class="#h-[15px] #w-[15px] #fill-inkdropdark group-hover:#fill-vividmagenta"
          />
        </a>
        <div class="#flex #justify-between #items-center #mb-[20px]">
          <div class="#text-sm #leading-8">
            <p v-for="(invoiceLine, index) in invoiceData" :key="index" v-html="invoiceLine"></p>
          </div>
        </div>
      </WhiteContainer>
      <WhiteContainer>
        <h3 class="#font-serif #text-lg #mb-[10px]">
          {{ __("Artikelen") }}
        </h3>
        <div>
          <div
            v-for="article in articles"
            :key="article.id"
            class="#flex #items-start #justify-between #gap-[30px] #py-[30px] #border-t #border-[#d5d5d5] first:#border-t-transparent"
          >
            <div class="#hidden #w-[70px] #min-w-[70px] md:#block">
              <img
                :src="
                  article.files.filenames[0]
                    ? article.files.filenames[0].src
                    : '/img/cart-empty-document.png'
                "
                alt=""
                class="#w-full"
              />
              <p v-if="article.files.filenames.length > 0" class="#text-xs">
                {{ __("Voorbeeld is indicatief, kleur kan afwijken") }}
              </p>
            </div>
            <div class="#w-full">
              <div class="#flex #f #items-start #justify-between #gap-x-[15px] md:#flex-row">
                <div>
                  <div class="#flex #justify-between #items-start #w-full #gap-x-2">
                    <div>
                      <h4 class="#font-medium">
                        {{ __("Artikel") }} {{ article.id }} - {{ article.product }}
                      </h4>
                      <p class="#text-sm #mb-[10px]">
                        {{ article.number }} {{ article.number_text }}
                      </p>
                    </div>
                    <div class="#flex #justify-end #items-center #gap-x-4">
                      <a
                        :href="`order?articleID=${article.id}&action=delete`"
                        class="#p-3 #group #cursor-pointer"
                      >
                        <svg-use
                          id="trash"
                          type="solid"
                          class="#w-[13.5px] #h-[13.5px] #fill-inkdropdark group-hover:#fill-red-600"
                        ></svg-use>
                      </a>
                      <Button :text="__('Wijzigen')" btn-class="grey" size="xs" :href="article.url">
                        <svg-use
                          id="pen"
                          type="solid"
                          class="#w-[0.9em] #h-[0.9em] #fill-inherit #inline #ml-4"
                        ></svg-use>
                      </Button>
                    </div>
                  </div>
                  <div class="#flex #justify-between #w-full">
                    <div>
                      <div class="#block md:#hidden">
                        <img
                          :src="
                            article.files.filenames[0]
                              ? article.files.filenames[0].src
                              : '/img/cart-empty-document.png'
                          "
                          alt=""
                          class="#w-full #max-w-[100px]"
                        />
                        <p v-if="article.files.filenames.length" class="#text-xs">
                          {{ __("Voorbeeld is indicatief, kleur kan afwijken") }}
                        </p>
                      </div>
                      <p class="#mt-4" v-html="article.summary"></p>
                    </div>
                    <div class="md:#min-w-[150px] md:#w-[150px]" />
                  </div>
                </div>
              </div>
              <DeliveryTerms :specs="article.specs.document"></DeliveryTerms>
            </div>
          </div>
        </div>
      </WhiteContainer>
    </div>
    <div class="#w-full #flex #items-start #flex-col #gap-y-[15px] #gap-x-[15px] md:#w-[450px]">
      <WhiteContainer class="#hidden md:#block">
        <div id="tablePriceDelivery"></div>
        <h3 class="#font-serif #text-lg #mb-[10px]">
          {{ __("Prijs") }}
        </h3>
        <PriceDetails :price-data="priceData"></PriceDetails>
        <div>
          <div class="#border-t #border-b #border-[#d5d5d5] #py-4 #my-8">
            <CouponCode
              ref="coupon_code"
              v-model="form.coupon"
              :error="couponError[0]"
              @submit="postCouponCode"
            ></CouponCode>
            <InvoiceOptions
              v-model:reference="invoiceReference"
              v-model:copy="invoiceCopy"
              :customer="customer"
              :reference-error="firstError('reference')"
            ></InvoiceOptions>
          </div>
          <div
            v-if="coupon.code"
            class="#flex #items-center #justify-between #mt-8 #mb-4 #bg-inkdroplight #p-[10px] #rounded-[4px] #text-sm"
          >
            <div>
              <p>
                {{ __("Kortingscode") }} <span class="#font-medium">{{ coupon.code }}</span>
              </p>
            </div>
            <a href="javascript:;" @click="destroyCouponCode">
              <svg-use
                id="trash"
                type="solid"
                class="#block #w-[1em] #h-[1em] #mt-[2px] #fill-red-600 #cursor-pointer #transition-all hover:!#opacity-50"
              />
            </a>
          </div>

          <div class="#space-y-2 #mb-4">
            <div v-if="showTerms">
              <CheckBox v-model="agreeTerms" :error="termsError">
                <span>
                  {{ __("Ik ga akkoord met de") }}
                  <a
                    href="/voorwaarden"
                    target="blank"
                    class="#border-dotted #border-b-[1px] #border-b-current hover:#text-vividmagenta"
                  >
                    {{ __("voorwaarden") }}
                  </a>
                </span>
                <span class="#text-vividmagenta">*</span></CheckBox
              >
            </div>
            <CheckBox v-model="newsLetter">
              <span>{{ __("Nieuwsbrief ontvangen") }}</span>
            </CheckBox>
          </div>
          <div class="#w-full">
            <Button
              :text="paymentButtonText"
              btn-class="secondary"
              size="lg"
              :disabled-styling="
                (selectedPaymentMethod === 'account' && onAccountStatus < 1) ||
                errorMessages.length > 0 ||
                !selectedPaymentMethod
              "
              class="#text-center #w-full"
              :disabled="processing"
              @click="postForm"
            ></Button>
            <p v-if="termsError" class="#text-sm #font-medium #text-[#d9534f] #mt-2">
              {{ __("Je moet eerst akkoord gaan met de voorwaarden") }}
            </p>
          </div>
        </div>
      </WhiteContainer>
      <WhiteContainer class="#hidden md:#block">
        <h3 class="#font-serif #text-lg #mb-[10px]">
          {{ __("Verwachte leverdatum") }}
        </h3>
        <p>{{ deliveryTimeslot }}</p>
      </WhiteContainer>
      <WhiteContainer class="#hidden md:#block">
        <a
          :href="route('article3')"
          class="#group #inline-flex #justify-start #items-center #gap-x-4 #mb-[10px]"
        >
          <h3 class="#font-serif #text-lg">
            {{ __("Afleveradres") }}
          </h3>
          <svg-use
            id="pen"
            type="solid"
            class="#h-[15px] #w-[15px] #fill-inkdropdark group-hover:#fill-vividmagenta"
          />
        </a>
        <div class="#flex #justify-between #items-center #mb-[20px]">
          <div class="#text-sm #leading-7 #space-y-2">
            <p v-for="(deliveryLine, index) in deliveryData" :key="index" v-html="deliveryLine"></p>
          </div>
        </div>
        <a
          :href="route('article2')"
          class="#group #inline-flex #justify-start #items-center #gap-x-4 #mb-[10px]"
        >
          <h3 class="#font-serif #text-lg">
            {{ __("Factuurgegevens") }}
          </h3>
          <svg-use
            id="pen"
            type="solid"
            class="#h-[15px] #w-[15px] #fill-inkdropdark group-hover:#fill-vividmagenta"
          />
        </a>
        <div class="#flex #justify-between #items-center #mb-[20px]">
          <div class="#text-sm #leading-8">
            <p v-for="(invoiceLine, index) in invoiceData" :key="index" v-html="invoiceLine"></p>
          </div>
        </div>
      </WhiteContainer>
    </div>
  </div>
  <div ref="mobilePriceDetails" class="md:#hidden #fixed #bottom-0 #w-full #z-[3]">
    <div class="#py-[10px] #px-[10px] #w-full #bg-brightcyan">
      <div
        class="#flex #flex-col #gap-y-4 #max-h-0 #overflow-hidden #transition-all"
        :class="{ '#max-h-[800px] #pt-[5px] #pb-[25px]': showPriceDetails }"
      >
        <PriceDetails
          :price-data="priceData"
          color="dark"
          class="!#text-white [&>*]:#text-white"
          @intercom="setIntercomDelayed"
        ></PriceDetails>
      </div>
      <div class="#flex #justify-between #items-center #gap-x-4">
        <div class="#text-white #text-sm sm:#text-base">
          <p>{{ deliveryTimeslot }}</p>
          <p v-if="priceData.price_total_incl && priceData.price_total_incl.value">
            {{ priceData.price_total_incl.value }}
          </p>
        </div>
        <Button
          :text="paymentButtonText"
          btn-class="secondary"
          :disabled-styling="
            (selectedPaymentMethod === 'account' && onAccountStatus < 1) ||
            errorMessages.length > 0 ||
            !selectedPaymentMethod
          "
          :disabled="processing"
          @click="postForm"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import WhiteContainer from "../components/WhiteContainer.vue";
import Button from "../components/Button.vue";
import InputField from "../components/InputField.vue";
import OrderProgress from "../components/OrderProgress.vue";
import CheckBox from "../components/CheckBox.vue";
import PriceDetails from "../components/PriceDetails.vue";
import SlideIn from "../components/SlideIn.vue";
import NotificationBar from "../components/NotificationBar.vue";
import CouponCode from "../components/CouponCode.vue";
import InvoiceOptions from "../components/InvoiceOptions.vue";
import DeliveryTerms from "../components/DeliveryTerms.vue";
import { lang, form, mobile } from "../mixins";
import { mapGetters } from "vuex";
import PaymentMethod from "../components/PaymentMethod.vue";
import SvgUse from "../../vue3/components/SvgUse.vue";

export default {
  components: {
    WhiteContainer,
    Button,
    InputField,
    OrderProgress,
    CheckBox,
    SlideIn,
    PriceDetails,
    PaymentMethod,
    NotificationBar,
    SvgUse,
    CouponCode,
    InvoiceOptions,
    DeliveryTerms,
  },
  mixins: [lang, mobile, form],
  data() {
    return {
      orderID: 0,
      paymentOptions: [],
      bankList: [],
      selectedPaymentMethod: "ideal",
      selectedBank: "abn-amro",
      articles: [],
      invoiceData: [],
      deliveryData: [],
      customer: [],
      priceData: [],
      deliveryTimeslot: null,
      invoiceReference: "",
      invoiceCopy: "",
      timerCheckAccount: false,
      language: "nl_NL",
      coupon: {},
      couponError: [],
      free: false,
      messages: [],
      errorMessages: [],
      form: {
        coupon: "",
      },
      agreeTerms: false,
      termsError: false,
      newsLetter: false,
      showPriceDetails: false,
      showTerms: false,
      onAccountStatus: 0,
      notificationBarOffset: 20,
      processing: false,
    };
  },
  computed: {
    ...mapGetters(["route"]),
    paymentButtonText() {
      if (this.selectedPaymentMethod === "free") {
        return this.__("Afgerond");
      }
      return this.__("Betalen");
    },
  },
  watch: {
    selectedPaymentMethod(value, oldValue) {
      if (value !== oldValue) {
        this.calculatePrice();
      }
    },
    agreeTerms(newValue) {
      if (newValue === true) {
        this.termsError = false;
      }
    },
    "form.coupon"(newValue) {
      if (newValue) {
        this.couponError = [];
      }
    },
    screenWidth(newValue) {
      if (newValue) {
        this.setIntercomIconLocation();
        this.setNotificationBarLocation();
      }
    },
    showPriceDetails(newValue, oldValue) {
      if (newValue !== oldValue) {
        setTimeout(() => {
          this.setIntercomIconLocation();
          this.setNotificationBarLocation();
        }, 200);
      }
    },
    async deliveryTimeslot(newValue, oldValue) {
      if (newValue !== oldValue && oldValue !== null) {
        this.messages.push({
          label: `<p>${this.__("De leverdatum is gewijzigd")} <span class="#hidden sm:#inline">${this.__("naar")} ${this.deliveryTimeslot}</span></p>`,
          id: Date.now() + Math.floor(Math.random() * 1000),
        });
      }
    },
    invoiceCopy(newValue) {
      if (newValue) {
        this.clearErrors("invoice_copy");
      }
    },
    messages() {
      this.setIntercomIconLocation();
    },
    invoiceReference() {
      this.clearErrors("reference");
    },
  },
  mounted() {
    this.getPageData();
    this.calculatePrice();
    setTimeout(() => {
      this.setIntercomIconLocation();
      this.setNotificationBarLocation();
    }, 200);
  },
  methods: {
    async postForm() {
      if (!this.agreeTerms) {
        this.termsError = true;
        this.$refs.mobileTerms.scrollIntoView({ behavior: "instant" });
        return;
      }
      this.clearAllErrors();
      this.processing = true;
      const response = await fetch(this.route("order.payment.submit"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          orderID: this.orderID,
          customerID: this.customer.id,
          payment_method: this.selectedPaymentMethod,
          payment_method_option: this.selectedPaymentMethod == "ideal" ? this.selectedBank : "",
          reference: this.invoiceReference,
          invoice_copy: this.invoiceCopy,
          language: this.language,
          newsletter: this.newsLetter ? "1" : "0",
        }),
      });

      if (response.ok) {
        const data = await response.text();

        switch (this.selectedPaymentMethod) {
          case "transcript":
          case "account":
          case "free":
            this.$refs.payment_form.action = data;
            this.$refs.payment_form.submit();
            break;
        }

        window.location.href = data;

        // On account check
      } else if (response.status == 403) {
        await response.json();
        this.processing = false;

        // Nog iets met de error doen?
        // De klant heeft geen rechten om op rekening te betalen.
      } else if (response.status == 422) {
        const data = await response.json();
        this.formErrors = data.errors;
        this.processing = false;
      }
    },
    async calculatePrice() {
      const url =
        LEGACY_URL +
        "/order/calculate_order.php?formID=payment_form&payment_method=" +
        this.selectedPaymentMethod;

      let response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        this.priceData = data.table_data;
        this.coupon.code = data.couponcode;
        this.coupon.value = data.couponcode_text;
        this.deliveryTimeslot = data.delivery_timeslot;
      }
    },
    async getPageData() {
      let response = await fetch(this.route("order.payment.init"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        // this.paymentOptions = await paymentMethods.text();
        this.invoiceData = data.invoice_data;
        this.deliveryData = data.delivery_data;
        this.customer = data.customer;
        this.articles = data.article_data;
        this.paymentOptions = data.methods;
        this.bankList = data.bank_list;
        this.language = data.lang;
        this.orderID = data.orderID;
        this.selectedBank = data.selected_bank;
        this.coupon = data.coupon;
        this.free = data.free;
        this.newsLetter = data.newsletter;
        this.showTerms = data.show_terms;
        this.onAccountStatus = data.on_account_status;
        this.selectedPaymentMethod = data.selected_payment_method;

        if (this.paymentOptions.length === 1) {
          this.selectedPaymentMethod = this.paymentOptions[0].value;
        }

        if (data.error) {
          this.errorMessages.push({
            label: data.error,
            id: Date.now() + Math.floor(Math.random() * 1000),
          });
        }

        if (!this.showTerms) {
          this.agreeTerms = true;
        }

        if (this.customer && this.customer.email4invoice) {
          this.invoiceCopy = this.customer.email4invoice;
        }

        this.checkApplePay();
      } else {
        response.text();
      }
    },
    checkApplePay() {
      if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
        for (var i = 0; i < this.paymentOptions.length; i++) {
          const method = this.paymentOptions[i];
          if (method.value == "applepay") {
            this.paymentOptions[i].active = 1;
          }
        }
      }
    },
    async postCouponCode() {
      let response = await fetch(this.route("order.payment.coupon.post"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ couponcode: this.form.coupon }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.error) {
          this.couponError.push(data.message);
        } else {
          this.messages.push({
            label: data.message,
            id: Date.now() + Math.floor(Math.random() * 1000),
          });
          this.form.coupon = "";
          this.$refs.coupon_code.closemenu();
          this.$refs.coupon_code_mobile.closemenu();
        }
        this.calculatePrice();
        this.getPageData();
        this.emitter.emit("refresh-cart");

        if (!data.error && data.message !== "") {
          this.$refs.coupon_code.closemenu();
          this.$refs.coupon_code_mobile.closemenu();
        }
      }
    },
    async destroyCouponCode() {
      let response = await fetch(this.route("order.payment.coupon.destroy"), {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.error) {
          this.couponError.push(data.message);
        } else {
          this.messages.push({
            label: data.message,
            id: Date.now() + Math.floor(Math.random() * 1000),
          });
        }
        this.calculatePrice();
        this.getPageData();
        this.emitter.emit("refresh-cart");
      }
    },
    clearMessages() {
      this.messages = [];
    },
    clearMessage(id) {
      this.messages = this.messages.filter((message) => {
        return id !== message.id;
      });
    },
    setIntercomDelayed() {
      setTimeout(() => {
        this.setIntercomIconLocation();
      }, 200);
    },
    setIntercomIconLocation() {
      this.$nextTick(() => {
        if (this.$refs.mobilePriceDetails && this.isMobile) {
          let value = this.$refs.mobilePriceDetails.offsetHeight + 25;
          this.emitter.emit("set-intercom-location", value);
        }
        if (this.$refs.mobilePriceDetails.offsetHeight === 0) {
          this.emitter.emit("set-intercom-location", 20);
        }
      });
    },
    setNotificationBarLocation() {
      this.$nextTick(() => {
        if (this.$refs.mobilePriceDetails && this.isMobile) {
          this.notificationBarOffset = this.$refs.mobilePriceDetails.offsetHeight + 35;
        }
        if (this.$refs.mobilePriceDetails.offsetHeight === 0) {
          this.notificationBarOffset = 20;
        }
      });
    },
  },
};
</script>
