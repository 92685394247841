<template>
  <div class="#w-full #flex #flex-col #gap-x-[10px] #items-center min-[500px]:#flex-row">
    <CarouselButton
      direction="left"
      class="#hidden min-[500px]:#block #left-0"
      :class="{ '#opacity-30 #touch-none #pointer-events-none': carouselIndex === 0 }"
      :location-styling="location"
      @click="carouselTurn('previous')"
    ></CarouselButton>
    <div
      id="containercarousel"
      class="#w-full #overflow-hidden"
      :class="({ 'lg:#mx-0': location === 'productpage' }, containerClasses)"
    >
      <div
        id="slider"
        ref="slider"
        class="#relative #w-full #flex #gap-x-[15px] #will-change-transform sm:#w-[calc(100%-15px)] lg:#w-[calc(100%-45px)] lg:#ml-[0px]"
        :class="{ '#transition-all': animateCarousel }"
        :style="moveSlider"
      >
        <slot></slot>
      </div>
    </div>
    <CarouselButton
      direction="right"
      class="#hidden min-[500px]:#block #right-0"
      :class="disableNextButton"
      :location-styling="location"
      @click="carouselTurn('next')"
    ></CarouselButton>
    <div class="#flex #gap-x-[30px] #mt-[15px] min-[500px]:#hidden">
      <CarouselButton
        :class="{ '#opacity-30 #touch-none #pointer-events-none': carouselIndex === 0 }"
        direction="left"
        :location-styling="location"
        @click="carouselTurn('previous')"
      ></CarouselButton>
      <CarouselButton
        direction="right"
        :class="disableNextButton"
        :location-styling="location"
        @click="carouselTurn('next')"
      ></CarouselButton>
    </div>
  </div>
</template>

<script>
import CarouselButton from "../components/CarouselButton.vue";
import throttle from "lodash.throttle";
import { mobile } from "../mixins";

export default {
  components: {
    CarouselButton,
  },
  mixins: [mobile],
  props: {
    location: {
      type: String,
      default: "homepage",
    },
    items: {
      type: Array,
      default: () => [],
    },
    containerClasses: {
      type: String,
      default: "",
    },
  },
  emits: ["getMoreData"],
  data() {
    return {
      carouselIndex: 0,
      reviewsOnScreen: 4,

      movePercentage: 25,

      touchStartXPosition: 0,
      touchCurrentXPosition: 0,
      carouselMoveLeft: 0,
      animateCarousel: true,
    };
  },
  computed: {
    moveSlider() {
      return {
        left: `${this.carouselMoveLeft}px`,
        transform: `translate(calc(-${this.movePercentage * this.carouselIndex}% + ${
          this.carouselIndex * -15
        }px))`,
      };
    },
    disableNextButton() {
      return {
        "#opacity-30 #touch-none #pointer-events-none":
          this.carouselIndex >= this.items.length - this.reviewsOnScreen,
      };
    },
    lastReviewIndex() {
      return this.items.length - 1;
    },
  },
  watch: {
    screenWidth(newValue) {
      this.changeCarouselSettings(newValue);
    },
    carouselIndex(newValue) {
      if (this.lastReviewIndex - (newValue + this.reviewsOnScreen - 1) <= 3) {
        this.$emit("getMoreData");
      }
    },
  },
  mounted() {
    this.changeCarouselSettings(this.screenWidth);
    this.$refs.slider.addEventListener("touchstart", this.handleTouchStart, { passive: true });
    this.$refs.slider.addEventListener("touchmove", this.handleTouchMove, { passive: true });
    this.$refs.slider.addEventListener("touchend", this.handleTouchEnd);
  },
  beforeUnmount() {
    this.$refs.slider.removeEventListener("touchstart", this.handleTouchStart);
    this.$refs.slider.removeEventListener("touchmove", this.handleTouchMove);
    this.$refs.slider.removeEventListener("touchend", this.handleTouchEnd);
  },
  methods: {
    carouselTurn: throttle(
      function (direction) {
        if (direction === "next") {
          if (this.carouselIndex === this.items.length - this.reviewsOnScreen) {
            return;
          }
          this.carouselIndex++;
        } else if (direction === "previous" && this.carouselIndex > 0) {
          this.carouselIndex--;
        }
      },
      50,
      { leading: true, trailing: false },
    ),
    handleTouchStart(e) {
      this.animateCarousel = false;
      this.touchStartXPosition = e.touches[0].clientX;
    },
    handleTouchMove(e) {
      if (e.cancelable) {
        e.preventDefault();
      }
      this.touchCurrentXPosition = e.touches[0].clientX;
      this.carouselMoveLeft = this.touchCurrentXPosition - this.touchStartXPosition;
    },
    handleTouchEnd() {
      this.animateCarousel = true;
      if (this.carouselMoveLeft > 50) {
        this.carouselTurn("previous");
      } else if (this.carouselMoveLeft < -50) {
        this.carouselTurn("next");
      }
      this.carouselMoveLeft = 0;
    },
    changeCarouselSettings(newValue) {
      if (newValue > 1023) {
        this.movePercentage = 25;
        this.reviewsOnScreen = 4;
      } else if (newValue < 1024 && newValue > 639) {
        this.movePercentage = 50;
        this.reviewsOnScreen = 2;
      } else {
        this.movePercentage = 100;
        this.reviewsOnScreen = 1;
      }
    },
  },
};
</script>
