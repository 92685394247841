<template>
  <div
    ref="container"
    class="error-messages #fixed #w-full #z-[99999] #left-0 #px-[20px] #space-y-4"
    :style="offsetFromEdge"
  >
    <DropIn :position="position">
      <NotificationMessage
        v-for="error in errors"
        :key="error.id"
        :message="error"
        type="error"
        :timer="false"
        @clear="(id) => $emit('clearMessage', id)"
      >
      </NotificationMessage>
      <NotificationMessage
        v-for="message in messages"
        :key="message.id"
        :message="message"
        :type="type"
        :timer="timer"
        @clear="(id) => $emit('clearMessage', id)"
      >
      </NotificationMessage>
    </DropIn>
  </div>
</template>

<script>
import DropIn from "../components/DropIn.vue";
import NotificationMessage from "./NotificationMessage.vue";

export default {
  components: {
    DropIn,
    NotificationMessage,
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "info",
    },
    timer: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: "top",
    },
    offset: {
      type: Number,
      required: true,
    },
  },
  emits: ["clearAllMessages", "clearMessage"],
  data() {
    return {};
  },
  computed: {
    offsetFromEdge() {
      let style = {};
      if (this.position === "top") {
        style.top = "90px";
      } else if (this.position === "bottom") {
        style.bottom = this.offset + "px";
      }
      return style;
    },
  },
};
</script>
