<template>
  <div class="#flex #flex-col #gap-y-2 #text-sm" :class="textColor">
    <div v-if="priceData.basket" class="#flex #justify-between #items-start">
      <a
        :href="route('shoppingcart')"
        class="#cursor-pointer #border-b #border-dotted #border-current hover:#text-vividmagenta"
        >{{ priceData.basket.label }}</a
      >
      <p>{{ priceData.basket.value }}</p>
    </div>
    <div v-if="priceData.production && priceData.production.label && showDetails">
      <div class="#flex #justify-between #items-start">
        <p>{{ priceData.production.label }}</p>
        <p v-html="priceData.production.value"></p>
      </div>
    </div>
    <div
      v-if="priceData.price_delivery && priceData.price_delivery.label"
      class="#flex #justify-between #items-start"
    >
      <a
        :href="route('article3')"
        class="#cursor-pointer #border-b #border-dotted #border-current hover:#text-vividmagenta"
        >{{ priceData.price_delivery.label }}</a
      >
      <p v-html="priceData.price_delivery.value"></p>
    </div>
    <div
      v-if="priceData.percentage_discount && priceData.percentage_discount.label"
      class="#flex #justify-between #items-start"
    >
      <p>{{ priceData.percentage_discount.label }}</p>
      <p class="#text-vividmagenta">{{ priceData.percentage_discount.value }}</p>
    </div>
    <div
      class="#grid #transition-all"
      :class="showDetails ? '#grid-rows-[1fr]' : '#grid-rows-[0fr]'"
    >
      <div class="#overflow-hidden">
        <div
          v-if="priceData.subtotal && priceData.subtotal.value"
          class="#w-max #ml-auto #space-y-2 #border-y-[1px] #py-2 #border-solid #border-neutral-300"
        >
          <div
            v-if="priceData.subtotal && priceData.subtotal.label"
            class="#flex #justify-between #items-start #gap-x-6 $"
          >
            <p class="#text-[#707070]">{{ priceData.subtotal.label }}</p>
            <p>{{ priceData.subtotal.value }}</p>
          </div>
        </div>
        <div
          v-if="priceData.price_discount && priceData.price_discount.label"
          class="#flex #justify-between #items-start"
        >
          <p>{{ priceData.price_discount.label }}</p>
          <p class="#text-vividmagenta">{{ priceData.price_discount.value }}</p>
        </div>
        <div
          v-if="priceData.price_payment && priceData.price_payment.label"
          class="#flex #justify-between #items-start #py-2"
        >
          <p>{{ priceData.price_payment.label }}</p>
          <p class="#text-vividmagenta">{{ priceData.price_payment.value }}</p>
        </div>
        <div
          class="#w-max #ml-auto #space-y-2 #border-y-[1px] #py-2 #border-solid #border-neutral-300"
        >
          <div
            v-if="priceData.price_total_excl && priceData.price_total_excl.label"
            class="#flex #justify-end #items-start #gap-x-6"
          >
            <p class="#text-[#707070]">{{ priceData.price_total_excl.label }}</p>
            <p>{{ priceData.price_total_excl.value }}</p>
          </div>
          <div
            v-if="priceData.tax9_total && priceData.tax9_total.label"
            class="#flex #justify-end #items-start #gap-x-6"
          >
            <p class="#text-[#707070]">{{ priceData.tax9_total.label }}</p>
            <p>{{ priceData.tax9_total.value }}</p>
          </div>
          <div
            v-if="priceData.tax21_total && priceData.tax21_total.label"
            class="#flex #justify-end #items-start #gap-x-6"
          >
            <p class="#text-[#707070]">{{ priceData.tax21_total.label }}</p>
            <p>{{ priceData.tax21_total.value }}</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="#group #flex #justify-end #items-center #gap-x-2 #cursor-pointer #mb-4 hover:#text-vividmagenta"
      @click="
        showDetails = !showDetails;
        $emit('intercom');
      "
    >
      <p v-if="showDetails" class="#text-xs #border-b #border-current #border-dotted">
        {{ __("Toon minder") }}
      </p>
      <p v-else class="#text-xs #border-b #border-current #border-dotted">
        {{ __("Toon details") }}
      </p>
      <svg-use
        id="angle-down"
        type="solid"
        class="#w-[0.7em] #h-[0.7em] #fill-inkdropdark group-hover:#fill-vividmagenta"
        :class="{ '#rotate-180': showDetails }"
      ></svg-use>
    </div>
    <div
      v-if="priceData.price_total_incl && priceData.price_total_incl.label"
      class="#flex #justify-between #items-start"
    >
      <p>{{ priceData.price_total_incl.label }}</p>
      <p class="#font-medium" :class="totalColor">{{ priceData.price_total_incl.value }}</p>
    </div>
  </div>
</template>

<script>
import SvgUse from "../components/SvgUse.vue";
import { lang } from "../mixins";
import { mapGetters } from "vuex";

export default {
  components: {
    SvgUse,
  },
  mixins: [lang],
  props: {
    priceData: {
      type: Object,
      default: () => {},
    },
    color: {
      type: String,
      default: "regular",
    },
  },
  emits: ["intercom"],
  data() {
    return {
      showDetails: false,
    };
  },
  computed: {
    ...mapGetters(["route"]),
    textColor() {
      return {
        "#text-inkdropdark": this.color === "regular",
        "#text-white": this.color === "dark",
      };
    },
    totalColor() {
      return {
        "#text-brightcyan": this.color === "regular",
        "#text-white": this.color === "dark",
      };
    },
  },
};
</script>
