<script setup>
import { useIntegerOptionProps } from "../composables/props";
import IntegerField from "../fields/IntegerField.vue";
import { useChecks, useCMS, useSetup } from "../composables/option";

defineOptions({
  key: "number",
  label: "Oplage",
});
const props = defineProps(useIntegerOptionProps());
const { check, name, key, description } = useSetup(props);
const { disabled, tooltipTitle, tooltipContent } = useCMS(name, check);
const { isRequired, isInteger, isMinInteger } = useChecks();

check(isRequired());
check(isInteger());
check(isMinInteger(1));
</script>

<template>
  <IntegerField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :name="key"
    :description="description"
  />
</template>
