<template>
  <div class="row">
    <div class="col-md-4">
      <form @submit.prevent="submit">
        <div class="form-group" :class="{ 'has-error': hasError('name') }">
          <label class="control-label sr-only"> E-mail </label>
          <input v-model="name" type="text" class="form-control" placeholder="Naam" />
          <p v-if="hasError('name')" class="help-block">
            {{ firstError("name") }}
          </p>
        </div>
        <div class="form-group" :class="{ 'has-error': hasError('password') }">
          <label class="control-label sr-only"> Wachtwoord </label>
          <input v-model="password" type="password" class="form-control" placeholder="Wachtwoord" />
          <p v-if="hasError('password')" class="help-block">
            {{ firstError("password") }}
          </p>
        </div>
        <SubmitButton text="Inloggen" :disabled="submitting" />
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubmitButton from "../components/SubmitButton.vue";
import { form } from "../mixins";
export default {
  name: "WikiLogin",
  components: { SubmitButton },
  mixins: [form],
  data() {
    return {
      name: "",
      password: "",
    };
  },
  methods: {
    async submit() {
      this.submitting = true;
      this.formErrors = {};

      const response = await fetch(this.route("wiki.login"), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "post",
        credentials: "same-origin",
        body: JSON.stringify({
          name: this.name,
          password: this.password,
        }),
      });

      if (response.ok) {
        const params = new URLSearchParams(window.location.search);

        let url = params.has("url") ? params.get("url") : "/wiki";
        window.location.assign(url);
      } else if (response.status === 422) {
        const body = await response.json();
        this.formErrors = body.errors;
        this.submitting = false;
      } else {
        this.formErrors = {
          name: ["Er heeft een onbekende fout plaatsgevonden."],
        };
        this.submitting = false;
      }
    },
  },
  computed: {
    ...mapGetters(["route"]),
  },
};
</script>

<style module="s"></style>
