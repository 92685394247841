import { fetchWithTimeout } from "../../admin/helpers";

export default {
  data() {
    return {
      pro6pp_auth_key: "KJyx73TZvCjF6YDr",
      autocompleting: false,
    };
  },
  methods: {
    async autocompleteAddress(zipcode, country) {
      if (!country || country.toLowerCase() !== "nl") {
        return;
      }

      if (/^\d{4}[A-Za-z]{2}$/.test(zipcode) === false) {
        return;
      }

      this.autocompleting = true;

      try {
        let response = await fetchWithTimeout(
          `https://api.pro6pp.nl/v2/suggest/nl/street?authKey=${this.pro6pp_auth_key}&postalCode=${zipcode}`,
          {
            timeout: 10000,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Requested-With": "XMLHttpRequest",
            },
          },
        );

        if (!response.ok) {
          this.autocompleting = false;

          return;
        }

        let data = await response.json();
        if (!Array.isArray(data) || data.length === 0) {
          this.autocompleting = false;

          return;
        }

        let [{ settlement, street } = {}] = data;
        if (settlement) {
          this.form.city = settlement;
        }
        if (street) {
          this.form.street = street;
        }

        if (street || settlement) {
          this.form.streetnumber = "";
          this.$nextTick(() => {
            this.$refs.streetNumber.focus();
          });
        }

        this.autocompleting = false;
      } catch (error) {
        this.autocompleting = false;

        return;
      }
    },
  },
};
