<template>
  <FadeIn>
    <div :class="s.container">
      <div v-if="icon && !legacy" :class="s.left">
        <div :class="s.icon">
          <div class="tailwind">
            <svg-use :id="icon" type="solid" class="#h-[1.75em] #w-[1.5em] #scale-x-[-1]" />
          </div>
        </div>
      </div>
      <div :class="s.body">
        <h4 v-if="$slots.title" :class="s.title" :style="{ fontSize: legacy ? '15px' : '20px' }">
          <slot name="title"></slot>
        </h4>
        <div :class="s.default">
          <slot></slot>
        </div>
      </div>
    </div>
  </FadeIn>
</template>
<script>
import { mapGetters } from "vuex";
import FadeIn from "../components/FadeIn.vue";
import SvgUse from "../components/SvgUse.vue";

export default {
  components: { FadeIn, SvgUse },
  computed: {
    ...mapGetters(["legacy"]),
  },
  props: {
    icon: {
      type: [Object, Array, String],
      required: false,
    },
  },
};
</script>

<style module="s">
.fadeEnterActive,
.fadeLeaveActive {
  transition: opacity 0.4s ease;
}

.fadeEnterFrom,
.fadeLeaveTo {
  opacity: 0;
}
.container {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 10px;
}
.left {
  display: table-cell;
  vertical-align: top;
  padding-right: 20px;
}
.body {
  display: table-cell;
  vertical-align: top;
}
.icon {
  width: 25px;
}
.title {
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 18px;
}
.default {
  margin: 0;
  font-size: 15px;
}
</style>
