<template>
  <div class="tailwind">
    <div
      class="#flex #justify-start #items-start #gap-[15px] #flex-col sm:#flex-row #px-[15px] #pb-[30px] md:#px-[30px]"
    >
      <InputField
        v-model="orderId"
        :label="__('Opdrachtnr.')"
        type="text"
        class="max-sm:#w-full #w-[250px]"
        :error="firstError('order_id') || firstError('__generic__')"
        @keyup.enter="callGetOrdersFunction"
      ></InputField>
      <InputField
        v-model="zipCode"
        :label="__('Postcode')"
        type="text"
        class="max-sm:#w-full #w-[250px]"
        :input-class="{ '!#border-[#d9534f]': firstError('__generic__') }"
        :error="firstError('postcode')"
        @keyup.enter="callGetOrdersFunction"
      ></InputField>
      <Button btn-class="secondary" :disabled="loading" @click="callGetOrdersFunction">
        {{ __("Bekijk status") }}
      </Button>
    </div>
  </div>
  <OrderOverview
    ref="orderOverview"
    :show-details="false"
    :show-filters="false"
    :auto-open="true"
    class="#invisible"
    :loading="loading"
    :loading-rows="1"
    :fetch-orders="fetchOrders"
  ></OrderOverview>
  <div v-if="!loading" class="tailwind">
    <div class="#px-[15px] md:#px-[30px] #pt-[30px] #pb-[30px] #text-center">
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
import OrderOverview from "../components/OrderOverview.vue";
import InputField from "../components/InputField.vue";
import Button from "../components/Button.vue";
import { lang, form } from "../mixins";
import { mapGetters } from "vuex";

export default {
  components: {
    OrderOverview,
    InputField,
    Button,
  },
  mixins: [lang, form],
  data() {
    return {
      orderLoaded: false,
      orderId: "",
      zipCode: "",
      loading: false,
      message: this.__(
        "Zoek op een Opdrachtnummer en postcode om de status van je bestelling te zien.",
      ),
      formErrors: {},
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  watch: {
    orderId(newValue) {
      if (newValue) {
        this.clearErrors("order_id");
        this.clearErrors("__generic__");
      }
    },
    zipCode(newValue) {
      if (newValue) {
        this.clearErrors("postcode");
        this.clearErrors("__generic__");
      }
    },
  },
  mounted() {
    const query_orderId = new URLSearchParams(window.location.search).get("order_id");
    const query_zipCode = new URLSearchParams(window.location.search).get("postcode");

    if (query_orderId || query_zipCode) {
      this.orderId = query_orderId;
      this.zipCode = query_zipCode;

      this.callGetOrdersFunction();
    }
  },
  methods: {
    callGetOrdersFunction() {
      this.$refs.orderOverview.getOrders();
    },
    updateUrl() {
      let url = new URL(window.location.href);
      if (this.orderId || this.zipCode) {
        url.searchParams.set("order_id", this.orderId);
        url.searchParams.set("postcode", this.zipCode);
      } else {
        url.searchParams.delete("order_id");
        url.searchParams.delete("postcode");
      }

      window.history.replaceState({}, "", url.href);
    },
    async fetchOrders() {
      this.loading = true;
      this.loaded = false;
      this.clearAllErrors();
      this.message = "";
      let obj = {
        from: null,
        to: null,
        column: null,
        direction: null,
        status: null,
        page: null,
        order_id: this.orderId,
        postcode: this.zipCode,
      };

      let response = await fetch(this.route("status"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(obj),
      });

      if (!response.ok) {
        const data = await response.json();
        if (data) {
          this.formErrors = data.errors;
        }
        this.message = this.__(
          "Zoek op een Opdrachtnummer en postcode om de status van je bestelling te zien.",
        );
      }

      this.updateUrl();

      this.loading = false;
      this.loaded = true;

      return response;
    },
  },
};
</script>
