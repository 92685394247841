<template>
  <form action="" name="myForm" @submit.prevent="createPlayer">
    <div v-if="players.length < 6" class="#flex #justify-between #gap-x-1">
      <input
        id="playerInput"
        v-model="tempName"
        placeholder="Naam"
        class="#py-2 #px-2 #rounded-md #border-0 #w-1/2"
        type="text"
        name="player"
        @blur="onBlur"
        @keyup.enter="createPlayer"
      />
      <input
        id="playerInput"
        v-model="tempNumber"
        placeholder="Polsbandje"
        class="#py-2 #px-2 #rounded-md #border-0 #w-1/4"
        type="text"
        inputmode="numeric"
        name="player"
        @blur="onBlur"
        @keyup.enter="createPlayer"
      />
      <input
        id="addBtn"
        class="#border-0 #bg-vividmagenta #font-semibold #py-2 #px-4 #rounded-md #text-white #self-end"
        type="button"
        name="button"
        value="+"
        @click="createPlayer"
      />
    </div>
    <input
      id="startBtn"
      type="button"
      class="#border-0 #mt-6 #font-semibold #block #mx-auto #w-full #bg-brightcyan #text-white #py-2 #px-4 #rounded-md"
      value="Start"
      @click="startGame"
    />
  </form>
</template>

<script>
export default {
  props: ["players"],
  emits: ["create", "start"],
  data() {
    return {
      tempName: "",
      tempNumber: "",
    };
  },
  watch: {
    tempNumber(newValue) {
      let filtered = newValue;
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      for (const i in filtered) {
        if (!keysAllowed.includes(filtered[i])) {
          filtered = filtered.replace(filtered[i], "");
        }
      }
      this.tempNumber = filtered;
    },
  },
  methods: {
    onBlur() {
      // window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    startGame() {
      if (this.players.length === 0) {
        return;
      }

      if (this.tempName && this.tempNumber) {
        this.createPlayer();
      }
      this.$emit("start");
    },
    createPlayer() {
      let samenumber = false;
      if (this.tempName.length === 0 || this.tempName[0] === " ") {
        alert("Voer een naam in die niet met een spatie begint.");
        return;
      }
      if (this.tempNumber.length === 0 || this.tempNumber[0] === " ") {
        alert("Voeg een polsbandje nummer toe.");

        return;
      }
      this.players.forEach((player) => {
        if (player.id === this.tempNumber) {
          alert("Dit polsbandje is al actief in dit spel.");
          samenumber = true;
        }
      });
      if (samenumber === true) {
        samenumber = false;
        return;
      }
      this.$emit("create", this.tempName, this.tempNumber);
      this.tempNumber = "";
      this.tempName = "";
    },
  },
};
</script>
