<template>
  <div>
    <h3 class="#font-serif #text-lg #mb-[10px]">{{ __("Leverwijze") }}</h3>
    <p>{{ order.delivery_method }}</p>
    <p v-if="order.delivery_option">{{ order.delivery_option }}</p>
    <p v-if="order.anonymous">{{ __("Blanco versturen") }}</p>
    <div v-if="order.delivery_instruction" class="#text-inkdropdark #text-sm #mt-4">
      <p class="#font-medium">{{ __("Bezorginstructies") }}</p>
      <p>
        {{ order.delivery_instruction }}
      </p>
    </div>

    <h3 v-if="showDetails" class="#font-serif #text-lg #mb-[10px] #mt-[15px]">
      {{ __("Afleveradres") }}
    </h3>
    <div v-if="showDetails" v-html="order.delivery_address"></div>

    <h3 class="#font-serif #text-lg #mb-[10px] #mt-[15px]">
      {{ __("Productiemethode") }}
    </h3>
    <p>{{ order.production_method }}</p>
  </div>
</template>

<script>
import { lang } from "../mixins";
export default {
  mixins: [lang],
  inject: ["showDetails"],
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
