<template>
  <LoginForm v-if="!$store.getters.user" location="article2"></LoginForm>
  <div v-else>
    <p>
      {{ __("Je bent aangemeld als") }}
    </p>
    <p class="#font-bold">
      {{ $store.getters.user.name }}
    </p>
    <p class="#mt-[5px]">({{ __("Je kunt je gegevens aanpassen") }})</p>
  </div>
</template>

<script>
import LoginForm from "../components/LoginForm.vue";
import { lang } from "../mixins";

export default {
  components: {
    LoginForm,
  },
  mixins: [lang],
};
</script>
