<template>
  <WaitingList></WaitingList>
</template>

<script>
import WaitingList from "../components/WaitingList.vue";

export default {
  components: {
    WaitingList,
  },
};
</script>
