<script setup>
import { computed } from "vue";
import OptionRow from "../OptionRow.vue";
import { useCheckboxOptionProps } from "../composables/props";
import { useOrderForm } from "../../modules/orderModule";
import camelCase from "lodash.camelcase";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  ...useCheckboxOptionProps(),
});
const form = useOrderForm();

const id = computed(() => {
  return camelCase(props.name + "_" + props.value);
});

const classes = computed(() => {
  return {
    deactive: props.disabled,
  };
});

if (props.initial && !import.meta.env.SSR) {
  form.updateItem({
    name: props.name,
    value: props.value,
  });
}

const update = (event) => {
  if (event.target.checked) {
    form.updateItem({
      name: props.name,
      value: props.value,
    });
  } else {
    form.deleteItem(props.name);
  }
};

const checked = computed(() => {
  return form.getValue(props.name) === props.value;
});
</script>

<template>
  <OptionRow v-show="!hidden" v-bind="$props">
    <div class="checkbox fancy-checkbox">
      <input
        :id="id"
        type="checkbox"
        :value="value"
        :name="name"
        :checked="checked"
        @change="update"
      />
      <label class="fancy-control-ui" :for="id" />
      <label :class="classes" :for="id">{{ label }}</label>
    </div>
  </OptionRow>
</template>
