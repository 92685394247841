<template>
  <a
    ref="button"
    :disabled="disabled"
    type="submit"
    :style="fixed"
    class="#select-none #font-medium #inline-block #rounded-md !#border-b-[1px] #border-[1px] #border-solid #cursor-pointer #min-w-max hover:!#border-[1px] hover:!#border-b-[1px] hover:#border-solid hover:#opacity-100"
    :class="[styling, { waiting: disabled }]"
    @click="onClick"
  >
    <span
      v-if="showLoader"
      class="spinner #flex #justify-center #items-center #gap-x-1 #relative #w-full"
    >
      <div :style="rect1" />
      <div :style="rect2" />
      <div :style="rect3" />
    </span>
    <div
      v-else
      class="#flex #items-center #gap-x-[5px] #h-full"
      :class="[
        iconPlacement === 'right' ? '#flex-row' : '#flex-row-reverse',
        icon ? '#justify-between' : '#justify-center',
      ]"
    >
      <span v-if="text" :class="{ 'max-[350px]:#hidden': icon === 'angle-left' }">
        {{ text }}
      </span>
      <slot></slot>
      <svg-use
        v-if="icon"
        :id="icon"
        :type="iconType"
        class="#block #w-[1em] #max-h-[1em]"
        :class="iconStyling"
      />
    </div>
  </a>
</template>

<script>
import SvgUse from "../components/SvgUse.vue";

export default {
  components: {
    SvgUse,
  },
  props: {
    btnClass: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledStyling: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "sm",
    },
    text: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    iconType: {
      type: String,
      default: "solid",
    },
    iconPlacement: {
      type: String,
      default: "right",
    },
  },
  emits: ["click"],
  data() {
    return {
      width: null,
      height: null,
      showLoader: false,
    };
  },
  computed: {
    fixed() {
      if (!this.disabled) {
        return {};
      }
      return {
        height: this.height + "px !important",
        width: this.width + "px !important",
        "min-width": this.width + "px !important",
      };
    },
    styling() {
      return {
        "#bg-brightcyan #text-white #border-[#1fc5ff] hover:#bg-[#05bfff] hover:!#text-white hover:#border-[#00a7e0]":
          this.btnClass === "primary",
        "#bg-vividmagenta #text-white #border-[#ff1c6d] hover:#bg-[#ff035c] hover:!#text-white hover:#border-[#de004f]":
          this.btnClass === "secondary",
        "#bg-white #text-brightcyan #border-[#1fc5ff] hover:#bg-inkdroplight hover:!#text-brightcyan hover:#border-[#00a7e0]":
          this.btnClass === "primary-outline",
        "#bg-inkdroplight #text-inkdropdark #border-transparent hover:#bg-[#dbdbdf] hover:#text-inkdropdark hover:#border-transparent":
          this.btnClass === "grey",
        "#text-lg #px-[18px] #py-[12px]": this.size === "lg",
        "#px-[12px] #py-[6px] #h-[42px]": this.size === "sm",
        "#px-[8px] #py-[4px] #text-sm": this.size === "xs",
        "!#opacity-50 !#pointer-events-none !#select-none": this.disabledStyling,
      };
    },
    iconStyling() {
      return {
        "#fill-white": this.btnClass === "primary" || this.btnClass === "secondary",
        "#fill-inkdropdark": this.btnClass === "grey",
        "#fill-brightcyan": this.btnClass === "primary-outline",
      };
    },
    rect1() {
      return {
        "background-color": "#636363",
        width: "7px",
        height: "25px",
        animation: "rect 1.2s infinite ease-in-out",
      };
    },
    rect2() {
      return {
        "background-color": "#e6007e",
        width: "7px",
        height: "25px",
        animation: "rect2 1.2s -1.1s infinite ease-in-out",
      };
    },
    rect3() {
      return {
        "background-color": "#398ccb",
        width: "7px",
        height: "25px",
        animation: "rect3 1.2s -1s infinite ease-in-out",
      };
    },
  },
  watch: {
    disabled(newValue) {
      if (newValue === true) {
        this.height = this.$refs.button.offsetHeight;
        this.width = this.$refs.button.offsetWidth;
        this.showLoader = true;
      } else {
        this.showLoader = false;
      }
    },
  },
  methods: {
    onClick($event) {
      if (this.disabled) {
        return;
      }
      this.$emit("click", $event);
    },
  },
};
</script>

<style>
@keyframes rect {
  0% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }

  40% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
}

@keyframes rect2 {
  0% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }

  40% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
}

@keyframes rect3 {
  0% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }

  40% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
}
</style>
