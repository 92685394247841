<template>
  <div class="tailwind">
    <input
      ref="datepicker"
      type="text"
      :placeholder="placeholder"
      class="#min-w-[100px] #max-w-[185px] #w-full #h-[42px] #text-inkdropdark #border-neutral-300 #peer #autofill:#bg-white #block #border-[1px] #rounded-md #py-3 #px-4 #outline-none #transition-all focus:#shadow-brightcyan focus:#border-brightcyan focus:#shadow-focus"
    />
  </div>
</template>

<script>
import AirDatepicker from "air-datepicker";
import "air-datepicker/air-datepicker.css";
import { mapGetters } from "vuex";
import en from "air-datepicker/locale/en";
import nl from "air-datepicker/locale/nl";

export default {
  name: "DatePicker",
  props: {
    modelValue: {
      type: Date,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    minDate: {
      type: Date,
      default: null,
    },
    maxDate: {
      type: Date,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      datepicker: null,
    };
  },
  computed: {
    ...mapGetters(["config"]),
  },
  watch: {
    minDate(newValue) {
      if (this.datepicker) {
        this.datepicker.update({
          minDate: newValue ?? false,
        });
      }
    },
    maxDate(newValue) {
      if (this.datepicker) {
        this.datepicker.update({
          maxDate: newValue ?? false,
        });
      }
    },
  },
  mounted() {
    let mobile = window.screen.width < 640;
    let locale = nl;
    if (this.config("app.locale") === "en") {
      locale = en;
    }

    this.datepicker = new AirDatepicker(this.$refs.datepicker, {
      locale: locale,
      isMobile: mobile,
      autoClose: true,
      dateFormat: "dd-MM-yyyy",
      onSelect: ({ date }) => {
        this.$emit("update:modelValue", date);
      },
    });
  },
  unmounted() {
    if (this.datepicker) {
      this.datepicker.destroy();
    }
  },
  methods: {
    clearAll() {
      this.datepicker.clear();
    },
  },
};
</script>

<style>
.air-datepicker {
  padding: 10px;
  background-color: white;
  border-radius: 6px;
  width: 300px;
  box-shadow: 0 0px 20px -3px rgba(0, 0, 0, 0.16);
  z-index: 6 !important;
  font-family: "Barlow";
  font-size: 17px;
}

.air-datepicker-body--cells {
  gap: 5px;
}
.air-datepicker-body--cells.-days- {
  grid-auto-rows: max-content;
}

.air-datepicker-nav--title {
  font-size: 17px;
  font-weight: 500;
}

.air-datepicker-nav--action:hover {
  background-color: #f6f6f7;
}

.air-datepicker-nav--action:hover > svg > path {
  stroke: #ff367d;
}

.air-datepicker-body--day-name {
  font-size: 17px;
  color: #707070;
  font-weight: 500;
  text-transform: lowercase;
}

.air-datepicker-cell {
  aspect-ratio: 1;
}

.air-datepicker-cell.-selected- {
  background: #ff367d !important;
  font-weight: 500;
}

.air-datepicker-cell.-focus- {
  background: #f6f6f7;
}

.air-datepicker-cell.-current- {
  border: 1px solid #ff367d;
  color: #24212b;
}

.air-datepicker {
  z-index: 100 !important;
}
</style>
