<template>
  <div class="#flex #flex-col #relative" :class="divStyling">
    <div
      class="#relative #flex #items-center"
      @mouseover="openPopup = true"
      @mouseleave="openPopup = false"
    >
      <input
        ref="input"
        :aria-label="label"
        :value="modelValue"
        :class="[styling, inputClass]"
        class="#peer #autofill:#bg-white #block #border-[1px] #w-full #rounded-md #py-3 #px-4 #outline-none #transition-all focus:#shadow-brightcyan focus:#border-brightcyan focus:#shadow-focus"
        :type="type"
        :disabled="disabled || false"
        :autocomplete="autoComplete"
        @input="triggerInput"
        @blur="customBlur"
        @keyup.enter="$emit('keyupenter')"
      />
      <p
        class="#truncate #transition-all #absolute #max-w-max #whitespace-nowrap #left-4 #right-full #px-2 #-ml-2 #rounded-md #select-none #pointer-events-none #box-border peer-focus:#text-[11px] peer-focus:#top-[-7px] peer-focus:#max-w-max peer-focus:#min-w-max peer-active:#top-[-7px] peer-active:#text-[11px]"
        :class="labelStyling"
      >
        {{ label }} <span v-if="required" class="#text-vividmagenta">*</span>
      </p>
      <div
        v-if="popup && openPopup"
        class="#absolute #bg-white #px-8 #py-6 #left-full #w-1/2 #z-[3] #shadow-focus #rounded-md #ml-8 #hidden md:#block"
      >
        <div
          class="#h-0 #absolute #w-0 #origin-top #top-1/2 #rotate-[270deg] #left-[-23px] #border-solid #border-white #border-b-[15px] #border-l-[10px] #border-l-transparent #border-r-[10px] #border-r-transparent #border-t-0"
        />
        <h3 class="#font-bold #mb-4">
          {{ infotitle }}
        </h3>
        <p>{{ infotext }}</p>
      </div>
    </div>
    <p
      v-if="inputError || error"
      class="#text-[#d9534f] #text-sm #font-semibold #mt-2"
      v-html="inputError || error"
    />
    <p v-if="warning" class="#text-orange-400 #text-sm #font-semibold #mt-2">
      {{ warning }}
    </p>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    modelValue: {
      type: [String, Number, null],
      default: "",
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
    },
    popup: {
      type: Boolean,
      required: false,
    },
    infotext: {
      type: String,
      required: false,
      default: null,
    },
    infotitle: {
      type: String,
      required: false,
      default: null,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    warning: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    validationType: {
      type: String,
      required: false,
      default: "",
    },
    autoComplete: {
      type: String,
      required: false,
      default: "",
    },
    darkmode: {
      type: Boolean,
      default: false,
      required: false,
    },
    inputClass: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:modelValue", "blur", "keyupenter"],
  data() {
    return {
      openPopup: false,
      inputError: "",
      autofilled: null,
    };
  },
  computed: {
    ...mapGetters(["route", "user", "isAdmin", "config"]),
    styling() {
      return {
        "!#border-[#d9534f]": this.inputError || this.error,
        "!#border-orange-400": this.warning,
        "#backdrop-blur-sm #bg-[#ffffff14] !#border-0": this.darkmode,
        "#text-inkdropdark #border-neutral-300": !this.darkmode,
        "#bg-[#f5f5f5]": this.disabled,
      };
    },
    divStyling() {
      return {
        "#basis-2/6 #max-w-[130px]": this.size === "sm",
        "#w-full #max-w-[200px]": this.size === "md",
        "#basis-full #min-w-[170px]": this.size === "lg",
      };
    },
    labelStyling() {
      return {
        "peer-focus:#bg-white peer-focus:#text-[#707070] peer-active:#bg-white peer-active:#text-[#707070]":
          !this.darkmode,
        "peer-focus:#text-inkdroplight peer-active:#text-inkdroplight": this.darkmode,
        "#text-[11px] #min-w-max !#top-[-7px] #text-[#707070] #bg-white":
          (this.modelValue && !this.darkmode) || (this.autofilled && !this.darkmode),
        "#text-[#d5d5d5] #w-5/6 #top-[8px]": !this.modelValue,
        "#text-[11px] #min-w-max !#top-[-7px] #text-inkdroplight": this.modelValue && this.darkmode,
        "#text-[11px] #min-w-max !#top-[-7px] #backdrop-blur-sm #bg-[#ffffff14] !#text-inkdropdark":
          this.autofilled && this.darkmode,
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.autocompleted = this.testAutoFill();
    });
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    customBlur() {
      this.$emit("blur");
    },
    triggerInput(e) {
      this.$emit("update:modelValue", e.target.value);
      this.inputError = "";
      this.autofilled = false;
      this.autocompleted = this.testAutoFill();
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    detectAutofill(element) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            window.getComputedStyle(element, null).getPropertyValue("appearance") ===
              "menulist-button",
          );
        }, 600);
      });
    },
    async testAutoFill() {
      this.autofilled = await this.detectAutofill(this.$refs.input);
    },
  },
};
</script>
