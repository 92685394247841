<template>
  <MediaItem icon="phone">
    <template #title>
      <a ref="title" href="javascript:;" @click.prevent="open = !open">
        {{ __("Bel mij terug") }}
      </a>
    </template>
    <p>
      {{ __("Kies een tijdvak wanneer je teruggebeld wilt worden") }}
    </p>
  </MediaItem>
  <div
    ref="popup"
    class="dropdown-menu"
    :class="s.container"
    :style="{ visibility: open ? 'visible' : 'hidden' }"
  >
    <CallBack v-if="open" @close="() => (open = false)" @select="focus" />
  </div>
</template>

<script>
import MediaItem from "../components/MediaItem.vue";
import CallBack from "../components/CallBack.vue";
import { lang } from "../mixins";
import { createPopper } from "@popperjs/core";

export default {
  components: { MediaItem, CallBack },
  mixins: [lang],
  data() {
    return {
      open: false,
    };
  },
  mounted() {
    this.$nextTick(function () {
      const boundary = document.querySelector(".page-wrap");
      createPopper(this.$refs["title"], this.$refs["popup"], {
        placement: "bottom",
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              boundary: boundary,
            },
          },
          {
            name: "offset",
            options: {
              offset: [0, 6],
            },
          },
        ],
      });
    });
    this.clickOutsideEvent = (event) => {
      if (!this.open) {
        return;
      }

      for (let ref of ["popup", "title"]) {
        ref = this.$refs[ref];
        if (event.target === ref || ref.contains(event.target)) {
          return;
        }
      }

      this.focus();
      this.open = false;
    };
    document.body.addEventListener("mousedown", this.clickOutsideEvent);
  },
  unmounted() {
    document.body.removeEventListener("mousedown", this.clickOutsideEvent);
  },
  methods: {
    focus() {
      var y = window.pageYOffset + this.$refs["title"].getBoundingClientRect().top;
      y = y - (84 + 35 + 10); // navbar + meta bar + padding

      if (window.scrollY > y) {
        window.scrollTo(0, y);
      }
    },
  },
};
</script>

<style module="s">
.container {
  -webkit-clip-path: inset(-100vh -100vh -100vh -100vw);
  clip-path: inset(-100vh -100vh -100vh -100vw);

  display: block;
  margin: 0;
  border: 0;
  width: 422px;
}

@media (max-width: 767px) {
  .container {
    width: calc(100% + 30px);
  }
}
</style>
