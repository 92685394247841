<template>
  <div style="height: 0px" class="tailwind infoPopup">
    <div
      ref="popup"
      :style="{ visibility: active || stayActive ? 'visible' : 'hidden' }"
      class="#bg-white #px-8 #py-6 #z-[3] #shadow-popup #rounded-md #max-w-[380px]"
    >
      <!-- <div data-popper-arrow class="#h-0 #absolute #w-0 #origin-top #border-solid #border-white #border-b-[15px] #border-l-[10px] #border-l-transparent #border-r-[10px] #border-r-transparent #border-t-0" /> -->
      <div
        data-popper-arrow
        class="#w-6 #h-6 before:#bg-white before:#w-6 before:#h-6 before:#block before:#rotate-45 #bg-transparent #absolute #-right-3 #-top-3"
      />

      <h3 class="#font-bold #mb-4">
        {{ title }}
      </h3>
      <p>{{ info }}</p>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  data() {
    return {
      active: false,
      stayActive: false,
      title: "",
      info: "",
    };
  },
  mounted() {
    this.emitter.on("open-info-popup", this.open);
    this.emitter.on("close-info-popup", this.close);
    this.emitter.on("stay-info-popup", this.stay);
    this.emitter.on("removestay-info-popup", this.removeStay);
  },
  unmounted() {
    this.emitter.off("open-info-popup", this.open);
    this.emitter.off("close-info-popup", this.close);
    this.emitter.off("stay-info-popup", this.stay);
    this.emitter.off("removestay-info-popup", this.removeStay);
  },
  methods: {
    open(data) {
      this.active = true;
      this.stayActive = false;
      this.title = data.title;
      this.info = data.info;

      createPopper(data.element, this.$refs["popup"], {
        placement: "left",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: ({ reference, popper, placement }) => {
                if (placement === "left") {
                  return [0, 20];
                } else if (placement === "bottom") {
                  return [0, 20];
                } else {
                  return [0, 20];
                }
              },
            },
          },
          {
            name: "flip",
            options: {
              fallbackPlacements: ["bottom"],
            },
          },
          {
            name: "preventOverflow",
            options: {
              mainAxis: true,
              altAxis: true,
              padding: 20,
            },
          },
        ],
      });
    },
    close() {
      this.active = false;
    },
    stay(data) {
      this.stayActive = true;
      this.title = data.title;
      this.info = data.info;

      createPopper(data.element, this.$refs["popup"], {
        placement: "left",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: ({ reference, popper, placement }) => {
                if (placement === "left") {
                  return [0, 20];
                } else if (placement === "bottom") {
                  return [0, 20];
                } else {
                  return [0, 20];
                }
              },
            },
          },
          {
            name: "flip",
            options: {
              fallbackPlacements: ["bottom"],
            },
          },
          {
            name: "preventOverflow",
            options: {
              mainAxis: true,
              altAxis: true,
              padding: 20,
            },
          },
        ],
      });
    },
    removeStay() {
      this.stayActive = false;
      this.active = false;
    },
  },
};
</script>
