<template>
  <div id="leaderboard-container" class="#mx-auto #text-center #flex #flex-col #items-center">
    <h2 class="#font-righteous #text-white #text-[28px] #mt-4">We have a winner!</h2>
    <div class="#mt-[2vh]">
      <svg
        id="Laag_2"
        class="#h-[29vh] #mx-auto"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        data-name="Laag 2"
        viewBox="0 0 769.32 1041.4"
      >
        <defs>
          <filter id="outer-glow-1" filterUnits="userSpaceOnUse">
            <feOffset dx="0" dy="0" />
            <feGaussianBlur result="blur" stdDeviation="8.78" />
            <feFlood flood-color="#ffe600" flood-opacity="1" />
            <feComposite in2="blur" operator="in" />
            <feComposite in="SourceGraphic" />
          </filter>
          <filter id="outer-glow-2" filterUnits="userSpaceOnUse">
            <feOffset dx="0" dy="0" />
            <feGaussianBlur result="blur-2" stdDeviation="8.78" />
            <feFlood flood-color="#ffe600" flood-opacity="1" />
            <feComposite in2="blur-2" operator="in" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <path
          d="m683.74,158.33c.38.47.6,1.06.6,1.7v707.94c0,14-9.2,26.31-22.62,30.32-142.81,42.66-237.04,76.01-278.4,116.69-41.36-40.68-135.59-74.03-278.4-116.69-7.3-2.18-13.36-6.82-17.36-12.86"
          style="fill: none; stroke: #ea3d7c; stroke-linejoin: round; stroke-width: 40px"
        />
        <path
          d="m89.41,885.44c-3.35-5.05-5.26-11.08-5.26-17.46V160.03c0-1.27.88-2.38,2.13-2.63,67.37-13.57,142.84-122.58,210.8-122.58,0,0,39.83,52.09,88.09-6.89,48.26,58.98,88.08,6.89,88.08,6.89,67.97,0,143.44,109.01,210.81,122.58.62.13,1.15.47,1.53.94"
          style="fill: none; stroke: #4fc0f2; stroke-linejoin: round; stroke-width: 40px"
        />
        <path
          d="m684.84,157.4c1.24.25,2.13,1.36,2.13,2.63v707.94c0,14-9.2,26.31-22.62,30.32-142.81,42.66-237.04,76.01-278.4,116.69-41.36-40.68-135.59-74.03-278.4-116.69-13.42-4.01-22.62-16.32-22.62-30.32V160.03c0-1.27.88-2.38,2.13-2.63,67.37-13.57,142.84-122.58,210.8-122.58,0,0,39.83,52.09,88.09-6.89,48.26,58.98,88.08,6.89,88.08,6.89,67.97,0,143.44,109.01,210.81,122.58Z"
          style="fill: #fff; stroke: #24212b; stroke-miterlimit: 10; stroke-width: 5px"
        />
        <polygon
          points="534.54 378.86 534.54 207.28 385.95 121.49 237.36 207.28 237.36 378.86 385.95 464.64 534.54 378.86"
          style="fill: #4bc1ef"
        />

        <polygon
          points="54.9 174.64 54.9 861.44 32.94 831.03 32.94 205.05 54.9 174.64"
          style="fill: #fff16e; filter: url(#outer-glow-1)"
        />
        <polygon
          points="714.21 174.64 714.21 861.44 736.17 831.03 736.17 205.05 714.21 174.64"
          style="fill: #fff16e; filter: url(#outer-glow-2)"
        />
      </svg>
      <p
        id="winnerScore"
        class="#text-[5vh] #text-white #font-righteous #mx-auto #text-center #mt-[-24.5vh]"
      >
        {{ winner.score }}
      </p>
      <p
        id="winnerName"
        class="#text-[2vh] #text-pbblack #font-righteous #text-center #mt-[2vh] #mx-auto"
      >
        {{ winner.name }}
      </p>
      <div class="#flex #justify-center #gap-[0.5vh] #mt-[1.5vh]">
        <div
          style="clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)"
          class="#w-[3.3vh] #h-[3.3vh] #bg-vividmagenta #flex #justify-center #items-center"
        >
          <p id="misses" class="#text-white #text-[2vh] #font-righteous">
            {{ winner.misses }}
          </p>
        </div>
        <div
          style="clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)"
          class="#w-[3.3vh] #h-[3.3vh] #bg-brightcyan #flex #justify-center #items-center"
        >
          <p id="singles" class="#text-white #text-[2vh] #font-righteous">
            {{ winner.singles }}
          </p>
        </div>
        <div
          style="clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)"
          class="#w-[3.3vh] #h-[3.3vh] #bg-brightcyan #flex #justify-center #items-center"
        >
          <p id="doubles" class="#text-white #text-[2vh] #font-righteous">
            {{ winner.doubles }}
          </p>
        </div>
        <div
          style="clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)"
          class="#w-[3.3vh] #h-[3.3vh] #bg-brightcyan #flex #justify-center #items-center"
        >
          <p id="triples" class="#text-white #text-[2vh] #font-righteous">
            {{ winner.triples }}
          </p>
        </div>
      </div>

      <div class="#flex #justify-center #gap-[0.5vh]">
        <div class="#w-[3.3vh] #h-[3.3vh] #flex #justify-center #items-center">
          <p class="#text-inkdropdark #text-[2vh] #font-righteous">M</p>
        </div>
        <div class="#w-[3.3vh] #h-[3.3vh] #flex #justify-center #items-center">
          <p class="#text-inkdropdark #text-[2vh] #font-righteous">S</p>
        </div>
        <div class="#w-[3.3vh] #h-[3.3vh] #flex #justify-center #items-center">
          <p class="#text-inkdropdark #text-[2vh] #font-righteous">D</p>
        </div>
        <div class="#w-[3.3vh] #h-[3.3vh] #flex #justify-center #items-center">
          <p class="#text-inkdropdark #text-[2vh] #font-righteous">T</p>
        </div>
      </div>
    </div>
    <ol
      id="leaderboard"
      start="2"
      class="#text-sm #font-righteous #text-white #text-left #relative #left-3 #max-w-sm #list-decimal #list-outside #w-3/4 #mx-auto #mt-[6vh] #space-y-3"
    >
      <li v-for="player in scoreboardArray">
        <div class="#flex #justify-between">
          <div>
            <p>{{ player.name }}</p>
            <p class="#text-[12px]">
              M: {{ player.misses }} S: {{ player.singles }} D: {{ player.doubles }} T:
              {{ player.triples }}
            </p>
          </div>
          <div>
            <p>{{ player.score }}</p>
          </div>
        </div>
      </li>
    </ol>
    <div class="#w-full #flex #justify-center #fixed #bottom-4 #max-w-sm">
      <a
        class="#border-0 #mt-6 #font-semibold #block #mx-8 #w-full #bg-brightcyan #text-white #py-2 #px-4 #rounded-md #box-border"
        href="#"
        @click="againPopup = true"
        >Opnieuw spelen</a
      >
    </div>
    <div
      v-if="againPopup"
      class="#absolute #h-screen #w-screen #bg-black/50 #box-border #flex #justify-center #items-center"
    >
      <div class="#bg-white #rounded-md #w-11/12 #box-border #p-6 #max-w-sm">
        <p class="#font-bold">Weet je zeker dat je opnieuw wil spelen met dezelfde spelers?</p>
        <div class="#flex #justify-center #gap-x-4 #mt-6">
          <a
            class="#border-0 #font-semibold #block #bg-inkdroplight #text-inkdropdark #py-2 #px-4 #rounded-md #box-border"
            href="#"
            @click="againPopup = false"
            >Terug</a
          >
          <a
            class="#border-0 #font-semibold #block #bg-brightcyan #text-white #py-2 #px-4 #rounded-md #box-border"
            href="#"
            @click="$emit('again')"
            >Opnieuw spelen</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["scoreboardArray", "winner"],
  emits: ["again"],
  data() {
    return {
      againPopup: false,
    };
  },
};
</script>
