<template>
  <div class="#mb-2">
    <h3 class="#font-serif #text-lg #mb-[15px]">
      {{ __("Help onze dienstverlening te verbeteren") }}
    </h3>
    <p class="#mb-1">
      {{
        __(
          "Wij zijn bij Print&Bind constant bezig onze dienstverlening te verbeteren. Help ons mee door je ervaring met ons te delen. Wij vragen je daarom om deze graag zo specifiek mogelijk te beschrijven.",
        )
      }}
    </p>

    <div v-if="mailSend" class="#text-green-600 #my-2">
      <h3>{{ __("Bedankt voor je opmerking") }}</h3>
      <p>{{ __("De email is verstuurd") }}</p>
    </div>
    <div v-else>
      <form class="#mt-8" method="post" enctype="multipart/form-data">
        <InputArea
          v-model="form.comment"
          class="#w-full"
          :error="firstError('comment')"
          :label="__('Je ervaring')"
          :required="true"
        />
        <SubmitButton
          btn-class="grey"
          size="sm"
          class="#mt-6 max-md:!#px-[12px] max-md:!#py-[6px]"
          :text="__('Opslaan')"
          @click="submitForm"
        >
        </SubmitButton>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { lang, form } from "../mixins";
import InputArea from "../components/InputArea.vue";
import SubmitButton from "../components/Button.vue";

export default {
  name: "FeedbackForm",
  components: { InputArea, SubmitButton },
  mixins: [lang, form],
  data() {
    return {
      mailSend: false,
      customerData: window.customerData || [],
      orderData: window.orderData || [],
      form: {
        comment: "",
        name_contact: "",
        company: "",
        email: "",
        customer_id: "",
        order_id: "",
      },
    };
  },
  computed: {
    ...mapGetters(["user", "route"]),
  },
  mounted() {
    this.form.name_contact = this.customerData.name_contact;
    this.form.company = this.customerData.company;
    this.form.email = this.customerData.email;
    this.form.customer_id = this.customerData.id;
    this.form.order_id = this.orderData.id;
  },
  methods: {
    submitForm: async function () {
      let response = await fetch(this.route("form.send-feedback"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          ...this.form,
        }),
      });

      if (response.ok) {
        this.mailSend = true;
      } else if (response.status === 422 || response.status === 400) {
        const body = await response.json();
        this.formErrors = body;
      } else {
        await response.text();
      }
    },
  },
};
</script>
