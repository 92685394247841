<script setup>
import { useSetup } from "../composables/option";
import { useRadioOptionProps } from "../composables/props";
import UploaderField from "../fields/UploaderField.vue";

defineOptions({
  key: "document",
});
const props = defineProps(useRadioOptionProps());
const { check, key } = useSetup(props);
</script>

<template>
  <UploaderField v-bind="$props" :name="key" />
</template>
