<template>
  <Listbox as="div">
    <div class="#relative #mt-2">
      <ListboxButton
        class="#relative #w-full #cursor-pointer #rounded-md #bg-white #py-3 #px-4 #text-left #text-inkdropdark #shadow-sm #ring-0 #border-solid #border-[1px] #border-neutral-300 focus:#outline-none focus:#ring-2 focus:#ring-brightcyan sm:#leading-6"
      >
        <span v-if="items[value]" class="#flex #items-center #h-10">
          <img v-if="items[value].image" :src="items[value].image" alt="" class="#mr-3" />
          <span class="#block #truncate">{{ items[value].name }}</span>
        </span>
        <span v-else class="#flex #items-center #h-10">{{ __("Selecteer een bank") }}</span>
        <span
          class="#pointer-events-none #absolute #inset-y-0 #right-0 #ml-3 #flex #items-center #pr-2"
        >
          <svg-use id="angle-down" type="solid" class="#h-6 #w-[11px] #fill-inkdropdark" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="#transition #ease-in #duration-100"
        leave-from-class="#opacity-100"
        leave-to-class="#opacity-0"
      >
        <ListboxOptions
          class="#absolute #z-10 #mt-1 #max-h-56 #w-full #overflow-auto #rounded-md #bg-white #py-1 #text-base #shadow-lg #ring-1 #ring-black #ring-opacity-5 focus:#outline-none"
        >
          <ListboxOption
            v-for="(item, key) in items"
            :key="key"
            as="template"
            @click="changeValue(key)"
          >
            <li
              class="#relative #cursor-pointer #select-none #py-2 #pl-3 #pr-9 hover:#bg-inkdroplight"
              :class="{
                '#opacity-50 !#cursor-default': item === items[value],
              }"
            >
              <div class="#flex #items-center">
                <img v-if="item.image" :src="item.image" alt="" class="#mr-3" />
                <span>{{ item.name }}</span>
              </div>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { lang } from "../mixins";
import SvgUse from "../../vue3/components/SvgUse.vue";

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    SvgUse,
  },
  mixins: [lang],
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    items: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
    },
  },
  methods: {
    changeValue(key) {
      this.$emit("update:modelValue", key);
    },
  },
};
</script>
