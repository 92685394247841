<template>
  <TrustpilotWidget location="homepage"></TrustpilotWidget>
</template>

<script>
import TrustpilotWidget from "../components/TrustpilotWidget.vue";

export default {
  components: {
    TrustpilotWidget,
  },
};
</script>
