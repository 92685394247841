<template>
  <a v-if="url" :href="url" target="_blank" :class="s.link"> Aanpassen </a>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["cms_user"]),
    url() {
      const { editUrl = null } = this.cms_user ?? {};
      return editUrl;
    },
  },
};
</script>

<style module="s">
.link {
  font-size: 10px;
  vertical-align: top;
}
</style>
