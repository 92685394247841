<script setup>
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";
import { computed, watch } from "vue";
import TopcoatNoneOption from "./TopcoatNoneOption.vue";
import { useChecks, useCMS, useSetup } from "../composables/option";
import TopcoatOption from "./TopcoatOption.vue";
import { useOrderForm } from "../../modules/orderModule";

defineOptions({
  key: "coating",
  value: "front",
  label: "Voorkant",
});
const props = defineProps(useRadioOptionProps());
const { check, name, key, option, label, value } = useSetup(props);
const { disabled } = useCMS(name, check);
const { isRequiredIfOther, isOneOf } = useChecks();
const form = useOrderForm();

const hidden = computed(() => {
  return form.isChecked(TopcoatNoneOption);
});

watch(
  hidden,
  (newValue) => {
    if (newValue && form.isChecked(option)) {
      form.deleteItem(key);
    } else if (!newValue && props.initial) {
      form.updateItem({
        name: key,
        value: value,
      });
    }
  },
  { immediate: true },
);

check(isRequiredIfOther(TopcoatOption));
check(isOneOf(props.choices));
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :initial="false"
    :hidden="hidden"
    :name="key"
    :value="value"
    :label="label"
  />
</template>
