<template>
  <v-html v-if="chunks === null" :html="html" />
  <template v-else>
    <v-html :html="chunks.left" />
    <span class="svg-container">
      <v-html :html="chunks.inner" />
      <svg-use :id="id" v-bind="$attrs" />
    </span>
    <v-html :html="chunks.right" />
  </template>
</template>

<script>
import VHtml from "./VHtml.vue";
import SvgUse from "./SvgUse.vue";
export default {
  components: { VHtml, SvgUse },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      html: null,
      chunks: null,
    };
  },
  created() {
    this.html = this.$slots.default()[0].children;

    const regex = /(?<left>.*)\[(?<inner>.+)\](?<right>.*)/;
    let matches = regex.exec(this.html);

    if (matches === null) {
      return;
    }

    this.chunks = {
      left: matches[1],
      inner: matches[2],
      right: matches[3],
    };
  },
};
</script>
