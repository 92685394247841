import { getCurrentInstance, useSSRContext } from "vue";
import { FatalFormError } from "./errors";
import { useStore } from "vuex";

export function useCMS(name, check) {
  let disabled = false;
  let tooltipTitle = null;
  let tooltipContent = null;

  let source = null;
  if (import.meta.env.SSR) {
    const ctx = useSSRContext();
    source = ctx.order_settings;
  } else {
    source = window.order_settings;
  }

  if ("option_info" in source) {
    const option_info = source.option_info.find((i) => i.option.key === name);
    if (option_info) {
      tooltipTitle = option_info.title;
      tooltipContent = option_info.content;
    }
  }

  if ("disabled" in source) {
    const disabled_info = source.disabled.find((i) => i.option.key === name);
    if (disabled_info) {
      disabled = true;

      check((option) => {
        if (!option.isChecked()) {
          return;
        }

        throw new FatalFormError(disabled_info.error_text);
      });
    }
  }

  return { disabled, tooltipTitle, tooltipContent };
}

export function useChecks() {
  const isRequired = () => {
    return (option) => {
      if (option.isEmpty()) {
        throw new FatalFormError("er moet een waarde ingevuld worden");
      }
    };
  };

  const isOneOf = (choices) => {
    return (option) => {
      if (option.isEmpty()) {
        return;
      }

      if (!choices.includes(option.getValue())) {
        throw new FatalFormError("moet 1 van de volgende waardes zijn: " + choices.join(", "));
      }
    };
  };

  const isRequiredIfOther = (other) => {
    return (option, form) => {
      if (!form.isChecked(other) && option.isEmpty()) {
        return;
      }
      if (!form.isChecked(other) && !option.isEmpty()) {
        throw new FatalFormError(
          `is alleen mogelijk als ${other.key} de waarde ${other.value} heeft`,
        );
      }
      if (option.isEmpty()) {
        throw new FatalFormError(
          `er moet een waarde ingevuld worden als ${other.key} de waarde ${other.value} heeft`,
        );
      }
    };
  };

  const isInteger = () => {
    return (option) => {
      if (option.isEmpty()) {
        return;
      }

      if (!option.isInt()) {
        throw new FatalFormError("moet een getal zijn");
      }
    };
  };

  const isString = () => {
    return (option) => {
      if (option.isEmpty()) {
        return;
      }

      if (!option.isString()) {
        throw new FatalFormError("moet tekst zijnzijn");
      }
    };
  };

  const isMinString = (min) => {
    return (option) => {
      if (option.isEmpty() || !option.isString()) {
        return;
      }

      if (option.getValue().length < min) {
        throw new FatalFormError(`moet minimaal ${min} characters zijn`);
      }
    };
  };

  const isMaxString = (max) => {
    return (option) => {
      if (option.isEmpty() || !option.isString()) {
        return;
      }

      if (option.getValue().length > max) {
        throw new FatalFormError(`mag maximaal ${max} characters zijn`);
      }
    };
  };

  const isMinInteger = (min) => {
    return (option) => {
      if (option.isEmpty() || !option.isInt()) {
        return;
      }

      if (option.getValue() < min) {
        throw new FatalFormError(`moet minimaal ${min} zijn`);
      }
    };
  };

  const isMaxInteger = (max) => {
    return (option) => {
      if (option.isEmpty() || !option.isInt()) {
        return;
      }

      if (option.getValue() > max) {
        throw new FatalFormError(`mag maximaal ${max} zijn`);
      }
    };
  };

  return {
    isRequired,
    isOneOf,
    isRequiredIfOther,
    isInteger,
    isMinInteger,
    isMinString,
    isMaxInteger,
    isString,
    isMaxString,
  };
}

export function useSetup(props) {
  const store = useStore();
  const option = getCurrentInstance().type;
  const name = option.__name;
  const { key, description, placeholder, label, value, key_width, key_height } = option;

  const check = (fn) => {
    store.commit("order/addCheck", { option, section: props.section, fn });
  };

  return {
    option,
    check,
    name,
    key,
    description,
    placeholder,
    label,
    value,
    key_width,
    key_height,
  };
}
