<script setup>
import { computed } from "vue";
import { useOptionProps } from "../composables/props";
import SvgUse from "../../components/SvgUse.vue";
import { useOrderForm } from "../../modules/orderModule";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  ...useOptionProps(),
});

const form = useOrderForm();

const rowClasses = computed(() => {
  let classes = {
    "big-spacing-top": props.first,
    "border-bottom": props.divider && props.last,
  };
  classes[`tr_${props.name}`] = true;

  return classes;
});

const fakeUpload = () => {
  if (form.getValue("default_doc") === 1) {
    form.updateItem({ name: "filenames", value: "dummy.pdf" });
    form.updateItem({ name: "default_doc", value: 0 });
  } else {
    form.updateItem({ name: "filenames", value: "" });
    form.updateItem({ name: "default_doc", value: 1 });
    form.deleteFileinfo();
  }
};
</script>

<template>
  <tr :class="rowClasses">
    <td>
      {{ first ? section : "" }}
    </td>
    <td colspan="2">
      <span id="btnDocSelect" class="docselect lightboxbtn btn btn-primary" @click="fakeUpload">
        <span class="tailwind">
          <svg-use
            id="file-plus"
            type="solid"
            class="#fill-current #w-[14px] #h-[17px] #mr-[5px]"
          ></svg-use>
        </span>
        <span>Voeg toe</span>
      </span>
    </td>
  </tr>
</template>
