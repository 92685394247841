<template>
  <LoginForm location="loginpage"></LoginForm>
</template>

<script>
import LoginForm from "../components/LoginForm.vue";
export default {
  components: {
    LoginForm,
  },
};
</script>
