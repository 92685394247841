<template>
  <p class="#flex #justify-between #px-8 #font-righteous">
    {{ name }} <a class="#text-red-500" @click="$emit('delete', id)">X</a>
  </p>
</template>

<script>
export default {
  props: ["name", "id", "thrownArrows"],
  emits: ["delete"],
  data() {
    return {};
  },
};
</script>
