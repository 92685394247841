<script setup>
import { computed, useTemplateRef } from "vue";
import SvgUse from "../../components/SvgUse.vue";
import OptionRow from "../OptionRow.vue";
import { useRadioOptionProps } from "../composables/props";
import { useOrderForm } from "../../modules/orderModule";
import camelCase from "lodash.camelcase";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  ...useRadioOptionProps(),
});

const form = useOrderForm();

const row = useTemplateRef("row");

const id = computed(() => {
  return camelCase(props.name + "_" + props.value);
});

const classes = computed(() => {
  return {
    deactive: props.disabled,
  };
});

if (props.initial && !import.meta.env.SSR) {
  form.updateItem({
    name: props.name,
    value: props.value,
  });
}

const checked = computed(() => {
  return form.getValue(props.name) === props.value;
});

const openTooltip = () => {
  form.openTooltip({
    title: props.iconTitle,
    content: props.iconContent,
    anchor: row.value.container,
  });
};

const update = () => {
  form.updateItem({
    name: props.name,
    value: props.value,
  });
};
</script>

<template>
  <OptionRow v-show="!hidden" v-bind="$props" ref="row">
    <div class="radio fancy-radio">
      <input
        :id="id"
        type="radio"
        :value="value"
        :name="name"
        :checked="checked"
        @change="update"
      />
      <label class="fancy-control-ui" :for="id" />
      <label :class="classes" :for="id">
        {{ label }}
        <template v-if="props.icon && !props.iconTitle">
          <span class="tailwind" style="display: inline-block">
            <svg-use
              :id="props.icon"
              :type="props.iconType"
              :class="props.iconClass"
              :style="{ fill: props.iconColor }"
            />
          </span>
        </template>
      </label>
      <template v-if="props.icon && props.iconTitle">
        <a
          href="javascript:;"
          class="tailwind"
          style="display: inline-block; border: 0"
          @click="openTooltip"
        >
          <svg-use
            :id="props.icon"
            :type="props.iconType"
            :class="props.iconClass"
            :style="{ fill: props.iconColor }"
          />
        </a>
      </template>
    </div>
  </OptionRow>
</template>
