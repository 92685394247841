<template>
  <h3 class="#font-serif #text-lg #mb-[10px]">
    {{ lastAction.title }}
  </h3>
  <div>
    <div class="#flex #items-center #justify-start #gap-x-[15px]">
      <div class="#h-[1px] #bg-[#d5d5d5] #w-full"></div>
      <p class="#text-sm #text-[#707070] #min-w-max">
        {{ lastAction.date }}
      </p>
    </div>
    <div>
      <div class="#flex #justify-start #items-start #gap-x-[15px] #py-[15px]">
        <svg-use
          v-if="lastAction.icon"
          :id="lastAction.icon"
          type="solid"
          class="#h-[28px] #min-w-[28px] #w-[28px] #fill-[#a8a8a8] #mt-[7px]"
        >
        </svg-use>
        <svg-use
          v-else
          id="circle-info"
          type="solid"
          class="#h-[28px] #min-w-[28px] #w-[28px] #fill-[#a8a8a8] #mt-[7px]"
        ></svg-use>
        <div class="#w-full">
          <p class="#font-medium">
            {{ lastAction.title }}
          </p>
          <p v-html="lastAction.text"></p>
          <div class="#flex #flex-wrap #gap-4 #mt-4">
            <template v-for="(obj, i) in lastAction.finished_photos" :key="i">
              <a
                v-if="(i < 1 && !showAllFinishedPhotos) || showAllFinishedPhotos"
                :href="obj.image"
                class="#w-48 #h-36 hover:#opacity-50"
                data-lightbox="images"
              >
                <img :src="obj.thumb" alt="" class="#w-full #h-full #object-cover #object-center" />
              </a>
            </template>
            <div
              v-if="
                !showAllFinishedPhotos &&
                lastAction.finished_photos &&
                lastAction.finished_photos.length > 1
              "
              class="#w-48 #h-36 #bg-inkdroplight #flex #justify-center #items-center #cursor-pointer hover:#opacity-50"
              @click="showAllFinishedPhotos = true"
            >
              <p class="#text-inkdropdark #font-bold #text-[150%]">
                +{{ lastAction.finished_photos.length - 1 }}
              </p>
            </div>
          </div>
        </div>
        <p class="#text-sm #text-[#707070] #min-w-max">
          {{ lastAction.time }}
        </p>
      </div>
    </div>
  </div>
  <div class="#transition-all #grid" :class="show ? '#grid-rows-[1fr]' : '#grid-rows-[0fr]'">
    <div class="#overflow-hidden">
      <div v-for="(item, index) in getItems()" :key="item">
        <div
          v-if="sameDate(index - 1) && lastAction.date !== item.date"
          class="#flex #items-center #justify-start #gap-x-[15px]"
        >
          <div class="#h-[1px] #bg-[#d5d5d5] #w-full"></div>
          <p class="#text-sm #text-[#707070] #min-w-max">
            {{ item.date }}
          </p>
        </div>
        <div class="#flex #justify-between #items-start #gap-x-[15px] #py-[15px]">
          <div class="#flex #justify-start #gap-x-[15px] #items-start">
            <svg-use
              v-if="item.icon"
              :id="item.icon ?? 'circle-info'"
              type="solid"
              class="#h-[28px] #min-w-[28px] #w-[28px] #fill-[#a8a8a8] #mt-[7px]"
            >
            </svg-use>
            <svg-use
              v-else
              id="circle-info"
              type="solid"
              class="#h-[28px] #min-w-[28px] #w-[28px] #fill-[#a8a8a8] #mt-[7px]"
            ></svg-use>
            <div>
              <p class="#font-medium">
                {{ item.title }}
              </p>
              <p v-html="item.text"></p>
              <div class="#flex #flex-wrap #gap-4 #mt-4">
                <template v-for="(obj, i) in item.finished_photos" :key="i">
                  <a
                    v-if="(i < 1 && !showAllFinishedPhotos) || showAllFinishedPhotos"
                    :href="obj.image"
                    class="#w-48 #h-36 hover:#opacity-50"
                    data-lightbox="images"
                  >
                    <img
                      :src="obj.thumb"
                      alt=""
                      class="#w-full #h-full #object-cover #object-center"
                    />
                  </a>
                </template>
                <div
                  v-if="
                    !showAllFinishedPhotos &&
                    item.finished_photos &&
                    item.finished_photos.length > 1
                  "
                  class="#w-48 #h-36 #bg-inkdroplight #flex #justify-center #items-center #cursor-pointer hover:#opacity-50"
                  @click="showAllFinishedPhotos = true"
                >
                  <p class="#text-inkdropdark #font-bold #text-[150%]">
                    +{{ item.finished_photos.length - 1 }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p class="#text-sm #text-[#707070] #min-w-max">
            {{ item.time }}
          </p>
        </div>
      </div>
      <PaginationButtons
        :page="historyPage"
        :pages="Math.ceil(items.length / itemsPerPage)"
        @change="changePage"
      ></PaginationButtons>
    </div>
  </div>
  <div class="#w-full #flex #justify-end #mt-[15px]">
    <Button
      btn-class="secondary"
      :text="show ? __('Toon minder') : __('Toon meer')"
      :disabled="loadingActions"
      @click="toggleHistory"
    ></Button>
  </div>
</template>

<script>
import SvgUse from "../components/SvgUse.vue";
import Button from "../components/Button.vue";
import { lang } from "../mixins";
import PaginationButtons from "./PaginationButtons.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    SvgUse,
    Button,
    PaginationButtons,
  },
  mixins: [lang],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      historyPage: 1,
      itemsPerPage: 9,
      items: [],
      lastAction: {},
      postcode: null,
      show: false,
      showAllFinishedPhotos: false,
      loadingActions: false,
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  mounted() {
    this.postcode = new URLSearchParams(window.location.search).get("postcode");
    this.getLatestAction();
  },
  methods: {
    sameDate(index) {
      if (index < 0) {
        return true;
      }
      return this.items[index].date !== this.items[index + 1].date;
    },
    changePage(page) {
      this.historyPage = page;
    },
    async toggleHistory() {
      if (this.items.length === 0) {
        await this.getActions();
      }
      this.show = !this.show;
    },
    async openHistory() {
      if (this.items.length === 0) {
        await this.getActions();
      }
      this.show = true;
    },
    async getActions() {
      this.loadingActions = true;
      const obj = {
        order_id: this.order.id,
        action_id: this.lastAction.id,
        has_finished_photos: this.lastAction.finished_photos.length > 0,
        postcode: this.postcode,
      };

      let response = await fetch(this.route("orders.actions"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(obj),
      });
      if (response.ok) {
        this.items = await response.json();
      } else {
        await response.text();
      }

      this.loadingActions = false;
    },
    async getLatestAction() {
      let obj = { order_id: this.order.id, postcode: this.postcode };

      let response = await fetch(this.route("orders.last-action"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(obj),
      });
      if (response.ok) {
        this.lastAction = await response.json();
      } else {
        await response.text();
      }
    },
    getItems() {
      const pages = Math.ceil(this.items.length / this.itemsPerPage);
      let tempArray = [];

      for (let i = 0; i < pages; i++) {
        let start = i * this.itemsPerPage;
        let end = (i + 1) * this.itemsPerPage;

        if (end > this.items.length) {
          end = this.items.length;
        }

        tempArray.push(this.items.slice(start, end));
      }

      return tempArray[this.historyPage - 1];
    },
  },
};
</script>
