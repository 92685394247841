<template>
  <CarouselWidget
    ref="carouselRef"
    :items="reviews"
    class="#w-full #justify-between"
    :container-classes="'#max-w-[1180px]'"
    :location="location"
    @get-more-data="getReviews()"
  >
    <TrustpilotReview
      v-for="review in reviews"
      :key="review.id"
      :title="review.title"
      :trustpilot-id="review.trustpilot_id"
      :rating="review.score"
      :time-diff="review.time_diff"
      :description="review.description"
      :name="review.name"
      :location-styling="location"
      :score="trustpilotScore"
      :total="trustpilotTotal"
    ></TrustpilotReview>
  </CarouselWidget>
</template>

<script>
import TrustpilotReview from "../components/TrustpilotReview.vue";
import CarouselWidget from "./CarouselWidget.vue";
import { mapGetters } from "vuex";
import { mobile } from "../mixins";

export default {
  components: {
    TrustpilotReview,
    CarouselWidget,
  },
  mixins: [mobile],
  props: {
    location: {
      type: String,
      default: "productpage",
    },
  },
  data() {
    return {
      reviewLimit: 30,
      reviewInterval: 6,

      isReviewLimitReached: false,

      reviews: window.reviews || [],
      product: window.product || null,
      trustpilotScore: window.score || 0,
      trustpilotTotal: window.totalReviews || 0,
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  methods: {
    async getReviews() {
      if (this.isReviewLimitReached) return;

      const url = new URL(window.location.origin + this.route("reviews.index"));
      url.searchParams.append("count", this.reviewInterval);
      url.searchParams.append("start", this.$refs.carouselRef.lastReviewIndex + 1);
      if (this.location === "homepage") {
        url.searchParams.append("source", "trustpilot_business");
      }
      if (this.product) {
        url.searchParams.append("product", this.product);
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });
      if (response.ok) {
        let newReviews = await response.json();
        this.reviews = [...this.reviews, ...newReviews];

        if (this.$refs.carouselRef.lastReviewIndex >= this.reviewLimit) {
          this.reviews = this.reviews.slice(0, this.reviewLimit);
          this.isReviewLimitReached = true;
        }
      } else {
        await response.text();
      }
    },
  },
};
</script>
