<template>
  <label class="#group #mt-4 #cursor-pointer #text-xs #flex #items-start #justify-start #gap-x-2">
    <input
      :id="inputId"
      v-model="value"
      type="checkbox"
      name="paymentmethod"
      class="#peer/checkbox #sr-only"
    />
    <div
      class="#w-[16px] #h-[16px] #min-w-[16px] #min-h-[16px] #border-[1px] #border-solid group-hover:!#border-brightcyan #inline-flex #justify-center #items-center"
      :class="error ? '#border-[#d9534f]' : '#border-[#d5d5d5]'"
    >
      <div v-if="value" class="#inline-block #w-full #h-full #rotate-45 #relative #top-[-6px]">
        <div class="#absolute #w-[3px] #h-[9px] #bg-brightcyan #left-[11px] #top-[6px]"></div>
        <div class="#absolute #w-[3px] #h-[3px] #bg-brightcyan #left-[8px] #top-[12px]"></div>
      </div>
    </div>
    <slot></slot>
  </label>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    inputId: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
