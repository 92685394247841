<template>
  <h3 class="#font-serif #text-lg #mb-[10px]">
    {{ __("Status") }}
  </h3>
  <div
    v-if="data.steps.length > 0"
    class="#flex #items-center #justify-between #relative #mx-[15px] #mt-[30px]"
  >
    <div
      v-for="(step, index) in data.steps"
      :key="step.name"
      class="#bg-[#a8a8a8] #w-14 #h-14 #rounded-full #z-[1] #text-center #flex #justify-center"
      :class="{ '#select-none #pointer-events-none': !step.completed }"
      :style="step.completed ? { background: color } : {}"
    >
      <p
        class="#text-xs #text-center #mt-[-30px] md:#text-sm #font-medium #whitespace-nowrap"
        :class="{ '#opacity-50': !step.completed }"
      >
        {{ step.name }}
      </p>
      <svg-use
        :id="step.icon"
        type="solid"
        class="#absolute #h-[1em] #w-[1em] #fill-white #self-center"
        :class="{ '#opacity-50': !step.completed }"
      ></svg-use>
      <div
        class="#peer #absolute #h-40 #-top-12 #w-1/4 #cursor-pointer"
        @click="$emit('openHistory')"
      ></div>
      <div
        v-if="step.info"
        class="#bg-inkdroplight #text-left #absolute #w-max #p-[20px] #top-[42px] #rounded-[4px] #max-w-[250px] #invisible #opacity-0 #transition-all peer-hover:#visible peer-hover:#opacity-100 hover:#visible hover:#opacity-100"
        :class="{
          '#left-0 #right-auto': index === 0,
          '#right-0 #left-auto': index === data.steps.length - 1,
        }"
      >
        <div
          class="#w-0 #h-0 #border-l-[10px] #border-l-transparent #border-r-[10px] #border-r-transparent #border-solid #border-b-[15px] #border-b-inkdroplight #absolute #top-[-13px] #-translate-x-1/2 #left-1/2"
          :class="{
            '#left-[17px] #right-auto': index === 0,
            '#right-[-3px] #left-auto': index === data.steps.length - 1,
          }"
        ></div>
        <div class="#flex #justify-between #items-center #text-[#707070] #text-sm #mb-4">
          <p>{{ step.date }}</p>
          <p>{{ step.time }}</p>
        </div>
        <p class="#inline #text-sm" v-html="step.info"></p>
      </div>
    </div>
    <div class="#bg-[#a8a8a8] #w-full #h-2 #rounded-full #absolute #z-0">
      <div :style="{ background: color, width: width }" class="#h-full #rounded-full"></div>
    </div>
  </div>
  <div v-else>
    <p>
      {{
        __(
          "De status van je bestelling is helaas onbekend. Neem contact op met de klantenservice voor meer informatie.",
        )
      }}
    </p>
  </div>
</template>

<script>
import SvgUse from "../components/SvgUse.vue";
import { lang } from "../mixins";

export default {
  components: {
    SvgUse,
  },
  mixins: [lang],
  props: {
    data: {
      type: Object,
      required: true,
    },
    width: {
      type: String,
      default: "0%",
    },
    color: {
      type: String,
      default: "",
    },
  },
  emits: ["openHistory", "mount"],
  mounted() {
    this.$emit("mount");
  },
};
</script>
