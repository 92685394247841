<script setup>
import { useChecks, useCMS, useSetup } from "../composables/option";
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";

defineOptions({
  key: "production_method",
  value: "standard",
  label: "Standaard (1 dag)",
});
const props = defineProps(useRadioOptionProps());
const { check, name, key, label, value } = useSetup(props);
const { disabled, tooltipTitle, tooltipContent } = useCMS(name, check);
const { isOneOf } = useChecks();

check(isOneOf(props.choices));
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :name="key"
    :value="value"
    :label="label"
  />
</template>
