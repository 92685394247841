<template>
  <FadeIn>
    <p v-if="show" :class="s.text" :style="[{ color }, style]">
      {{ message }}
    </p>
  </FadeIn>
</template>

<script>
import FadeIn from "./FadeIn.vue";
export default {
  components: { FadeIn },
  props: {
    style: {
      type: Object,
      default: {},
    },
  },
  emits: ["done"],
  data() {
    return {
      show: false,
      message: null,
      type: null,
      timeout: null,
    };
  },
  computed: {
    color() {
      return this.type === "success" ? "#5cb85c" : "#d9534f";
    },
  },
  unmounted() {
    clearTimeout(this.timeout);
  },
  methods: {
    flash(message, type = "success", duration = 2000) {
      this.message = message;
      this.type = type;
      this.show = true;
      this.timeout = setTimeout(() => {
        this.show = false;
        this.$emit("done");
      }, duration);
    },
  },
};
</script>

<style module="s">
.text {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 17px;
}

.fadeEnterActive,
.fadeLeaveActive {
  transition: opacity 0.5s ease;
}

.fadeEnterFrom,
.fadeLeaveTo {
  opacity: 0;
}
</style>
