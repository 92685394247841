<template>
  <div id="play-container" class="#ml-4 #mr-4">
    <div
      style="height: 25vh"
      class="#h-60 #rounded-full #flex #justify-center #items-center #border-radius #ml-auto #mr-auto #mb-5 #mt-[6vh] #relative"
    >
      <img src="/img/number_bg.svg" style="" class="#h-full" alt="" />
      <h2
        class="#text-zinc-900 #text-center #z-10 #absolute #font-righteous"
        :class="{
          '#text-[5vh]': currentGoal === 'Bull',
          '#text-[7vh]': currentGoal <= 20,
        }"
      >
        {{ currentGoal }}
      </h2>
    </div>

    <div class="#flex #justify-between #w-full #max-w-xs #ml-auto #mr-auto #text-white #text-xl">
      <p id="current-player" class="#font-righteous">
        {{ currentPlayer.name }}
      </p>
      <p id="scorelabel" class="#font-bold #font-righteous">
        {{ currentPlayer.score }}
      </p>
    </div>

    <div class="#flex #justify-center #mt-5 #gap-9">
      <div
        id="arrow-1"
        class="#text-white #font-righteous #text-xl #w-12 #h-12 #rounded-full #flex #justify-center #items-center"
        :class="{
          '#bg-green-500': currentPlayer.thrownArrows[0] >= 0,
          '#bg-red-500': currentPlayer.thrownArrows[0] < 0,
          '#bg-inkdroplight': currentPlayer.thrownArrows[0] === undefined,
        }"
      >
        {{ currentPlayer.thrownArrows[0] }}
      </div>
      <div
        id="arrow-2"
        class="#text-white #font-righteous #text-xl #w-12 #h-12 #rounded-full #flex #justify-center #items-center"
        :class="{
          '#bg-green-500': currentPlayer.thrownArrows[1] >= 0,
          '#bg-red-500': currentPlayer.thrownArrows[1] < 0,
          '#bg-inkdroplight': currentPlayer.thrownArrows[1] === undefined,
        }"
      >
        {{ currentPlayer.thrownArrows[1] }}
      </div>
      <div
        id="arrow-3"
        class="#text-white #font-righteous #text-xl #w-12 #h-12 #rounded-full #flex #justify-center #items-center"
        :class="{
          '#bg-green-500': currentPlayer.thrownArrows[2] >= 0,
          '#bg-red-500': currentPlayer.thrownArrows[2] < 0,
          '#bg-inkdroplight': currentPlayer.thrownArrows[2] === undefined,
        }"
      >
        {{ currentPlayer.thrownArrows[2] }}
      </div>
    </div>
    <!-- <div v-if="uploaderror" class="#absolute #h-screen #w-screen #bg-black/50 #box-border #flex #justify-center #items-center #top-0 #left-0 #z-50">
      <div class="#bg-white #rounded-md #w-11/12 #box-border #p-6 #max-w-sm">
        <p class="#font-bold #text-inkdropdark">Het is mislukt om de score te versturen. Kijk of je verbonden bent met het internet en probeer het opnieuw.</p>
        <div class="#flex #justify-center #gap-x-4 #mt-6">
          <a @click="$emit('tryagain')" class="#border-0 #font-semibold #block #bg-brightcyan #text-white #py-2 #px-4 #rounded-md #box-border" href="#">Probeer opnieuw</a>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: ["currentPlayer", "currentGoal", "uploaderror"],
  emits: ["tryagain"],
};
</script>
