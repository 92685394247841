<template>
  <span
    class="spinner #flex #justify-center #items-center #gap-x-1 #relative #w-full"
    :class="styling"
  >
    <div :style="rect1" />
    <div :style="rect2" />
    <div :style="rect3" />
  </span>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "md",
    },
  },
  computed: {
    rect1() {
      return {
        "background-color": "#636363",
        width: "7px",
        height: "25px",
        animation: "rect 1.2s infinite ease-in-out",
      };
    },
    rect2() {
      return {
        "background-color": "#e6007e",
        width: "7px",
        height: "25px",
        animation: "rect2 1.2s -1.1s infinite ease-in-out",
      };
    },
    rect3() {
      return {
        "background-color": "#398ccb",
        width: "7px",
        height: "25px",
        animation: "rect3 1.2s -1s infinite ease-in-out",
      };
    },
    styling() {
      return {
        "#scale-75": this.size === "sm",
      };
    },
  },
};
</script>
<style>
@keyframes rect {
  0% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }

  40% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
}

@keyframes rect2 {
  0% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }

  40% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
}

@keyframes rect3 {
  0% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }

  40% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
}
</style>
