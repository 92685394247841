<script setup>
import { computed } from "vue";
import OptionRow from "../OptionRow.vue";
import { useIntegerOptionProps } from "../composables/props";
import { useOrderForm } from "../../modules/orderModule";
import camelCase from "lodash.camelcase";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  ...useIntegerOptionProps(),
});

const form = useOrderForm();

const id = computed(() => {
  return camelCase(props.name);
});

if (props.initial && !import.meta.env.SSR) {
  form.updateItem({
    name: props.name,
    value: props.initial,
  });
}

const update = (event) => {
  if (!isNaN(parseInt(event.target.value))) {
    form.updateItem({
      name: props.name,
      value: parseInt(event.target.value),
    });
  } else {
    form.deleteItem(props.name);
  }
};

const value = computed(() => {
  return form.getValue(props.name);
});
</script>

<template>
  <OptionRow v-bind="$props">
    <input
      :id="id"
      :value="value"
      :name="name"
      type="number"
      autocomplete="off"
      inputmode="numeric"
      :disabled="disabled"
      :placeholder="placeholder"
      style="max-width: 70px"
      @input="update"
    />
  </OptionRow>
</template>
