<script setup>
import { computed, onMounted, ref, watch } from "vue";
import * as products from "./products";
import { FatalFormError } from "./composables/errors";
import FatalError from "./messages/FatalError.vue";
import OrderPrices from "./OrderPrices.vue";
import OrderTooltip from "./OrderTooltip.vue";
import { useStore } from "vuex";
import SvgUse from "../components/SvgUse.vue";
import { useOrderForm } from "../modules/orderModule";
import debounce from "lodash.debounce";

const props = defineProps({
  product: {
    type: String,
    default: "",
  },
});

const form = useOrderForm();
const store = useStore();

const component = computed(() => {
  for (const [, entry] of Object.entries(products)) {
    if (entry.slug === props.product) {
      return entry;
    }
    let aliases = entry.aliases ?? [];
    if (aliases.find((item) => item.slug === props.product)) {
      return entry;
    }
  }

  return null;
});

const title = computed(() => {
  for (const [, entry] of Object.entries(products)) {
    if (entry.slug === props.product) {
      return entry.title;
    }

    let aliases = entry.aliases ?? [];
    let alias = aliases.find((item) => item.slug === props.product);
    if (alias) {
      return alias.title;
    }
  }

  return null;
});

const messageComponent = (message) => {
  if (message instanceof FatalFormError) {
    return FatalError;
  }

  return null;
};

const errors = ref([]);
const calculation = ref(null);

const sumbit = async () => {
  let data = null;

  try {
    console.log("AJAX call: article store");
    const response = await fetch(store.getters.route("orders.articles.store"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(store.state.order.form),
    });

    if (!response.ok) {
      await response.text();
      errors.value.push(new FatalFormError("Onbekend fout opgetreden"));

      return;
    }

    data = await response.json();
  } catch (error) {
    console.error(error);
    errors.value.push(new FatalFormError("Onbekend fout opgetreden"));

    return;
  }

  let errorsTemp = [];
  for (const error of data.errors) {
    if (error.type === "FatalFormError") {
      errorsTemp.push(new FatalFormError(error.message, error));
    }
  }
  errors.value = errorsTemp;

  console.log(data);
};

onMounted(() => {
  form.isInitialized();
});

watch(
  [store.state.order.form, store.state.order.fileinfo],
  debounce(async () => {
    if (!store.state.order.initialized) {
      return;
    }

    let errorsTemp = [];
    if (component.value === null) {
      errorsTemp.push(new FatalFormError("Onbekend", { key: "product", section: "Product" }));
    }
    console.log("Running all checks");
    for (const check of store.state.order.checks) {
      try {
        check.fn(
          new Proxy(check.option, {
            get(target, prop) {
              if (form[prop] && typeof form[prop] === "function") {
                return (...args) => form[prop](target, ...args);
              }
              return Reflect.get(...arguments);
            },
          }),
          form,
        );
      } catch (err) {
        if (err instanceof FatalFormError) {
          err.key = check.option.key;
          err.section = check.section;
          errorsTemp.push(err);
        } else {
          throw err;
        }
      }
    }
    errors.value = errorsTemp;
  }, 10),
);

watch(
  store.state.order.form,
  debounce(async () => {
    if (!store.state.order.initialized || errors.value.length !== 0) {
      calculation.value = null;
      return;
    }

    let formData = new URLSearchParams();
    for (const [key, value] of Object.entries(store.state.order.form)) {
      formData.append(key, value);
    }

    try {
      console.log("AJAX call: calculate_article");
      const response = await fetch("/legacy/order/calculate_article.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formData,
      });

      if (!response.ok) {
        await response.text();
        calculation.value = null;
      } else {
        calculation.value = await response.json();
      }
    } catch (error) {
      console.error(error);
      calculation.value = null;
    }
  }, 200),
);

watch(
  store.state.order.form,
  debounce(async () => {
    if (!store.state.order.initialized || errors.value.length !== 0) {
      calculation.value = null;
      return;
    }

    let formData = new URLSearchParams();
    formData.append("f", "getFileInfo");
    formData.append("filename", form.getValue("filenames", ""));
    formData.append("fullscan", 1);

    let index = 0;
    for (const [key, value] of Object.entries(store.state.order.form)) {
      formData.append("form_data[" + index + "][name]", key);
      formData.append("form_data[" + index + "][value]", value);
      index++;
    }

    try {
      console.log("AJAX call: getfileinfo");
      const response = await fetch("/legacy/inc/ajax.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formData,
      });

      if (!response.ok) {
        await response.text();
        errors.value.push(new FatalFormError("Kan bestand niet analyseren"));

        return;
      }

      const data = await response.json();
      if (form.getValue("default_doc") === 0) {
        form.setFileinfo(data);
      } else if (form.getValue("default_doc") === 1 && form.getFileinfo().length > 0) {
        form.deleteFileinfo();
      }
    } catch (error) {
      console.error(error);
      errors.value.push(new FatalFormError("Kan bestand niet analyseren"));

      return;
    }
  }, 200),
);
</script>

<template>
  <div class="container_12">
    <div class="custom_width content_page">
      <div class="page article article1">
        <div class="grid_9 article_header">
          <h1 v-if="component">
            {{ title }} samenstellen<a
              href="javascript:;"
              title="Wijzig product"
              class="prodselect lightboxbtn button grey no_arrow"
              >wijzig</a
            >
          </h1>
        </div>
        <div class="grid_9">
          <div class="grid_4 suffix_1 article_left">
            <form id="calcForm">
              <table class="cellspacing">
                <component :is="component" v-if="component" :product="product" />
              </table>
            </form>
            <OrderTooltip />
          </div>
          <div id="sticky-container" class="grid_4">
            <div id="follow-container">
              <div class="article_right">
                <div id="article_price_tabel">
                  <OrderPrices v-if="calculation" :calculation="calculation" />
                  <div id="add_to_cart">
                    <p v-if="!calculation" class="default-price-text mt-4">
                      Prijs &amp; leverdatum worden automatisch berekend
                    </p>
                    <button
                      id="winkelmandje"
                      class="legacy-submit noproduct btn btn-secondary"
                      title="Voeg toe aan winkelmandje"
                      @click="sumbit"
                    >
                      <span class="tailwind">
                        <svg-use
                          id="cart-plus"
                          type="solid"
                          class="#fill-white #w-[20px] #h-[20px]"
                        ></svg-use>
                      </span>
                      Bestel
                    </button>
                  </div>
                </div>
              </div>
              <div class="fixed error-messages">
                <div v-if="errors.length > 0" class="grid_12 errors errors2" style="display: block">
                  <component
                    :is="messageComponent(error)"
                    v-for="error in errors"
                    :key="error.id"
                    :instance="error"
                  />
                </div>
                <!-- <div class="grid_12 errors errors1"></div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.page-legacy .popover.right {
  left: unset !important;
}
@media (max-width: 767px) {
  .popover {
    min-width: 300px;
  }
}

.popover-content small {
  font-size: 12px;
}
.popover-content img {
  max-width: 100%;
}
</style>
