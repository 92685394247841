<template>
  <a
    href="javascript:;"
    class="#group #p-[5px] #box-content #border-[1px] #border-solid #border-[#A8A8A8] #rounded-full #cursor-pointer #transition-all hover:#bg-inkdropdark hover:#opacity-50"
    :class="buttonStyling"
    @click="$emit('click')"
  >
    <svg-use :id="`arrow-${direction}`" type="regular" class="#h-[1em] #w-[1em] #fill-[#A8A8A8]" />
  </a>
</template>

<script>
import SvgUse from "../components/SvgUse.vue";

export default {
  components: {
    SvgUse,
  },
  props: {
    direction: {
      type: String,
      default: "left",
    },
    locationStyling: {
      type: String,
      default: "homepage",
    },
  },
  emits: ["click"],
  computed: {
    buttonStyling() {
      return {
        "lg:#hidden": this.locationStyling === "productpage",
      };
    },
  },
};
</script>
