<script setup>
import DeliveryPickupOption from "./options/DeliveryPickupOption.vue";
import DeliveryMailOption from "./options/DeliveryMailOption.vue";
import OptionSection from "./OptionSection.vue";
import ProductionFastOption from "./options/ProductionFastOption.vue";
import ProductionStandardOption from "./options/ProductionStandardOption.vue";
import ProductionBudgetOption from "./options/ProductionBudgetOption.vue";
import ProductionExtraBudgetOption from "./options/ProductionExtraBudgetOption.vue";
import CommentOption from "./options/CommentOption.vue";
import HiddenField from "./fields/HiddenField.vue";
import { useStore } from "vuex";
import FileBorderHasContentOption from "./options/FileBorderHasContentOption.vue";
import FileBleedOption from "./options/FileBleedOption.vue";
import FileOrientationOption from "./options/FileOrientationOption.vue";
import FileHighestOrientationOption from "./options/FileHighestOrientationOption.vue";
import FileContentWithin1mmOption from "./options/FileContentWithin1mmOption.vue";
import FileContentWithin2mmOption from "./options/FileContentWithin2mmOption.vue";
import FileContentAtTopOption from "./options/FileContentAtTopOption.vue";
import FileContentAtRightOption from "./options/FileContentAtRightOption.vue";
import FileContentAtLeftOption from "./options/FileContentAtLeftOption.vue";
import FileContentAtBottomOption from "./options/FileContentAtBottomOption.vue";
import FileHasFrameOption from "./options/FileHasFrameOption.vue";
import FileHasNoCutMarginOption from "./options/FileHasNoCutMarginOption.vue";
import FileSizeOption from "./options/FileSizeOption.vue";
import FilePageSizeOption from "./options/FilePageSizeOption.vue";
import FileHasCoverOption from "./options/FileHasCoverOption.vue";
import FileRatioMatchOption from "./options/FileRatioMatchOption.vue";
import FilePageCountOption from "./options/FilePageCountOption.vue";
import FileThruCutOption from "./options/FileThruCutOption.vue";
import FileClearSpotOption from "./options/FileClearSpotOption.vue";
import FileOverprintOption from "./options/FileOverprintOption.vue";
import FileHasBleedboxOption from "./options/FileHasBleedboxOption.vue";
import FileHasTrimboxOption from "./options/FileHasTrimboxOption.vue";
import FileCleanHeightOption from "./options/FileCleanHeightOption.vue";
import FileCleanWidthOption from "./options/FileCleanWidthOption.vue";
import FileEnableBlankPagesOption from "./options/FileEnableBlankPagesOption.vue";
import FileInvalidFontsOption from "./options/FileInvalidFontsOption.vue";

defineProps({
  product: {
    type: String,
    required: true,
  },
  group: {
    type: String,
    required: true,
  },
});

const store = useStore();
</script>

<template>
  <OptionSection name="Leverwijze">
    <DeliveryPickupOption initial />
    <DeliveryMailOption />
  </OptionSection>
  <OptionSection name="Productie" divider>
    <ProductionFastOption />
    <ProductionStandardOption initial />
    <ProductionBudgetOption />
    <ProductionExtraBudgetOption />
  </OptionSection>
  <CommentOption />

  <!-- done -->
  <HiddenField name="type" type="string" :initial="group" />
  <HiddenField name="product" type="string" :initial="product" />
  <HiddenField name="with_tax" type="bit" :initial="store.getters.config('with_tax', 1)" />

  <FileOrientationOption initial="" />
  <FileHighestOrientationOption initial="" />
  <FileBorderHasContentOption :initial="false" />
  <FileContentWithin1mmOption :initial="false" />
  <FileContentWithin2mmOption :initial="false" />
  <FileContentAtTopOption :initial="false" />
  <FileContentAtRightOption :initial="false" />
  <FileContentAtLeftOption :initial="false" />
  <FileContentAtBottomOption :initial="false" />
  <FileHasFrameOption :initial="false" />
  <FileHasNoCutMarginOption :initial="false" />
  <FileSizeOption initial="" />
  <FilePageSizeOption initial="" />
  <FileBleedOption :initial="0" />
  <FileHasCoverOption :initial="0" />
  <FileRatioMatchOption :initial="0" />
  <FilePageCountOption :initial="0" />
  <FileThruCutOption :initial="false" />
  <FileClearSpotOption :initial="false" />
  <FileOverprintOption :initial="false" />
  <FileHasBleedboxOption :initial="0" />
  <FileHasTrimboxOption :initial="0" />
  <FileCleanHeightOption :initial="0" />
  <FileCleanWidthOption :initial="0" />
  <FileEnableBlankPagesOption :initial="false" />
  <FileInvalidFontsOption :initial="false" />

  <!-- todo -->
  <HiddenField name="thickness" type="float" :initial="0" />
  <HiddenField name="thickness_inside" type="float" :initial="0" />
  <HiddenField name="thickness_cover" type="float" :initial="0" />
  <HiddenField name="weight" type="float" :initial="0" />
  <HiddenField name="id" type="string" />
  <HiddenField name="action" type="string" />
  <HiddenField name="order_id" type="string" />
  <HiddenField name="customer_type" type="string" />
  <HiddenField name="customer_id" type="string" />
  <HiddenField name="reference" type="string" />
  <HiddenField name="from_article" type="string" />
  <HiddenField name="campaign" type="string" />
  <HiddenField name="couponcode" type="string" />
  <HiddenField name="production_free" type="string" />
  <HiddenField name="extra_post" type="string" />
  <HiddenField name="add_file_method" type="string" initial="upload" />
  <HiddenField name="filenames" type="string" />
  <HiddenField name="default_doc" type="bit" :initial="1" />
  <HiddenField name="file_processing" type="string" intial="default" />
  <HiddenField name="file_converted" type="string" />
  <HiddenField name="file_url" type="string" />
  <HiddenField name="file_number_of_cover_files" type="integer" :initial="0" />
  <HiddenField name="file_ad_placement" type="string" />
  <HiddenField name="file_has_error" type="bit" :initial="0" />
  <HiddenField name="file_error_message" type="string" />
  <HiddenField name="file_pitchprint_tracking" type="string" />
  <HiddenField name="file_no_trimbox_size" type="string" />
  <HiddenField name="from_fast_calc" type="bit" :initial="0" />
  <HiddenField name="compare_specs_hash" type="string" />
  <HiddenField name="disable_preview" type="bit" :initial="0" />
  <HiddenField name="screenshot_page" type="string" />
  <HiddenField name="global" type="bit" :initial="0" />
  <HiddenField name="analyzation_status" type="bit" :initial="0" />
  <HiddenField name="status" type="string" />
</template>
