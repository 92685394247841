<template>
  <div>
    <div
      class="#flex #w-full #relative #group"
      :class="
        registerForm
          ? '!#justify-end #items-start #gap-x-4'
          : '#justify-between #items-center #gap-x-8'
      "
      @mouseenter="callPopup"
    >
      <div
        ref="container"
        class="#group #min-w-0 #flex #gap-x-4 #items-center #break-words #overflow-y-visible #overflow-x-clip #overflow-ellipsis"
      >
        <h4
          class="#inline #min-w-0 #text-base #break-words #overflow-y-visible #peer #cursor-default"
          :class="labelStyling"
        >
          {{ title }}
        </h4>
        <div
          v-if="popup?.title && popup?.info"
          ref="fonticon"
          class="fonticon"
          @mouseenter="openPopupNew"
          @mouseleave="closePopupNew"
          @click="stayPopup"
        >
          <svg-use
            id="circle-info"
            type="solid"
            class="#h-[1em] #mt-2 md:#hidden md:group-hover:#block #block #transition-all hover:#fill-brightcyan #cursor-pointer #fill-[#a8a8a8] #w-8"
          />
        </div>
      </div>
      <InputToggle
        :model-value="modelValue"
        @update:model-value="(newValue) => $emit('update:modelValue', newValue)"
      />
    </div>
    <p v-if="error" class="#text-[#d9534f] #text-sm #font-semibold #mt-2">
      {{ error }}
    </p>
  </div>
</template>

<script>
import InputToggle from "../components/InputToggle.vue";
import SvgUse from "../components/SvgUse.vue";

export default {
  components: {
    InputToggle,
    SvgUse,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    popup: {
      type: Object,
      default: null,
    },
    error: {
      type: Boolean,
      required: false,
    },
    modelValue: {
      type: Boolean,
      required: false,
    },
    activePopup: {
      default: null,
      required: false,
    },
    registerForm: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "activePopup"],
  data() {
    return {
      popupHover: false,
      popupOpen: false,
      icon: null,
    };
  },
  computed: {
    labelStyling() {
      return {
        "#text-sm #whitespace-normal": this.registerForm,
        "#overflow-x-clip #overflow-ellipsis #whitespace-nowrap": !this.registerForm,
      };
    },
  },
  watch: {
    activePopup(newValue) {
      if (newValue !== this.$refs.popupModal) {
        this.popupOpen = false;
      }
    },
  },
  methods: {
    openPopupNew() {
      this.emitter.emit("open-info-popup", {
        title: this.popup.title,
        info: this.popup.info,
        placement: "left",
        element: this.$refs.container,
      });
    },
    closePopupNew(element) {
      this.emitter.emit("close-info-popup");
    },
    stayPopup() {
      this.emitter.emit("stay-info-popup", {
        title: this.popup.title,
        info: this.popup.info,
        placement: "left",
        element: this.$refs.container,
      });
    },
  },
};
</script>
