import throttle from "lodash.throttle";

export default {
  data() {
    return {
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      throttle(this.onResize, 100, { leading: false, trailing: true }),
    );
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize: function (event) {
      this.screenWidth = event.target.window.innerWidth;
      this.screenHeight = event.target.window.innerHeight;
    },
  },
  computed: {
    isMobile() {
      if (this.screenWidth < 768) {
        return true;
      }
      return false;
    },
  },
};
