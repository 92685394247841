<template>
  <div
    :class="[s.container, { [s.active]: active, [s.disabled]: disabled, [s.inverted]: inverted }]"
    @mousedown="mousedown"
    @mouseleave="mouseleave"
    @mouseup="mouseup"
    @touchstart="touchstart"
    @touchend="touchend"
    @touchcancel="touchcancel"
  >
    <p v-if="text" :class="s.text">
      {{ text }}
    </p>
    <div v-else-if="icon" class="tailwind">
      <svg-use :id="icon" type="solid" class="#h-[1.25em] #w-[2em]" />
    </div>
  </div>
</template>

<script>
import SvgUse from "../components/SvgUse.vue";

export default {
  components: {
    SvgUse,
  },
  props: {
    text: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: false,
      longPressed: false,
      interval: null,
    };
  },
  computed: {
    hasOnLongPress() {
      return !!(this.$attrs && this.$attrs.onLongPress);
    },
  },
  watch: {
    disabled(val, oldval) {
      if (val) {
        this.active = false;
        this.longPressStop();
      }
    },
  },
  methods: {
    mousedown(event) {
      if (!this.disabled) {
        this.active = true;
        this.longPressStart();
      }
    },
    mouseleave(event) {
      if (!this.disabled) {
        this.active = false;
        this.longPressStop();
      }
    },
    mouseup(event) {
      if (!this.disabled) {
        this.active = false;
        this.press();
        this.longPressStop();
      }
    },
    touchstart(event) {
      if (!this.disabled) {
        this.active = true;
        this.longPressStart();
      }
    },
    touchend(event) {
      event.preventDefault(); // Cancel mouseup if using touchscreen
      if (!this.disabled) {
        this.active = false;
        this.press();
        this.longPressStop();
      }
    },
    touchcancel(event) {
      if (!this.disabled) {
        this.active = false;
        this.longPressStop();
      }
    },
    press() {
      if (this.longPressed && this.hasOnLongPress) {
        return;
      }
      this.$emit("press", true);
    },
    longPressStart() {
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.$emit("longPress", true);
          this.longPressed = true;
        }, 150);
      }
    },
    longPressStop() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
        this.longPressed = false;
      }
    },
  },
};
</script>

<style module="s">
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f7;
  border-radius: 50%;
  aspect-ratio: 1;
  margin: 0px;
  flex: 1;
  border: 0px;
}
.text {
  font-size: 30px;
  font-weight: 700;
  margin: 0px;
}
.active {
  background-color: #dbdbdf;
}
.disabled {
  opacity: 0.5;
}
.inverted {
  background-color: #dbdbdf;
}
.inverted.active {
  background-color: #f6f6f7;
}
</style>
