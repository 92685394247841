<template>
  <FadeIn>
    <div
      v-if="show"
      class="#fixed #z-50 #top-0 #left-0 #w-full #h-full #bg-inkdropdark/90 #flex #justify-center #items-center #p-[15px] sm:#p-[60px]"
    >
      <svg-use
        id="xmark"
        type="solid"
        class="#w-16 #h-16 #fill-white #top-[30px] #right-[30px] #absolute #cursor-pointer hover:#opacity-50 #transition-all"
      ></svg-use>
      <div class="#relative #h-4/5 #max-h-max #max-w-max #w-4/5">
        <div
          v-outside-click="handleOutsideClick"
          class="#relative #bg-white #rounded-[6px] #w-full #max-w-max #max-h-max #h-full #overflow-hidden #p-2"
        >
          <img
            :src="allImages[imgIndex]"
            alt=""
            class="#max-w-full #w-max #max-h-full #h-max #object-contain"
          />
          <div class="#absolute #w-full #h-full #top-0 #left-0 #flex #justify-between">
            <div
              class="#h-full #w-1/4 #flex #justify-start #items-center #pl-8 #transition-all"
              :class="
                firstImg
                  ? '#opacity-0'
                  : '#group #cursor-pointer #opacity-100 md:#opacity-0 hover:#opacity-100 #bg-gradient-to-r #from-[#24212b8c]'
              "
              @click="prevImg"
            >
              <svg-use
                id="angle-left"
                type="solid"
                class="#fill-white #w-16 #h-16 #opacity-100 md:#opacity-0 #transition-all group-hover:#opacity-100"
              ></svg-use>
            </div>
            <div
              class="#h-full #w-1/4 #flex #justify-end #items-center #pr-8 #right-0 #transition-all"
              :class="
                lastImg
                  ? '#opacity-0'
                  : '#group #cursor-pointer #opacity-100 md:#opacity-0 hover:#opacity-100 #bg-gradient-to-l #from-[#24212b8c]'
              "
              @click="nextImg"
            >
              <svg-use
                id="angle-right"
                type="solid"
                class="#fill-white #w-16 #h-16 #opacity-100 md:#opacity-0 #transition-all group-hover:#opacity-100"
              ></svg-use>
            </div>
          </div>
        </div>
        <p class="#absolute #-bottom-12 #left-0 #text-white #text-xs #opacity-80">
          {{ __("Afbeelding") }} {{ imgIndex + 1 }} {{ __("van") }} {{ allImages.length }}
        </p>
      </div>
    </div>
  </FadeIn>
</template>

<script>
import SvgUse from "./SvgUse.vue";
import outsideClick from "../directives/outsideClick.js";
import FadeIn from "./FadeIn.vue";
import lang from "../mixins/lang.js";

export default {
  components: {
    SvgUse,
    FadeIn,
  },
  directives: {
    outsideClick,
  },
  mixins: [lang],
  data() {
    return {
      show: false,
      currentImg: null,
      imgIndex: 0,
      allImages: [],
    };
  },
  computed: {
    lastImg() {
      return this.imgIndex === this.allImages.length - 1;
    },
    firstImg() {
      return this.imgIndex === 0;
    },
  },
  methods: {
    open(img, images) {
      this.show = true;
      this.allImages = images;
      this.currentImg = img;

      this.imgIndex = this.allImages.indexOf(img);
    },
    nextImg() {
      if (this.lastImg) {
        return;
      }
      this.imgIndex++;
    },
    prevImg() {
      if (this.firstImg) {
        return;
      }
      this.imgIndex--;
    },
    handleOutsideClick() {
      if (this.show === false) {
        return;
      }

      this.show = false;
    },
  },
};
</script>
