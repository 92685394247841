<template>
  <div>
    <h1 class="bg-blue-200">narrowcast3</h1>
  </div>
</template>

<script>
export default {};
</script>

<style module="s"></style>
