<template>
  <tr
    class="#h-[90px]"
    :class="{ 'hover:#bg-inkdroplight #cursor-pointer': !autoOpen }"
    @click="toggle($event)"
  >
    <td class="#pl-[15px] md:#pl-[30px] #border-t-[1px] #border-solid #border-[#d5d5d5]">
      <div class="#flex #justify-start #items-center #gap-x-[20px]">
        <div
          class="#w-20 #h-24 #rounded-[4px] #overflow-hidden #border #border-solid #border-[#d5d5d5]"
        >
          <div class="#relative #grid #w-full #h-full #bg-[#d5d5d5] #gap-[1px]" :class="coverGrid">
            <img
              v-for="article in order.articles.slice(0, 4)"
              :key="article.id"
              class="#w-full #h-full #object-cover"
              :class="{ '#opacity-60 #bg-white': !article.img || article.missing }"
              :src="article.img && !article.missing ? article.img : '/img/fallback_logo.svg'"
              alt=""
            />
            <div
              v-if="order.articles.length > 4"
              class="#absolute #w-full #h-full #bg-inkdropdark/60 #flex #justify-center #items-center"
            >
              <p class="#text-xl #text-white #font-bold">
                {{ order.articles.length }}
              </p>
            </div>
          </div>
        </div>
        <div class="#max-w-[calc(100%-78px)]">
          <p class="#block #font-medium">
            <span class="#hidden sm:#inline #select-none"> {{ __("Opdracht") }}&nbsp;</span>
            <span ref="orderId">
              {{ order.id }}
            </span>
          </p>
          <p
            class="#text-sm #block #text-[#707070] #overflow-ellipsis #overflow-hidden #select-none #pr-[15px] #whitespace-nowrap md:#text-base"
          >
            {{ order.reference }}
          </p>
        </div>
      </div>
    </td>
    <td class="#hidden sm:#table-cell #border-t-[1px] #border-solid #border-[#d5d5d5]">
      {{ order.date }}
    </td>
    <td class="#border-t-[1px] #border-solid #border-[#d5d5d5]">
      <div class="#h-4 #w-[100px] min-[450px]:#w-[150px] #bg-[#a8a8a8] #relative #rounded-full">
        <div
          class="#h-full #absolute #left-0 #rounded-full"
          :style="{ background: progressBarColor, width: progressBarWidth }"
        >
          <div
            v-if="order.statusBar.customer_action"
            class="#absolute #bg-white #flex #justify-center #items-center #right-0 #top-1/2 #-translate-y-1/2 #h-[0.7em] #w-[0.7em]"
          >
            <svg-use
              id="circle-exclamation"
              type="solid"
              class="#absolute #h-[1em] #w-[1em] #fill-[#ff0000] #top-1/2 #-translate-y-1/2"
            ></svg-use>
          </div>
        </div>
      </div>
      {{ progressBarStatus }}
      <span v-if="order.statusBar.customer_action" class="#text-[#ff0000]"
        >({{ __("actie vereist") }})</span
      >
    </td>
    <td
      class="#hidden min-[992px]:#table-cell #pr-[15px] md:#pr-[30px] #border-t-[1px] #border-solid #border-[#d5d5d5]"
    >
      {{ order.price }}
    </td>
  </tr>
  <tr v-if="open" class="#bg-inkdroplight #shadow-[inset_0px_10px_20px_-24px] #overflow-x-clip">
    <td colspan="5" class="#pt-[15px] #px-[15px] lg:#px-[30px] #hidden sm:#table-cell">
      <WhiteContainer>
        <OrderStatusbar
          :data="order.statusBar"
          :width="progressBarWidth"
          :color="progressBarColor"
          @open-history="handleOpenHistory"
        ></OrderStatusbar>
      </WhiteContainer>
    </td>
  </tr>
  <tr v-if="open" class="#bg-inkdroplight #shadow-[inset_0px_-10px_20px_-24px]">
    <td colspan="5" class="#pb-[15px] #px-[15px] lg:#px-[30px]">
      <div class="#flex #justify-between #items-start #gap-x-[15px] #mt-[15px]">
        <div class="#w-full #space-y-[15px]">
          <WhiteContainer>
            <OrderHistory ref="order-history" :order="order"></OrderHistory>
          </WhiteContainer>
          <div
            class="#w-full #flex #items-start #justify-between #gap-[15px] #flex-col sm:#flex-row min-[992px]:#hidden"
          >
            <div class="#flex-1 #w-full #space-y-[15px]">
              <WhiteContainer v-if="order.tracktrace.length > 0">
                <TrackTrace :codes="order.tracktrace"></TrackTrace>
              </WhiteContainer>
              <WhiteContainer v-if="order.expected">
                <h3 class="#font-serif #text-lg #mb-[10px]">
                  {{ __("Verwachte leverdatum") }}
                </h3>
                <p>
                  {{ order.expected }}
                </p>
              </WhiteContainer>
              <WhiteContainer>
                <DeliveryProductionDetails :order="order"></DeliveryProductionDetails>
              </WhiteContainer>
            </div>
            <WhiteContainer v-if="showDetails" class="#flex-1 #w-full">
              <OrderInvoices
                :invoices="invoices"
                :loading-invoices="loadingInvoices"
                :loaded="invoicesLoaded"
                @click="getInvoices(order.id)"
              ></OrderInvoices>
            </WhiteContainer>
          </div>
          <WhiteContainer>
            <OrderArticles
              :articles="order.articles"
              @create-message="(obj) => $emit('message', obj)"
            ></OrderArticles>
          </WhiteContainer>
        </div>
        <div class="#min-w-[350px] #max-w-[350px] #space-y-[15px] #hidden min-[992px]:#block">
          <WhiteContainer v-if="order.tracktrace.length > 0">
            <TrackTrace :codes="order.tracktrace"></TrackTrace>
          </WhiteContainer>
          <WhiteContainer v-if="order.expected">
            <h3 class="#font-serif #text-lg #mb-[10px]">
              {{ __("Verwachte leverdatum") }}
            </h3>
            <p>{{ order.expected }}</p>
          </WhiteContainer>
          <WhiteContainer>
            <DeliveryProductionDetails :order="order"></DeliveryProductionDetails>
          </WhiteContainer>
          <WhiteContainer v-if="showDetails">
            <OrderInvoices
              :invoices="invoices"
              :loading-invoices="loadingInvoices"
              :order-id="order.id"
              :loaded="invoicesLoaded"
              @click="getInvoices(order.id)"
            ></OrderInvoices>
          </WhiteContainer>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import WhiteContainer from "../components/WhiteContainer.vue";
import SvgUse from "../components/SvgUse.vue";
import Button from "../components/Button.vue";
import OrderHistory from "./OrderHistory.vue";
import OrderStatusbar from "./OrderStatusbar.vue";
import { lang } from "../mixins";
import OrderArticles from "./OrderArticles.vue";
import { mapGetters } from "vuex";
import OrderInvoices from "./OrderInvoices.vue";
import TrackTrace from "./TrackTrace.vue";
import DeliveryProductionDetails from "./DeliveryProductionDetails.vue";

export default {
  components: {
    WhiteContainer,
    SvgUse,
    Button,
    OrderHistory,
    OrderStatusbar,
    OrderArticles,
    OrderInvoices,
    TrackTrace,
    DeliveryProductionDetails,
  },
  mixins: [lang],
  inject: ["showDetails"],
  props: {
    order: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
    autoOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click", "message"],
  data() {
    return {
      invoices: [],
      loadingInvoices: false,
      invoicesLoaded: false,
    };
  },
  computed: {
    ...mapGetters(["route"]),
    progressBarWidth() {
      let length = this.order.statusBar.steps.length;
      let progress = 0;
      this.order.statusBar.steps.forEach((step) => {
        if (!step.completed) {
          return;
        }
        progress++;
      });

      return ((progress - 1) / (length - 1)) * 100 + "%";
    },
    progressBarColor() {
      if (this.order.statusBar.customer_action) {
        return "#FF0000";
      }

      let color = "#EB980C";
      this.order.statusBar.steps.forEach((step) => {
        if (!step.completed) {
          return;
        }
        color = step.color;
      });

      if (this.order.statusBar.steps.length === 0) {
        color = "";
      }

      return color;
    },
    progressBarStatus() {
      let status = "";

      this.order.statusBar.steps.forEach((step) => {
        if (!step.completed) {
          return;
        }
        status = step.name;
      });

      if (this.order.statusBar.steps.length === 0) {
        status = this.__("Onbekend");
      }

      return status;
    },
    coverGrid() {
      return {
        "#grid-cols-1": this.order.articles.length < 2,
        "#grid-cols-2": this.order.articles.length === 2,
        "#grid-cols-2 #grid-rows-2": this.order.articles.length > 2,
      };
    },
    orderHistory() {
      return [...this.order.orderhistory].reverse();
    },
  },
  methods: {
    toggle(e) {
      if (this.open && e.target === this.$refs.orderId) {
        return;
      }
      this.$emit("click", e);
    },
    handleOpenHistory() {
      this.$refs["order-history"].openHistory();
    },
    async getInvoices(id) {
      this.loadingInvoices = true;
      let obj = {
        orderID: id,
      };
      let response = await fetch(this.route("orders.invoices"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(obj),
      });
      if (response.ok) {
        let object = await response.json();
        this.invoices = object;
      } else {
        await response.text();
      }
      this.loadingInvoices = false;
    },
  },
};
</script>
