<template>
  <div class="tailwind">
    <template v-for="(page, i) in pages" :key="`page_${i}`">
      <div
        :style="{
          visibility: i === index ? 'visible' : 'hidden',
          display: index > i ? 'none' : 'block',
        }"
        v-html="page.content"
      ></div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      pages: [],
      index: 0,
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  async mounted() {
    await this.getNarrowCasts();
    this.startTimeout();
  },
  beforeUnmount() {
    clearTimeout(this.updateIndexTimeout);
  },
  methods: {
    startTimeout() {
      this.updateIndexTimeout = setTimeout(async () => {
        if (this.index + 1 >= this.pages.length) {
          location.reload();
        } else {
          this.index++;
        }

        clearTimeout(this.updateIndexTimeout);
        this.startTimeout();
      }, this.pages[this.index].duration);
    },
    appendScript(script) {
      return new Promise((resolve, reject) => {
        let s = document.createElement("script");

        if (script.src) {
          s.src = script.src;
          s.addEventListener("load", () => {
            resolve();
          });

          this.$nextTick(() => {
            document.body.appendChild(s);
          });
        } else {
          s.innerHTML = script.innerHTML;
          this.$nextTick(() => {
            document.body.appendChild(s);
            resolve();
          });
        }
      });
    },
    async getUrlHtml(url) {
      let response = await fetch(url);
      const html = await response.text();
      const parser = new DOMParser();

      return parser.parseFromString(html, "text/html");
    },
    async getNarrowCasts() {
      let response = await fetch("/cms/api/collections/narrowcasts/entries");
      let { data } = await response.json();

      for (const narrowcast of data) {
        if (narrowcast.template !== "narrowcast/narrowcastoverview") {
          continue;
        }

        for (const page of narrowcast.items) {
          const pageDoc = await this.getUrlHtml(page.url);

          const content = pageDoc.body.querySelector("main > :not(script):not(style)");

          if (content !== null) {
            let duration = 30000;
            for (const item of data) {
              if (item.id === page.id) {
                const { duration: { value = "medium" } = {} } = item;

                if (value === "short") {
                  duration = 15000;
                } else if (value === "medium") {
                  duration = 30000;
                } else if (value === "long") {
                  duration = 45000;
                } else {
                  throw new Error("Unknown page duration");
                }
                break;
              }
            }
            this.pages.push({ content: content.outerHTML, duration: duration });
          }

          let scripts = pageDoc.body.querySelector("main").querySelectorAll("script");

          for (const script of scripts) {
            await this.appendScript(script);
          }

          let styles = pageDoc.body.querySelector("main").querySelectorAll("style");

          for (const style of styles) {
            document.head.appendChild(style);
          }
        }

        break;
      }
    },
  },
};
</script>
