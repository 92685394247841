<script setup>
import { computed, watch } from "vue";
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";
import { FatalFormError } from "../composables/errors";
import PaperCardOption from "./PaperCardOption.vue";
import { useChecks, useCMS, useSetup } from "../composables/option";
import { useOrderForm } from "../../modules/orderModule";
import camelCase from "lodash.camelcase";

defineOptions({
  key: "size",
  key_width: "size_custom_width",
  key_height: "size_custom_height",
  value: "custom",
  label: "Eigen formaat",
  description:
    "Afwijkend formaat kun je hier opgeven (breedte x hoogte in mm). Je vult hier het eindformaat dat moet overblijven na het bijsnijden (dus zonder bleed) of afwerking (vouwen, inbinden enz).",
});

const props = defineProps(useRadioOptionProps());
const { check, option, name, key, key_width, key_height, value, label, description } =
  useSetup(props);
const { disabled } = useCMS(name, check);
const { isRequired, isOneOf } = useChecks();
const form = useOrderForm();

const widthId = computed(() => {
  return camelCase(key_width);
});

const heightId = computed(() => {
  return camelCase(key_height);
});

const update = (name, event) => {
  if (!isNaN(parseInt(event.target.value))) {
    form.updateItem({
      name: name,
      value: parseInt(event.target.value),
    });
  } else {
    form.updateItem({
      name: name,
      value: null,
    });
  }
};

check(isRequired());
check(isOneOf(props.choices));

form.addCheck({
  option,
  section: `${props.section}: breedte`,
  fn: (option, form) => {
    if (!option.isChecked()) {
      return;
    }

    if (form.isEmpty(key_width)) {
      throw new FatalFormError("er moet een waarde ingevuld worden");
    } else if (!form.isInt(key_width)) {
      throw new FatalFormError("moet een getal zijn");
    }

    const width = form.getValue(option.key_width);
    const height = form.getValue(option.key_height, 0);

    let orientation = height > width || height === width ? "portrait" : "landscape";
    const min = orientation === "portrait" ? 60 : 80;
    let max = orientation === "portrait" ? 310 : 440;
    if (form.isChecked(PaperCardOption) && orientation === "landscape") {
      max = 620;
    }

    const val = form.getValue(option.key_width);
    if (val < min) {
      throw new FatalFormError(`is te klein, minimale breedte is ${min}mm`);
    } else if (val > max) {
      throw new FatalFormError(`is te groot, maximale breesdte is ${max}mm`);
    }
  },
});

form.addCheck({
  option,
  section: `${props.section}: hoogte`,
  fn: (option, form) => {
    if (!option.isChecked()) {
      return;
    }

    if (form.isEmpty(key_height)) {
      throw new FatalFormError("er moet een waarde ingevuld worden");
    } else if (!form.isInt(key_height)) {
      throw new FatalFormError("moet een getal zijn");
    }

    const width = form.getValue(option.key_width, 0);
    const height = form.getValue(option.key_height);

    let orientation = height > width || height === width ? "portrait" : "landscape";
    const min = orientation === "portrait" ? 80 : 60;
    let max = orientation === "portrait" ? 440 : 310;
    if (form.isChecked(PaperCardOption) && orientation === "portrait") {
      max = 620;
    }

    const val = form.getValue(option.key_height);
    if (val < min) {
      throw new FatalFormError(`is te klein, minimale hoogte is ${min}mm`);
    } else if (val > max) {
      throw new FatalFormError(`is te groot, maximale hoogte is ${max}mm`);
    }
  },
});

const checked = computed(() => {
  return form.isChecked(option);
});

watch(checked, (newValue) => {
  if (newValue) {
    form.updateItem({ name: key_width, value: null });
    form.updateItem({ name: key_height, value: null });
  } else {
    form.deleteItem(key_width);
    form.deleteItem(key_height);
  }
});
</script>

<template>
  <RadioField
    v-bind="$props"
    :name="key"
    :disabled="disabled"
    :value="value"
    :label="label"
    :description="description"
  />
  <tr v-if="checked" class="tr_size_custom_fields">
    <td width="24%"></td>
    <td width="57%">
      <div class="custom-size-field">
        <input
          :id="widthId"
          :value="form[key_width]"
          type="number"
          autocomplete="off"
          placeholder="b"
          style="max-width: 70px"
          @input="(event) => update(key_width, event)"
        />
        x
        <input
          :id="heightId"
          :value="form[key_height]"
          type="number"
          autocomplete="off"
          placeholder="h"
          style="max-width: 70px"
          @input="(event) => update(key_height, event)"
        />
        mm
      </div>
    </td>
    <td width="19%"></td>
  </tr>
</template>
