<template>
  <div class="#space-y-[30px]">
    <div class="#max-w-[1180px] #w-full #mx-auto #pt-[30px]">
      <div class="#flex #flex-col #justify-between #items-stretch #gap-[15px] lg:#flex-row">
        <div class="#flex-[2] #w-full">
          <a
            :style="{ backgroundImage: `url('${latestNewsItem.big_image}')` }"
            class="#text-white #p-[20px] sm:#p-[30px] #flex #flex-col #justify-end #w-full #h-full #min-h-[350px] #bg-cover #bg-center #relative #group #rounded-[6px] #overflow-hidden hover:#cursor-pointer max-sm:#aspect-auto max-lg:#aspect-[3/2] hover:!#text-white"
            :href="latestNewsItem.url"
          >
            <div
              class="#absolute #w-full #h-full #bg-gradient-to-t #from-[#24212be3] #left-0 #bottom-0 #z-0"
            ></div>
            <p class="#text-white #text-xs #z-[1]">
              {{ latestNewsItem.date }}
            </p>
            <h5 class="media-heading #text-2xl #font-medium #mb-2 #z-[1] group-hover:#underline">
              <SvgDetect
                id="streep"
                class="#fill-brightcyan #absolute #w-full #h-1/3 #left-0 #bottom-0"
              >
                {{ latestNewsItem.title }}
              </SvgDetect>
            </h5>
            <p class="#z-[1] #text-sm #line-clamp-4" v-html="latestNewsItem.introduction"></p>
          </a>
        </div>
        <div class="#flex-1 #w-full #space-y-[15px]">
          <a
            v-for="item in featuredNewsItems"
            :key="item.title"
            class="#bg-white #rounded-[6px] #overflow-hidden #w-full #h-[87px] #flex #justify-start #gap-x-4 #group hover:#cursor-pointer"
            :title="item.title"
            :href="item.url"
          >
            <img :src="item.image" alt="" class="#w-52 #object-cover #aspect-[3/2]" />
            <div class="#py-[15px] #pr-[15px]">
              <p class="#text-xs">{{ item.date }}</p>
              <h3 class="#font-medium #text-lg #line-clamp-1 group-hover:#underline">
                <SvgDetect
                  id="streep"
                  class="#fill-brightcyan #absolute #w-full #h-1/3 #left-0 #bottom-0 #hidden"
                >
                  {{ item.title }}
                </SvgDetect>
              </h3>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div v-for="(articleArray, i) in sortedArticles" :key="articleArray.title + i">
      <div v-if="articleArray.items.length > 0" class="#flex #flex-col #items-center">
        <h3
          class="#font-bold #font-sans #text-xl #mb-[15px] #max-w-[1180px] #w-full"
          style="font-synthesis: weight"
        >
          {{ articleArray.title }}
        </h3>
        <CarouselWidget
          :items="articleArray.items"
          class="#w-full #justify-between"
          :container-classes="'#max-w-[1180px]'"
        >
          <a
            v-for="(item, i) in articleArray.items"
            :key="i"
            :href="item.url"
            class="#group #bg-white #m-0 hover:#cursor-pointer #relative #overflow-hidden #rounded-[6px] #min-w-full #w-1/4 sm:#min-w-[50%] lg:#min-w-[25%] #group"
          >
            <div
              :style="{ backgroundImage: `url('${item.image}')` }"
              class="#relative #flex #flex-col #justify-end #w-full #h-[220px] #p-[20px] #text-white #bg-cover #bg-center"
            >
              <div
                class="#absolute #w-full #h-full #bg-gradient-to-t #from-[#24212be3] #left-0 #bottom-0 #z-0"
              ></div>
              <p class="#text-white #text-xs #z-[1]">
                {{ item.date }}
              </p>
              <h5 class="media-heading #text-xl #font-medium #z-[1] group-hover:#underline">
                <SvgDetect
                  id="streep"
                  class="#fill-brightcyan #absolute #w-full #h-1/3 #left-0 #bottom-0 #hidden"
                >
                  {{ item.title }}
                </SvgDetect>
              </h5>
            </div>
            <div class="#flex #flex-col #p-[20px] #h-[148px]">
              <div
                class="!#font-sans !#font-normal #text-sm #line-clamp-5"
                v-html="item.introduction"
              ></div>
              <div class="#flex-1"></div>
            </div>
          </a>
        </CarouselWidget>
      </div>
    </div>
  </div>
</template>

<script>
import CarouselWidget from "../components/CarouselWidget.vue";
import SvgDetect from "../components/SvgDetect.vue";
import { lang } from "../mixins";

export default {
  components: {
    CarouselWidget,
    SvgDetect,
  },
  mixins: [lang],
  data() {
    return {
      articles: window.articles || [],
      sortedArticles: {
        updates: {
          title: this.__("Nieuws updates"),
          items: [],
        },
        inspiration: {
          title: this.__("Inspiratie"),
          items: [],
        },
        design: {
          title: this.__("Ontwerpen"),
          items: [],
        },
        info: {
          title: this.__("Informeren"),
          items: [],
        },
      },
    };
  },
  computed: {
    latestNewsItem() {
      return this.articles[0];
    },
    featuredNewsItems() {
      return this.articles.slice(1, 6);
    },
  },
  mounted() {
    this.articles.forEach((article) => {
      for (const category of article.category) {
        this.sortedArticles[category].items.push(article);
      }
    });
  },
};
</script>
