<template>
  <div
    class="#group #cursor-pointer #mb-4 #mt-8 #w-max hover:#text-vividmagenta md:#mt-2"
    @click="show = !show"
  >
    <h4 class="#font-regular #inline #border-b #border-dotted #border-current">
      {{ __("Aanlevervoorwaarden") }}
    </h4>
    <svg-use
      id="angle-down"
      type="regular"
      class="#inline #h-[23px] #w-[17px] #fill-inkdropdark #ml-2 group-hover:#fill-vividmagenta"
      :class="{ '#rotate-180': show }"
    />
  </div>
  <div v-if="show" class="#grid #grid-cols-1 lg:#grid-cols-2 #gap-4">
    <div
      v-for="condition in specs"
      :key="condition.title"
      class="#bg-inkdroplight #rounded-[4px] #h-max"
    >
      <div
        class="#group #flex #justify-between #items-start #p-4 #cursor-pointer"
        @click="condition.open = !condition.open"
      >
        <p class="#font-medium group-hover:#text-vividmagenta">
          {{ condition.title }}
        </p>
        <svg-use
          id="angle-down"
          type="regular"
          class="#h-[23px] #w-[17px] #fill-inkdropdark group-hover:#fill-vividmagenta"
          :class="{ '#rotate-180': condition.open }"
        />
      </div>
      <p
        class="#px-[15px] #pb-[15px]"
        :class="{ '#hidden': !condition.open }"
        v-html="condition.description"
      ></p>
    </div>
  </div>
</template>

<script>
import { lang } from "../mixins";
import SvgUse from "../../vue3/components/SvgUse.vue";

export default {
  components: {
    SvgUse,
  },
  mixins: [lang],
  props: {
    specs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
    };
  },
};
</script>
