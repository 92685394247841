<template>
  <div
    class="#flex-1 #flex #justify-between #gap-3 #min-w-[47%] #max-w-[47%]"
    :class="{ '#opacity-100': ap, '#opacity-30': !ap }"
  >
    <p>{{ name }}</p>
    <p>{{ score }}</p>
  </div>
</template>

<script>
export default {
  props: ["name", "score", "id", "currentPlayer"],
  data() {
    return {
      active: false,
    };
  },
  computed: {
    ap() {
      return (this.active = this.id === this.currentPlayer.id);
    },
  },
};
</script>
