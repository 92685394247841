<template>
  <form class="navbar-form" @submit.prevent>
    <div class="form-group">
      <div class="inner-addon right-addon">
        <span v-if="searching" class="tailwind">
          <svg-use
            id="circle-notch"
            type="solid"
            class="#animate-spin #absolute #right-0 #h-[15px] #w-[15px] #fill-inkdropdark #px-[12px] #py-[10px] #box-content"
          />
        </span>
        <div v-else class="tailwind">
          <svg-use
            id="magnifying-glass"
            type="solid"
            class="#absolute #right-0 #h-[1em] #w-[17px] #fill-inkdropdark #px-[12px] #py-[10px] #box-content"
          />
        </div>
        <input
          ref="input"
          v-model="query"
          type="text"
          class="form-control form-control-search"
          placeholder="Zoeken"
          autocomplete="off"
          @keyup.up="navigateUp"
          @keyup.down="navigatedown"
          @keyup.enter="navigateEnter"
        />
      </div>
    </div>
  </form>
  <div
    v-show="results.length > 0"
    ref="results"
    class="autocomplete-suggestions header-search-widget"
    :class="s.results"
  >
    <div
      v-for="(result, index) of results"
      :key="result.id"
      class="autocomplete-suggestion"
      :class="[s.result, { selected: selected === index }]"
      @mouseover="() => select(index)"
      @click="() => navigate(result)"
    >
      <div class="tailwind">
        <svg-use
          v-if="result.id === 'extended'"
          id="magnifying-glass"
          type="solid"
          class="#absolute #right-0 #h-[1em] #w-[17px] #fill-inkdropdark #px-[12px] #py-[10px] #box-content"
        />
      </div>
      <div v-html="highlight(result.title)"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { createPopper } from "@popperjs/core";
import { debounce } from "../helpers";
import SvgUse from "../components/SvgUse.vue";

export default {
  components: {
    SvgUse,
  },
  data() {
    return {
      query: "",
      popper: null,
      searching: false,
      results: [],
      selected: null,
    };
  },
  watch: {
    query(val, oldVal) {
      this.results = [];
      this.selected = null;
      this.searching = true;

      this.search(val);
    },
    results(val, oldVal) {
      if (val.length > 0) {
        this.popper.update();
      }
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.popper = createPopper(this.$refs["input"], this.$refs["results"], {
        placement: "bottom-end",
      });
    });
  },
  methods: {
    search: debounce(async function (query) {
      if (query.length > 0) {
        const response = await fetch(this.route("wiki.search"), {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          method: "post",
          credentials: "same-origin",
          body: JSON.stringify({
            query: query,
          }),
        });

        if (response.ok) {
          this.results = await response.json();
        } else {
          alert("Er heeft een onbekende fout plaatsgevonden.");
        }
      }
      this.searching = false;
    }, 500),
    navigate(result) {
      this.results = [];
      this.searching = true;
      window.location.assign(result.value);
    },
    navigateUp() {
      if (this.results.length < 1 || this.query.length < 1 || this.searching) {
        return;
      }
      if (this.selected === null) {
        this.selected = this.results.length - 1;
      } else if (this.selected > 0) {
        this.selected--;
      } else {
        this.selected = null;
      }
    },
    navigatedown() {
      if (this.results.length < 1 || this.query.length < 1 || this.searching) {
        return;
      }
      if (this.selected === null) {
        this.selected = 0;
      } else if (this.selected < this.results.length - 1) {
        this.selected++;
      } else {
        this.selected = null;
      }
    },
    navigateEnter() {
      if (this.results.length < 1 || this.query.length < 1 || this.searching) {
        return;
      }
      this.navigate(this.results[this.selected ?? 0]);
    },
    select(index) {
      this.selected = index;
    },
    highlight(haystack) {
      for (const word of this.query.split(" ")) {
        haystack = haystack.replace(
          new RegExp(word, "gi"),
          (str) => `<strong class="text-primary">${str}</strong>`,
        );
      }

      return haystack;
    },
  },
  computed: {
    ...mapGetters(["route"]),
  },
};
</script>

<style module="s">
.results {
  display: block;
  min-width: 360px;
}
@media (max-width: 767px) {
  .results {
    width: calc(100% - 20px);
  }
}
.result {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.icon {
  margin-right: 5px;
}
</style>
