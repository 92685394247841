<template>
  <OrderBuilder :product="slug" />
</template>

<script>
import OrderBuilder from "../order/OrderBuilder.vue";

export default {
  components: {
    OrderBuilder,
  },
  data() {
    return {
      slug: null,
    };
  },

  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.slug = urlParams.get("product");
  },
};
</script>
