<script setup>
import { computed } from "vue";
import camelCase from "lodash.camelcase";
import { useOrderForm } from "../../modules/orderModule";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  type: {
    validator(value) {
      return ["boolean", "bit", "string", "integer", "float"].includes(value);
    },
    required: true,
  },
  initial: {
    type: [String, Number, Boolean],
    validator(value, props) {
      if (["integer", "float"].includes(props.type)) {
        return typeof value === "number";
      }
      if (props.type === "string") {
        return typeof value === "string";
      }
      if (props.type === "bit") {
        return [0, 1].includes(value);
      }
      if (props.type === "boolean") {
        return typeof value === "boolean";
      }

      return false;
    },
    default: null,
  },
});

const form = useOrderForm();

form.updateItem({
  name: props.name,
  value: props.initial,
});

const id = computed(() => {
  return camelCase(props.name);
});

const value = computed(() => {
  return form.getValue(props.name);
});
</script>

<template>
  <tbody>
    <input :id="id" :value="value" :name="name" type="hidden" />
  </tbody>
</template>
