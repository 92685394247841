<template>
  <RegisterForm location="article2"></RegisterForm>
</template>

<script>
import RegisterForm from "../components/RegisterForm.vue";

export default {
  components: {
    RegisterForm,
  },
  data() {
    return {
      user: null,
    };
  },
  mounted() {
    if (this.$store.getters.user) {
      this.user = true;
    }
  },
};
</script>
