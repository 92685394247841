<script setup>
import VHtml from "../../components/VHtml.vue";

const props = defineProps({
  instance: {
    type: Object,
    required: true,
  },
});

const { section, message, cta, cta_text } = props.instance;
</script>

<template>
  <div class="error_2">
    <p>
      <b v-if="section">{{ section }}&nbsp;</b>
      <v-html :html="message" />
      <a v-if="cta" href="javascript:;" @click="cta">&nbsp;{{ cta_text }}</a>
    </p>
  </div>
</template>
