<template>
  <MediaItem icon="phone">
    <template #title>
      <a :href="phoneLink">{{ phoneDisplay }}</a>
    </template>
    <p>
      <span v-html="storehours.html"></span>
    </p>
  </MediaItem>
</template>

<script>
import { mapGetters } from "vuex";
import { lang } from "../mixins";
import MediaItem from "../components/MediaItem.vue";
import { cleanPhone } from "../helpers";

export default {
  name: "ContactTelephone",
  components: { MediaItem },
  mixins: [lang],

  computed: {
    ...mapGetters(["storehours", "config"]),
    phoneDisplay() {
      return this.config("store.telephone").replace("+3120", "020 ");
    },
    phoneLink() {
      return "tel:" + cleanPhone(this.config("store.telephone"));
    },
  },
};
</script>

<style module="s"></style>
