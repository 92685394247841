<template>
  <a
    :class="[background, dimensions]"
    class="#select-none #flex #justify-center #items-center #rounded-md #text-xl #box-border #relative"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    color: {
      validator(value) {
        return ["blue", "magenta", "red", "default"].includes(value);
      },
      default: "default",
    },
    size: {
      validator(value) {
        return ["small", "default"].includes(value);
      },
      default: "default",
    },
    type: {
      validator(value) {
        return ["square", "default"].includes(value);
      },
      default: "default",
    },
  },
  computed: {
    background() {
      return {
        "#bg-[#f6f6f7]": this.color === "default",
        "#bg-[#38ccff]": this.color === "blue",
        "#bg-[#ff367d]": this.color === "magenta",
        "#bg-red-500": this.color === "red",
        "#text-white": this.color !== "default",
        "#text-black": this.color === "default",
      };
    },
    dimensions() {
      return {
        "#h-14": this.size === "small",
        "#h-16": this.size === "default",
        "#w-14": this.type === "square" && this.size === "small",
        "#w-16": this.type === "square" && this.size === "default",
        "#grow": this.type === "default",
      };
    },
  },
};
</script>
