<script setup>
import { ref, watch } from "vue";
import { computed } from "vue";
import { useOrderForm } from "../../modules/orderModule";
import { useTextAreaOptionProps } from "../composables/props";
import camelCase from "lodash.camelcase";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: "",
  },
  max: {
    type: Number,
    default: null,
  },
  ...useTextAreaOptionProps(),
});

const form = useOrderForm();

const id = computed(() => {
  return camelCase(props.name);
});

if (props.initial && !import.meta.env.SSR) {
  form.updateItem({
    name: props.name,
    value: props.initial,
  });
}

const update = (event) => {
  const val = event.target.value;
  if (val && props.max && val.length > props.max) {
    form.updateItem({
      name: props.name,
      value: val.slice(0, props.max),
    });
    event.target.value = val.slice(0, props.max);
  } else if (val) {
    form.updateItem({
      name: props.name,
      value: val,
    });
  } else {
    form.deleteItem(props.name);
  }
};

const textarea = ref(null);

const adjustTextareaHeight = () => {
  const el = textarea.value;
  el.style.height = "auto";
  el.style.height = el.scrollHeight + "px";
};

const value = computed(() => {
  return form.getValue(props.name);
});

const remaining = computed(() => {
  if (!props.max) {
    return 0;
  }

  return props.max - (value.value?.length ?? 0);
});

watch(value, () => {
  adjustTextareaHeight();
});
</script>

<template>
  <tr :class="`tr_${name} big-spacing-top big-spacing-bottom border-bottom`">
    <td colspan="2" width="81%">
      <textarea
        :id="id"
        ref="textarea"
        :placeholder="placeholder"
        :value="value"
        :name="name"
        :disabled="disabled"
        rows="3"
        style="
          overflow: hidden;
          overflow-wrap: break-word;
          resize: horizontal;
          height: 100px;
          color: rgb(59, 59, 59);
        "
        @input="update"
      ></textarea>
      <span v-if="max" style="float: right; font-size: 10px; font-size: 1rem">
        {{ remaining }}
      </span>
    </td>
    <td width="19%" class="align-right"></td>
  </tr>
</template>
