<template>
  <label class="#relative #inline-flex #items-center #cursor-pointer #top-[4px]">
    <input
      type="checkbox"
      true-value="true"
      false-value="false"
      class="#sr-only #peer"
      :checked="modelValue"
      :disabled="disabled"
      tabindex="-1"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <div
      class="#w-14 #h-7 #bg-gray-200 peer-focus:#outline-none #rounded-full peer peer-checked:after:#translate-x-[110%] peer-checked:after:#border-white after:#content-[''] after:#absolute after:#top-0.5 after:#left-[2px] after:#bg-white after:#border-gray-300 after:#border after:#rounded-full after:#h-6 after:#w-6 after:#transition-all peer-checked:#bg-brightcyan"
    />
  </label>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
};
</script>
