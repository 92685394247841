<template>
  <div :class="s.wrapper">
    <div :class="s.container">
      <CallBack hide-description />
    </div>
  </div>
</template>

<script>
import CallBack from "../components/CallBack.vue";
export default {
  components: { CallBack },
};
</script>

<style module="s">
.wrapper {
  display: flex;
  justify-content: center;
}
.container {
  width: 422px;
}
</style>
