<template>
  <div
    class="#relative #flex #justify-between #items-center sm:#mt-12 xl:#mt-10 #mb-2 #w-full sm:#w-[97%] #mx-auto"
  >
    <div class="#absolute #w-full #h-[5px] #bg-brightcyan"></div>
    <div
      v-for="(step, index) in ordersteps"
      :key="step.value"
      class="#relative #text-center #flex #flex-col #items-center"
    >
      <a :href="step.link" class="#group #flex #flex-col #items-center">
        <div
          class="#border-[1px] #border-solid #border-brightcyan #w-12 #h-12 #p-2 #rounded-full #relative #text-center #flex #justify-center #items-center"
          :class="stepStyling(index)"
        >
          <p
            class="#absolute #w-max #bottom-full #mb-2 #text-sm #font-medium #hidden sm:#block group-hover:#text-vividmagenta"
          >
            {{ step.label }}
          </p>
          <svg-use :id="step.icon" type="solid" class="#h-6 #max-w-6 #fill-white" />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { lang } from "../mixins";
import SvgUse from "../../vue3/components/SvgUse.vue";

export default {
  components: {
    SvgUse,
  },
  mixins: [lang],
  props: {
    currentPage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ordersteps: [],
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  created() {
    this.ordersteps = [
      {
        value: "article1",
        label: this.__("Opdracht"),
        link: this.route("shoppingcart"),
        icon: "cart-shopping",
      },
      {
        value: "article2",
        label: this.__("Je gegevens"),
        link: this.route("article2"),
        icon: "user",
      },
      {
        value: "article3",
        label: this.__("Leverwijze"),
        link: this.route("article3"),
        icon: "truck",
      },
      {
        value: "article4",
        label: this.__("Betalen"),
        link: this.route("order.payment"),
        icon: "circle-check",
      },
    ];
  },
  methods: {
    stepStyling(i) {
      if (i + 1 <= this.currentPage) {
        return "#bg-brightcyan";
      }
      return "#bg-white";
    },
  },
};
</script>
