<template>
  <div v-if="startGame" class="#mx-4 #text-center #flex #flex-col #items-center">
    <h1 class="#text-white #font-righteous #text-xl #mt-8">Spelers toevoegen</h1>
    <div class="#text-white #text-xl #space-y-3 #w-full #mt-10 #max-w-sm">
      <player
        v-for="player in players"
        :id="player.id"
        :name="player.name"
        :score="player.score"
        :throwns-arrows="player.thrownArrows"
        :score-arrows="player.scoreArrows"
        @delete="deletePlayer"
      ></player>
    </div>
    <div class="#fixed #bottom-8 #w-full #max-w-xs #box-border">
      <playerForm
        :players="players"
        @create="createPlayer"
        @start="(startGame = false), (playingGame = true)"
      ></playerForm>
    </div>
  </div>
  <div v-if="playingGame" class="#text-white #flex #flex-col">
    <div
      id="small-playerlist"
      class="#flex #w-full #max-w-sm #flex-wrap #gap-x-4 #gap-y-1 #mt-4 #mx-auto #px-5 #text-md #text-white #font-righteous #box-border"
    >
      <SmallPlayer
        v-for="player in players"
        :id="player.id"
        :name="player.name"
        :score="player.score"
        :current-player="currentPlayer"
      ></SmallPlayer>
    </div>
    <GameScreen
      :current-player="currentPlayer"
      :current-goal="currentGoal"
      :uploaderror="uploaderror"
      @tryagain="endGame"
    ></GameScreen>
    <div class="#fixed #bottom-0 #w-full #pr-4 #pl-4 #pb-4 #box-border">
      <div
        class="#box-border #flex #justify-center #bottom-0 #mt-4 #max-w-md #ml-auto #mr-auto #gap-3 #font-semibold"
      >
        <Button class="#touch-none" color="magenta" @click="addScore(-1)">M</Button>
        <Button v-if="currentGoal <= 20" class="#touch-none" color="blue" @click="addScore(1)"
          >S</Button
        >
        <Button class="#touch-none" color="blue" @click="addScore(2)">D</Button>
        <Button class="#touch-none" color="blue" @click="addScore(3)">T</Button>
      </div>
      <div class="#flex #gap-3 #max-w-md #ml-auto #mr-auto #mt-5 #font-semibold">
        <Button class="#touch-none" size="small" color="red" type="square" @click="undoBtn"
          >B</Button
        >
        <Button class="#touch-none" size="small" @click="nextPlayer">{{ nextButton }}</Button>
      </div>
    </div>
  </div>
  <Leaderboard
    v-if="gameOver"
    :scoreboard-array="scoreboardArray"
    :winner="winner"
    @again="playAgain"
  ></Leaderboard>
  <div
    v-if="loadingScore"
    class="#absolute #h-screen #w-screen #top-0 #left-0 #bg-inkdropdark #box-border #flex #flex-col #justify-center #items-center"
  >
    <h2 class="#font-bold #text-white #font-righteous #text-[30px] #text-center">
      Score berekenen...
    </h2>
    <p class="#text-white #font-righteous">Dus even geduld Dody</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import playerForm from "../components/playerForm.vue";
import player from "../components/player.vue";
import Button from "../components/PdcButton.vue";
import GameScreen from "../components/GameScreen.vue";
import SmallPlayer from "../components/SmallPlayer.vue";
import Leaderboard from "../components/Leaderboard.vue";

export default {
  components: {
    playerForm,
    player,
    Button,
    GameScreen,
    SmallPlayer,
    Leaderboard,
  },
  data() {
    return {
      players: [],
      scoreboardArray: [],
      playerNumb: 0,
      currentGoal: 1,
      currentPlayerIndex: 0,
      winner: "",
      startGame: true,
      playingGame: false,
      gameOver: false,
      nextButton: "Volgende",
      uploaderror: false,
      loadingScore: false,
    };
  },
  mounted() {
    // this.createPlayer('Jelle');
    // this.createPlayer('Mike');
    // this.createPlayer('Rankmaster B');
    // this.createPlayer('El matador');
    // this.createPlayer('Tijdelijke gyros');
    // this.createPlayer('Maradody');
    // this.currentGoal = 20;
    // this.startGame = false;
    // this.playingGame = true;
  },
  computed: {
    ...mapGetters(["route"]),
    currentPlayer() {
      return this.players[this.currentPlayerIndex];
    },
  },
  watch: {
    currentPlayerIndex(newValue, oldValue) {
      if (this.currentGoal === "Bull" && newValue === this.players.length - 1) {
        this.nextButton = "Eindig spel";
      } else {
        this.nextButton = "Volgende";
      }
    },
  },
  methods: {
    createPlayer(tempName, tempNumber) {
      this.players.push({
        name: tempName,
        id: tempNumber,
        score: 0,
        thrownArrows: [],
        scoreArrows: [],
        misses: 0,
        singles: 0,
        doubles: 0,
        triples: 0,
        winner: false,
      });
      this.playerNumb++;
    },
    deletePlayer(id) {
      this.players = this.players.filter((player) => id !== player.id);
    },
    addScore(points) {
      if (this.currentPlayer.thrownArrows.length === 3) {
        return;
      }

      this.currentPlayer.score = this.currentPlayer.score + points;
      this.currentPlayer.thrownArrows.push(points);
      this.currentPlayer.scoreArrows.push(points);
    },
    nextPlayer() {
      while (this.currentPlayer.thrownArrows.length < 3) {
        this.currentPlayer.thrownArrows.push(0);
        this.currentPlayer.scoreArrows.push(0);
      }
      this.currentPlayer.thrownArrows = [];

      if (this.currentPlayerIndex === this.players.length - 1) {
        if (this.currentGoal === "Bull") {
          this.endGame();
          return;
        }
        this.currentPlayerIndex = 0;
        this.currentGoal++;

        if (this.currentGoal === 21) {
          this.currentGoal = "Bull";
          return;
        }

        return;
      }

      this.currentPlayerIndex++;
    },

    undoBtn() {
      if (this.currentPlayerIndex === 0 && this.currentPlayer.scoreArrows.length === 0) {
        return;
      }

      if (this.currentPlayer.thrownArrows.length > 0) {
        const undoScore = this.currentPlayer.scoreArrows.pop();
        this.currentPlayer.score -= undoScore;
        this.currentPlayer.thrownArrows.pop();
      } else if (this.currentPlayer.thrownArrows.length === 0) {
        if (this.currentPlayerIndex === 0) {
          this.currentPlayerIndex = this.players.length - 1;
          if (this.currentGoal === "Bull") {
            this.currentGoal = 20;
          } else {
            this.currentGoal--;
          }
        } else {
          this.currentPlayerIndex--;
        }
        this.currentPlayer.thrownArrows = this.currentPlayer.scoreArrows.slice(-3);
      }
    },
    // Function that decides who won
    async endGame() {
      this.loadingScore = true;
      for (const player of this.players) {
        this.updateCounters(player);
      }
      this.scoreboardArray = this.players.slice();
      this.scoreboardArray.sort((s1, s2) => {
        // Compare the scores
        let compareScore = s2.score - s1.score;

        if (compareScore === 0) {
          // Compare triples if the score is a tie
          compareScore = s2.triples - s1.triples;

          if (compareScore === 0) {
            // Compare the doubles if the triples are a tie
            compareScore = s2.doubles - s1.doubles;

            if (compareScore === 0) {
              // Compare the misses if the doubles are a tie
              compareScore = s1.misses - s2.misses;
            }
          }
        }
        return compareScore;
      });
      this.scoreboardArray[0].winner = true;

      let response = await fetch(this.route("pdcscore"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          score: this.scoreboardArray,
        }),
      });
      response = await response.json();

      this.winner = this.scoreboardArray.shift();
      this.loadingScore = false;
      this.gameOver = true;
      this.playingGame = false;
    },
    updateCounters(player) {
      player.misses = player.scoreArrows.filter((t) => t === -1).length;
      player.singles = player.scoreArrows.filter((t) => t === 1).length;
      player.doubles = player.scoreArrows.filter((t) => t === 2).length;
      player.triples = player.scoreArrows.filter((t) => t === 3).length;
    },
    playAgain() {
      for (const player of this.players) {
        player.score = 0;
        player.thrownArrows = [];
        player.scoreArrows = [];
        player.misses = 0;
        player.singles = 0;
        player.doubles = 0;
        player.triples = 0;
        player.winner = false;
      }
      this.currentGoal = 1;
      this.currentPlayerIndex = 0;
      this.winner = "";
      this.gameOver = false;
      this.startGame = true;
    },
  },
};
</script>
