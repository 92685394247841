<script setup>
import { FatalFormError } from "../composables/errors";
import { useCMS, useSetup } from "../composables/option";
import { useCheckboxOptionProps } from "../composables/props";
import CheckboxField from "../fields/CheckboxField.vue";

defineOptions({
  key: "finishing_extra",
  value: "round_corner",
  label: "Ronde hoeken",
});
const props = defineProps(useCheckboxOptionProps());
const { check, name, key, label, value } = useSetup(props);
const { disabled, tooltipTitle, tooltipContent } = useCMS(name, check);

check((option) => {
  if (option.isEmpty()) {
    return;
  }

  let choices = ["round_corner"];
  if (!choices.includes(option.getValue())) {
    throw new FatalFormError("moet 1 van de volgende waardes zijn: " + choices.join(", "));
  }
});
</script>

<template>
  <CheckboxField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :name="key"
    :label="label"
    :value="value"
  />
</template>
