<template>
  <FadeIn>
    <div>
      <div v-if="!jobID" :class="s.container">
        <p :class="s.helptext">
          {{ __("Vul uw order nummer in, u kunt deze vinden in uw email en of sms berichten.") }}
        </p>
        <div class="form-group" :class="s.inputContainer">
          <input
            v-model="number"
            type="text"
            class="form-control form-control-search"
            :class="s.input"
            :placeholder="__('Opdracht nummer')"
            autocomplete="off"
            disabled
          />
          <FlashMessage ref="message" :style="{ marginBottom: 0 }" />
        </div>
        <NumberPad
          :class="s.pad"
          :value="number"
          :disabled="submitting"
          @change="(val) => (number = val)"
          @submit="submit"
        />
      </div>
      <div v-else :class="s.container">
        <h4>{{ __("Pak uw bestelling uit het vak:") }}</h4>
        <p :class="s.position">
          {{ position }}
        </p>
        <p>
          {{ __("Op naam van:") }} <strong>{{ name }}</strong>
        </p>

        <div class="tailwind">
          <p>{{ __("Heeft u uw bestelling kunnen vinden?") }}</p>
          <div :class="s.btnRow">
            <SubmitButton
              :text="__('Ja')"
              btn-class="primary"
              size="full-sm"
              :disabled="submitting"
              @click="confirm"
            />
            <SubmitButton
              :text="__('Nee')"
              btn-class="secondary"
              size="full-sm"
              :disabled="submitting"
              @click="cancel"
            />
          </div>
          <SubmitButton :text="__('Terug')" btn-class="grey" size="full-sm" @click="back" />
        </div>
      </div>
    </div>
  </FadeIn>
</template>

<script>
import { mapGetters } from "vuex";
import FadeIn from "../components/FadeIn.vue";
import NumberPad from "../components/NumberPad.vue";
import SubmitButton from "../components/SubmitButton.vue";
import FlashMessage from "../components/FlashMessage.vue";
import { lang, form } from "../mixins";
export default {
  name: "SelfService",
  components: { FadeIn, NumberPad, SubmitButton, FlashMessage },
  mixins: [lang, form],
  data() {
    return {
      number: "",
      name: null,
      order: null,
      position: null,
      jobID: null,
    };
  },
  mounted() {
    // this.number = "642163";
    // this.submit();
  },
  methods: {
    async submit() {
      this.submitting = true;

      const response = await fetch(this.route("selfservice"), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "post",
        credentials: "same-origin",
        body: JSON.stringify({
          number: this.number,
        }),
      });

      if (response.ok) {
        const body = await response.json();
        this.number = "";
        this.name = body.name;
        this.order = body.order;
        this.position = body.position;
        this.jobID = body.job_id;
      } else if (response.status === 422) {
        const body = await response.json();
        this.number = "";
        this.$refs.message.flash(body.errors.number[0], "error", 4000);
      } else if (response.status === 401) {
        this.$refs.message.flash(this.__("Deze functie werkt alleen op locatie"), "error", 4000);
      } else {
        this.$refs.message.flash(
          this.__("Er heeft een onbekende fout plaatsgevonden."),
          "error",
          4000,
        );
      }

      this.submitting = false;
    },
    async confirm() {
      this.submitting = true;

      const response = await fetch(this.route("selfservice.confirm"), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "post",
        credentials: "same-origin",
        body: JSON.stringify({
          job_id: this.jobID,
        }),
      });

      this.submitting = false;
      this.back();

      this.$nextTick(() => {
        if (response.ok) {
          this.$refs.message.flash(this.__("Tot de volgende keer"), "success", 4000);
        } else {
          this.$refs.message.flash(
            this.__("Er heeft een onbekende fout plaatsgevonden."),
            "error",
            4000,
          );
        }
      });
    },
    async cancel() {
      this.submitting = true;

      const response = await fetch(this.route("selfservice.cancel"), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "post",
        credentials: "same-origin",
        body: JSON.stringify({
          job_id: this.jobID,
        }),
      });

      this.submitting = false;
      this.back();

      this.$nextTick(() => {
        if (response.ok) {
          this.$refs.message.flash(
            this.__("U kunt indien nodig hulp vragen aan de balie"),
            "success",
            4000,
          );
        } else {
          this.$refs.message.flash(
            this.__("Er heeft een onbekende fout plaatsgevonden."),
            "error",
            4000,
          );
        }
      });
    },
    back() {
      this.name = null;
      this.order = null;
      this.position = null;
      this.jobID = null;
    },
  },
  computed: {
    ...mapGetters(["route"]),
  },
};
</script>

<style module="s">
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.inputContainer {
  margin-top: 20px;
  width: 100%;
  max-width: 275px;
  margin-bottom: 25px;
}

.input {
  background-color: #fff !important;
  padding-top: 12px;
  padding-bottom: 12px;
  height: unset;
}

.pad {
  max-width: 275px;
}

.position {
  font-size: 5em;
  color: #38ccff;
}

.btnRow {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 20px;
}

.helptext {
  text-align: center;
  display: block;
  margin: 0;
}
</style>
