<template>
  <div v-if="loading" :class="s.loading">
    <LoadingIndicator />
  </div>
  <template v-else>
    <div v-show="showForm" :class="s.content">
      <FlashMessage ref="finalMessage" :style="finalMessageStyle" @done="() => $emit('close')" />
      <form v-if="!done" @submit.prevent="submit">
        <div :class="s.formHeader">
          <h5 :class="s.formHeaderText">
            {{ header }}
          </h5>
          <button type="button" class="btn btn-sm" :class="s.formHeaderBtn">
            {{ headerTime }}
          </button>
          <a :class="s.backButton" @click="back">
            {{ __("wijzig") }}
          </a>
        </div>
        <div class="form-group" :class="{ 'has-error': hasError('phone') }">
          <input
            v-model="form.phone"
            type="tel"
            class="form-control"
            :disabled="submitting"
            :placeholder="__('Telefoonnummer')"
            @focus="() => clearErrors('phone')"
          />
          <p v-if="hasError('phone')" class="help-block">
            {{ firstError("phone") }}
          </p>
        </div>
        <div class="form-group" :class="{ 'has-error': hasError('subject') }">
          <select
            v-model="form.subject"
            class="form-control"
            :class="{ [s.placeholder]: !form.subject }"
            :disabled="submitting"
            @focus="() => clearErrors('subject')"
          >
            <option value="" hidden>
              {{ __("Wat is het onderwerp?") }}
            </option>
            <option value="levertijd">
              {{ __("Levertijd") }}
            </option>
            <option value="prijs">
              {{ __("Prijs") }}
            </option>
            <option value="status">
              {{ __("Status") }}
            </option>
            <option value="bestandsaanlevering">
              {{ __("Bestandsaanlevering") }}
            </option>
            <option value="klacht">
              {{ __("Klacht") }}
            </option>
            <option value="overige">
              {{ __("Overige") }}
            </option>
          </select>
          <div class="tailwind">
            <p class="#mt-2 #text-sm #text-sky-400">
              {{ __("Dit gesprek kan worden opgenomen voor trainings- of kwaliteitsdoeleinden") }}
            </p>
          </div>
          <p v-if="hasError('subject')" class="help-block">
            {{ firstError("subject") }}
          </p>
        </div>
        <div v-if="existing" class="error_1">
          <span v-html="existingError" />
          <a :class="s.errorOption" @click="reschedule">{{ __("Ja") }}</a> /
          <a :class="s.errorOption" @click="ignore">{{ __("Nee") }}</a>
        </div>
        <div class="tailwind">
          <SubmitButton :text="__('Bevestigen')" :disabled="submitting" />
        </div>
      </form>
    </div>
    <div v-show="!showForm" class="product-tabs" :class="s.content">
      <p v-if="!hideDescription">
        {{
          __(
            "Wij werken met een bel-mij-terug tijdvak systeem waarin je teruggebeld wordt, hiermee voorkomen wij een (lange) wachtrij.",
          )
        }}
      </p>
      <ul class="nav nav-tabs" :class="s.tabHeader">
        <template v-for="day in days" :key="day.date">
          <li role="presentation" :class="{ active: form.date === day.date }">
            <a :class="s.item" @click="() => (form.date = day.date)">{{ day.name }}</a>
          </li>
        </template>
      </ul>
      <div class="tab-content" :class="s.tabContainer">
        <div v-if="error" class="error_1">
          {{ error }}
        </div>
        <div v-if="slots.length > 0" :class="s.form">
          <template v-for="slot in slots" :key="slot.from">
            <button
              type="button"
              class="btn btn-sm"
              :class="[s.button, { [s.disabled]: slot.free <= 0 }]"
              @click.prevent="() => select(slot)"
            >
              {{ slot.from }} - {{ slot.to }}
            </button>
          </template>
        </div>
        <div v-else :class="s.form">
          {{ __("Sorry, onze klantenservice is gesloten") }}
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { mapGetters } from "vuex";
import { lang, form } from "../mixins";
import { capitalize } from "../helpers";
import FlashMessage from "../components/FlashMessage.vue";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import SubmitButton from "../components/SubmitButton.vue";

export default {
  components: { FlashMessage, LoadingIndicator, SubmitButton },
  mixins: [lang, form],
  props: {
    hideDescription: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close", "select"],
  data() {
    return {
      loading: true,
      submitting: false,
      done: false,
      phonenumber: "",
      days: [],
      existing: null,
      error: null,
      form: {
        customer_id: null,
        phone: "",
        subject: "",
        date: null,
        from: null,
        to: null,
      },
    };
  },

  computed: {
    ...mapGetters(["storehours", "route", "user"]),
    slots() {
      if (!this.form.date) {
        return [];
      }

      const day = this.days.find((item) => item.date === this.form.date);

      return day.slots;
    },
    header() {
      if (!this.form.date) {
        return null;
      }

      const day = this.days.find((item) => item.date === this.form.date);

      return capitalize(day.name);
    },
    headerTime() {
      if (!this.form.date) {
        return null;
      }

      return `${this.form.from} - ${this.form.to}`;
    },
    existingError() {
      if (!this.existing || !this.form.date) {
        return null;
      }

      const day = this.days.find((item) => item.date === this.form.date);

      return this.sprintf(
        this.__(
          "Je wordt %s al teruggebeld tussen %s - %s.<br><br>Wil je de afspraak verzetten naar %s tussen %s - %s? ",
        ),
        `<b>${this.existing.name}</b>`,
        `<b>${this.existing.from}</b>`,
        `<b>${this.existing.to}</b>`,
        `<b>${day.name}</b>`,
        `<b>${this.form.from}</b>`,
        `<b>${this.form.to}</b>`,
      );
    },
    showForm() {
      return this.form.from && this.form.to;
    },
    finalMessageStyle() {
      return {
        margin: 0,
        textAlign: "center",
      };
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    select(slot) {
      this.$emit("select");
      if (slot.free <= 0) {
        this.error = this.__("Dit tijdvak is helaas niet (meer) mogelijk");
      } else {
        this.form.from = slot.from;
        this.form.to = slot.to;
      }
    },
    back() {
      this.error = null;
      this.form.from = null;
      this.form.to = null;
    },
    ignore() {
      this.existing = null;
    },
    async load() {
      let response = await fetch(this.route("callback.index"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      this.days = await response.json();
      this.form.date = this.days[0].date;

      const { id = null, telephone = "" } = this.user ?? {};
      this.form.customer_id = id;
      this.form.phone = telephone;

      this.loading = false;

      // this.form.from = this.days[0].slots[0].from; // debug
      // this.form.to = this.days[0].slots[0].to; // debug
    },
    async reschedule() {
      await fetch(this.route("callback.destroy", { id: this.existing.id }), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "post",
        credentials: "same-origin",
        body: JSON.stringify({
          _method: "DELETE",
        }),
      });
      this.existing = null;
      await this.submit();
    },
    async submit() {
      this.submitting = true;
      this.formErrors = {};

      const response = await fetch(this.route("callback.store"), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        method: "post",
        credentials: "same-origin",
        body: JSON.stringify(this.form),
      });

      if (!response.ok && response.status === 422) {
        const body = await response.json();
        this.formErrors = body.errors;
        this.submitting = false;

        return false;
      }

      if (!response.ok && response.status === 409) {
        const body = await response.json();
        this.existing = body.data;
        this.submitting = false;

        return false;
      }

      if (!response.ok) {
        await response.text();
        this.$refs.finalMessage.flash(
          this.__("Er heeft een onbekende fout plaatsgevonden."),
          "error",
          10000,
        );
        this.submitting = false;
        this.done = true;

        return false;
      }

      const { success } = await response.json();
      if (!success) {
        this.$refs.finalMessage.flash(
          this.__("Er heeft een onbekende fout plaatsgevonden."),
          "error",
          10000,
        );

        this.submitting = false;
        this.done = true;

        return false;
      }

      this.$refs.finalMessage.flash(
        this.__("We zullen binnen het gekozen tijdvak contact opnemen."),
        "success",
        10000,
      );
      this.submitting = false;
      this.done = true;

      return true;
    },
  },
};
</script>

<style module="s">
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.header {
  display: flex;
  flex: 1;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tabHeader {
  margin-top: 0px !important;
}

.tabContainer {
  padding: 10px;
}

.item {
  cursor: pointer;
}

.form {
  display: flex;
  flex-wrap: wrap;
  margin: -12px 0 0 -12px;
  width: calc(100% + 12px);
}

.errorOption {
  border-bottom: 1px dotted;
  text-decoration: none;
  cursor: pointer;
}

.errorOption:hover {
  color: #ff367d !important;
}

.disabled,
.disabled:hover,
.disabled:focus {
  text-decoration: line-through;
}

.placeholder {
  color: #999;
}

.placeholder option {
  color: #505050;
}

.formHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.formHeaderText {
  margin: 0;
}

.formHeaderBtn {
  margin-left: 10px;
  margin-right: 10px;
  cursor: unset;
}

.formHeaderBtn:focus {
  outline: unset;
}

.formHeaderBtn:active {
  box-shadow: unset;
}

.backButton {
  border: none;
  color: #777 !important;
  font-size: 13px;
  padding-top: 2px;
  text-decoration: none;
  cursor: pointer;
}

.backButton:hover {
  border: none !important;
  color: #ff367d !important;
}

.button {
  margin: 12px 0 0 12px;
  flex-basis: calc(50% - 12px);
}

@media (min-width: 768px) {
  .button {
    flex-basis: calc(33.333333% - 12px);
  }
}
</style>
