<template>
  <form action="POST" class="#w-full" @submit.prevent="submitForm(event)">
    <h1 v-if="title" class="#text-xl #text-center #font-medium #mb-[15px] min-[450px]:#text-2xl">
      {{ title }}
    </h1>
    <p v-if="description" class="#mb-[30px]">
      {{ description }}
    </p>
    <p
      v-if="succesMessage"
      class="#mt-[5px] #text-center #text-base #font-semibold #text-[#2CD11A]"
    >
      {{ succesMessage }}
    </p>
    <template v-else>
      <slot></slot>
      <SubmitButton :text="btnText" :btn-class="btnColor" :size="btnSize" :disabled="submitting" />
    </template>
  </form>
</template>

<script>
import SubmitButton from "../components/SubmitButton.vue";

export default {
  components: {
    SubmitButton,
  },
  props: {
    succesMessage: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      default: "",
    },
    btnColor: {
      type: String,
      default: "primary",
    },
    btnSize: {
      type: String,
      default: "sm",
    },
  },
  emits: ["submit"],
  methods: {
    submitForm() {
      this.$emit("submit");
    },
  },
};
</script>
