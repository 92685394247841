<template>
  <transition
    appear
    :enter-from-class="s.fadeEnterFrom"
    :leave-to-class="s.fadeLeaveTo"
    :enter-active-class="s.fadeEnterActive"
    :leave-active-class="s.fadeLeaveActive"
  >
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: "FadeIn",
};
</script>

<style module="s">
.fadeEnterActive,
.fadeLeaveActive {
  transition: opacity 0.4s ease;
}

.fadeEnterFrom,
.fadeLeaveTo {
  opacity: 0;
}
</style>
