import { debounce } from "../helpers";

export default {
  data() {
    return {
      keycache: "",
    };
  },
  mounted() {
    document.addEventListener("keydown", this.onKeydown);
  },
  unmounted() {
    document.removeEventListener("keydown", this.onKeydown);
  },
  methods: {
    isLetter(str) {
      return str && str.length === 1 && /^[a-z0-9~]+$/i.test(str);
    },
    clearDebounced: debounce(function () {
      this.keycache = "";
    }, 100),
    onKeydown(e) {
      if (this.isLetter(e.key)) {
        this.keycache += e.key;
      } else if (e.key === "Enter") {
        if (/^i[A-Z0-9]+$/.test(this.keycache)) {
          this.onRfidScan(this.keycache.substring(1));
        } else if (/^~i[A-Z0-9]+$/.test(this.keycache)) {
          this.onRfidScan(this.keycache.substring(2));
        }
      }

      this.clearDebounced();
    },
    onRfidScan(rfid) {
      console.log(rfid);
    },
  },
};
