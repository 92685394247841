<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import SvgUse from "../components/SvgUse.vue";

const props = defineProps({
  calculation: {
    type: Object,
    required: true,
  },
});
const store = useStore();

// console.log(props.calculation);

const formatMoney = (val, digits = 2) => {
  if (Number.isNaN(Number(val))) {
    return "";
  }

  return new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: digits,
  })
    .format(val)
    .replace("€", "")
    .trim();
};

const withTax = store.getters.config("with_tax", 1);

const totalPrice = computed(() => {
  const { price_total_incl, price_total } = props.calculation;

  if (withTax) {
    return formatMoney(price_total_incl);
  }
  return formatMoney(price_total);
});

const priceOfOneExample = computed(() => {
  const { price_1_exemp, taxrate } = props.calculation;

  if (withTax) {
    let tax = price_1_exemp * (parseFloat(taxrate) / 100);

    return formatMoney(price_1_exemp + tax, 3);
  }

  return formatMoney(price_1_exemp, 3);
});

const deliveryDate = computed(() => {
  const {
    delivery_data: { day_text_nl, time, delivery_method, day, month_nl, days },
  } = props.calculation;

  let date = day_text_nl;

  if (delivery_method === "pickup") {
    date += " " + time;
  }

  if (days > 7) {
    date += "<br>" + day + " " + month_nl;
  }

  return date;
});

const orderingTime = computed(() => {
  const {
    delivery_data: { production_method, delivery_method },
  } = props.calculation;

  var curr_date = new Date();

  if (production_method === "fast") {
    if (delivery_method == "pickup" && curr_date.getHours() > 13 && curr_date.getHours() < 22) {
      return "bestel voor 22:00u";
    } else {
      return "bestel voor 13:00u";
    }
  }

  return "bestel voor 18:00u";
});
</script>

<template>
  <div id="tablePriceDelivery">
    <div id="calculated_prices">
      <table width="100%" cellspacing="0" cellpadding="0">
        <tbody>
          <tr class="spacing-top">
            <td width="5%" class="blue bold" style="font-size: 24px">€</td>
            <td width="30%">
              <span class="bold blue" style="font-size: 24px">{{ totalPrice }}</span>
              <span style="font-size: 12px; padding-left: 5px">
                {{ withTax ? "incl" : "excl" }}. btw
              </span>
            </td>
            <td width="35%" style="padding-top: 14px">
              <span class="small">{{ priceOfOneExample }} p/stuk</span>
            </td>
            <td width="30%" style="text-align: right; padding-top: 14px">
              <a href="javascript:;" class="hide_show small border-bottom"> Toon details </a>
            </td>
          </tr>
          <tr class="xl-spacing-top">
            <td width="5%" class="blue bold" style="font-size: 16px">
              <span class="tailwind">
                <svg-use
                  id="calendar"
                  type="regular"
                  class="#fill-brightcyan #w-[17px] #h-[17px]"
                ></svg-use>
              </span>
            </td>
            <td width="30%" class="blue bold" style="font-size: 16px">
              <span id="delivery_date_span" v-html="deliveryDate"></span>
            </td>
            <td width="65%" colspan="2" class="small" style="padding-bottom: 0px">
              {{ orderingTime }}
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div id="details_table" style="display: none"></div>
    </div>
  </div>
</template>
