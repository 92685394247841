<template>
  <div v-if="isAdmin" class="admin-dialog" v-html="text" />
</template>

<script>
import { mapGetters } from "vuex";
import { lang } from "../mixins";
export default {
  mixins: [lang],
  data() {
    return {
      interval: null,
    };
  },
  computed: {
    ...mapGetters(["route", "user", "isAdmin"]),
    name() {
      const { email } = this.user || {};
      return email || this.__("Onbekend");
    },
    text() {
      return this.sprintf(
        this.__("Je bent ingelogd als admin voor %s. - %sUitloggen%s"),
        `<strong>${this.name}</strong>`,
        `<strong><a href="${this.route("legacy.admin_logout")}">`,
        "</a></strong>",
      );
    },
  },
  mounted() {
    if (this.isAdmin) {
      this.interval = setInterval(async () => {
        const response = await fetch(this.route("session.admin-check"), {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        if (response.ok) {
          const { is_admin: isStillAdmin, user_id: userId } = await response.json();
          const { id } = this.user || {};
          if (!isStillAdmin) {
            this.replaceBody();
            window.close();
          } else if (id && userId && id !== userId) {
            this.replaceBody();
            window.close();
          }
        } else {
          await response.text();
        }
      }, 5000);
    } else {
      // console.log('Not an admin')
    }
  },
  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    replaceBody() {
      document.body.innerHTML =
        '<h1 style="text-align: center;">Browser disabled</h1><p style="text-align: center;">Another article is being edited</p>';
    },
  },
};
</script>

<style module="s"></style>
