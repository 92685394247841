<template>
  <transition-group
    :enter-active-class="
      position === 'top'
        ? s.fadeEnterActiveTop
        : position === 'bottom'
          ? s.fadeEnterActiveBottom
          : ''
    "
  >
    <slot></slot>
  </transition-group>
</template>

<script>
export default {
  name: "SlideIn",
  props: {
    position: {
      type: String,
      default: "top",
    },
  },
};
</script>

<style module="s">
.fadeEnterActiveTop {
  animation: showInputTop 0.3s cubic-bezier(0.66, 0.01, 0.4, 1.01) forwards;
}
.fadeEnterActiveBottom {
  animation: showInputBottom 0.3s cubic-bezier(0.66, 0.01, 0.4, 1.01) forwards;
}
@keyframes showInputTop {
  0% {
    margin-top: -20px;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    margin-top: 0;
  }
}
@keyframes showInputBottom {
  0% {
    margin-bottom: -20px;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    margin-bottom: 0;
  }
}
</style>
