<template>
  <div
    v-if="pages > 1"
    class="#flex #justify-center #items-center #gap-x-4"
    :class="{ '*:#opacity-50 *:#pointer-events-none': disabled }"
  >
    <svg-use
      id="angle-left"
      type="regular"
      class="#h-[1em] #w-[0.6em] #fill-inkdropdark #cursor-pointer hover:#fill-vividmagenta"
      :class="{ '#opacity-50 #pointer-events-none #select-none': firstPage }"
      @click="previousPage"
    >
    </svg-use>
    <template v-for="(p, i) in displayedPages" :key="`page_${i}`">
      <p
        v-if="p !== '...'"
        class="#font-medium hover:#text-vividmagenta"
        :class="page === p ? '#text-vividmagenta #cursor-default !#opacity-100' : '#cursor-pointer'"
        @click="goToPage(p)"
      >
        {{ p }}
      </p>
      <p v-else>...</p>
    </template>
    <svg-use
      id="angle-right"
      type="regular"
      class="#h-[1em] #w-[0.6em] #fill-inkdropdark #cursor-pointer hover:#fill-vividmagenta"
      :class="{ '#opacity-50 #pointer-events-none #select-none': lastPage }"
      @click="nextPage"
    >
    </svg-use>
  </div>
</template>

<script>
import SvgUse from "./SvgUse.vue";

export default {
  components: {
    SvgUse,
  },
  props: {
    page: {
      type: Number,
      default: 0,
    },
    pages: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change"],
  computed: {
    firstPage() {
      return this.page === 1;
    },
    lastPage() {
      return this.page === this.pages;
    },
    slot1() {
      return 1;
    },
    slot2() {
      if (this.pages < 2) {
        return;
      }
      if (this.pages < 8 || this.page < 5) {
        return 2;
      }

      return "...";
    },
    slot3() {
      if (this.pages < 3) {
        return;
      }
      if (this.pages < 8 || this.page < 5) {
        return 3;
      }

      if (this.pages - this.page > 2) {
        return this.page - 1;
      }

      return this.pages - 4;
    },
    slot4() {
      if (this.pages < 4) {
        return;
      }
      if (this.pages < 8 || this.page < 5) {
        return 4;
      }
      if (this.pages - this.page > 2) {
        return this.page;
      }

      return this.pages - 3;
    },
    slot5() {
      if (this.pages < 5) {
        return;
      }
      if (this.pages < 8 || this.page < 5) {
        return 5;
      }
      if (this.pages - this.page > 2) {
        return this.page + 1;
      }

      return this.pages - 2;
    },
    slot6() {
      if (this.pages < 6) {
        return;
      }
      if (this.pages < 8) {
        return 6;
      }
      if (this.pages - this.page > 3) {
        return "...";
      }

      return this.pages - 1;
    },
    slot7() {
      if (this.pages < 7) {
        return;
      }

      return this.pages;
    },

    displayedPages() {
      return [
        this.slot1,
        this.slot2,
        this.slot3,
        this.slot4,
        this.slot5,
        this.slot6,
        this.slot7,
      ].filter((i) => i);
    },
  },
  methods: {
    goToPage(p) {
      this.$emit("change", p);
    },
    previousPage() {
      if (this.firstPage) {
        return;
      }
      this.goToPage(this.page - 1);
    },
    nextPage() {
      if (this.lastPage) {
        return;
      }
      this.goToPage(this.page + 1);
    },
  },
};
</script>
