<template>
  <FadeIn>
    <span v-html="storehours.html"></span>
  </FadeIn>
</template>

<script>
import FadeIn from "../components/FadeIn.vue";
import { mapGetters } from "vuex";
export default {
  name: "StoreHours",
  components: { FadeIn },
  computed: {
    ...mapGetters(["storehours"]),
  },
};
</script>

<style module="s">
.fadeEnterActive,
.fadeLeaveActive {
  transition: opacity 0.4s ease;
}

.fadeEnterFrom,
.fadeLeaveTo {
  opacity: 0;
}
</style>
