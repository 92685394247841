<template>
  <h3 class="#font-serif #text-lg #mb-[10px]">
    {{ __("Artikelen") }}
  </h3>
  <div>
    <div
      v-for="article in list"
      :key="article.id"
      class="#flex #items-start #justify-start #gap-x-[15px] #w-full #py-[30px] #border-t #border-solid #border-[#d5d5d5] first:#pt-0 first:#border-t-0"
    >
      <div class="#w-[70px] #hidden sm:#block">
        <img
          :src="article.img && !article.missing ? article.img : '/img/cart-empty-document.png'"
          alt=""
          class="#w-full"
          :class="
            article.missing || !article.img
              ? ''
              : '#cursor-pointer #transition-all #border #border-[#d5d5d5] hover:#opacity-50'
          "
          @click.stop="openLightbox(article, images)"
        />
      </div>
      <div class="#w-full #flex-1 #space-y-8 sm:#space-y-2 #grid #grid-cols-3 #gap-x-[30px]">
        <div :class="showDetails ? 'max-sm:#col-span-3 #col-span-2' : '#col-span-3'">
          <div class="#flex #justify-start #items-baseline #gap-x-2 max-sm:#flex-wrap">
            <p class="#text-base #font-medium #min-w-max">
              {{ article.quantity }} x {{ article.product_name }}
            </p>
            <p class="#text-xs #col-span-3 #text-[#707070]">{{ __("art.") }} {{ article.id }}</p>
          </div>
          <div class="#flex #text-xs sm:#text-sm #text-[#707070] #font-normal">
            <p
              v-for="(text, i) in splitUpReference(article.reference)"
              :key="i"
              :class="{ '#line-clamp-1 #break-all': i === 0, '#whitespace-pre': i !== 0 }"
            >
              {{ text }}
            </p>
          </div>
        </div>
        <div v-if="showDetails" class="sm:#justify-self-end max-sm:#col-span-3 max-sm:#order-last">
          <div aria-describedby="tooltip" class="#w-max">
            <Button
              btn-class="grey"
              :text="__('Opnieuw bestellen')"
              size="xs"
              @click="
                toggleReOrder(
                  $event.currentTarget.parentElement,
                  article.id,
                  article.quantity,
                  article.missing,
                )
              "
            >
              <svg-use id="cart-shopping" type="solid" class="#h-[1em] #w-[1em] #fill-inkdropdark">
              </svg-use>
            </Button>
          </div>
        </div>

        <img
          :src="article.img && !article.missing ? article.img : '/img/cart-empty-document.png'"
          alt=""
          class="#w-full #max-w-[100px] #block #col-span-2 sm:#hidden"
          :class="
            article.missing || !article.img
              ? ''
              : '#cursor-pointer #transition-all #border #border-[#d5d5d5] hover:#opacity-50'
          "
          @click.stop="openLightbox(article, images)"
        />
        <p class="#col-span-3" v-html="article.info" />
        <div v-if="article.filenames.length > 0" class="#col-span-3">
          <p v-for="(file, i) in article.filenames" :key="i" class="#text-xs">
            <a :href="file.link" target="_blank" class="#underline">{{ file.name }}</a>
          </p>
        </div>
        <div
          v-if="article.comment"
          class="#bg-inkdroplight #p-4 #text-sm #col-span-2 #rounded-[4px] #w-max #max-w-full"
        >
          <p class="#font-medium">{{ __("Opmerking") }}</p>
          <p>{{ article.comment }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../components/Button.vue";
import SvgUse from "../components/SvgUse.vue";
import { lang } from "../mixins";
import { mapGetters } from "vuex";

export default {
  components: {
    Button,
    SvgUse,
  },
  mixins: [lang],
  inject: ["toggleReOrder", "openLightbox", "showDetails"],
  props: {
    articles: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["createMessage"],
  data() {
    return {
      items_rendered: 5,
    };
  },
  computed: {
    ...mapGetters(["route"]),
    list() {
      return this.articles.slice(0, this.items_rendered);
    },
    images() {
      let images = [];
      for (const article of this.articles) {
        if (!article.missing && article.img) {
          images.push(article.img);
        }
      }
      return images;
    },
  },
  mounted() {
    this.renderArticle();
  },
  updated() {
    this.renderArticle();
  },
  methods: {
    renderArticle() {
      if (this.items_rendered < this.articles.length) {
        setTimeout(() => {
          this.items_rendered += 10;
        }, 1);
      }
    },
    splitUpReference(ref) {
      if (ref.length > 3) {
        let firstPart = ref.slice(0, ref.length - 3);
        let lastPart = ref.slice(-3);
        let newRef = [firstPart.replace(/ /g, "\u00A0"), lastPart];
        return newRef;
      }

      return ref;
    },
  },
};
</script>
