<template>
  <transition
    :enter-from-class="s.fadeEnterFrom"
    :leave-to-class="s.fadeLeaveTo"
    :enter-active-class="s.fadeEnterActive"
    :leave-active-class="s.fadeLeaveActive"
    @enter="() => $emit('enter')"
  >
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: "SlideIn",
  methods: {},
};
</script>

<style module="s">
.fadeEnterActive {
  animation: showInput 0.3s cubic-bezier(0.66, 0.01, 0.4, 1.01) forwards;
}
.fadeLeaveActive {
  animation: hideInput 0.3s cubic-bezier(0.66, 0.01, 0.4, 1.01) forwards;
}
@keyframes showInput {
  0% {
    width: 20%;
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    width: 100%;
  }
}
@keyframes hideInput {
  0% {
    width: 100%;
  }

  50% {
    opacity: 1;
  }

  100% {
    width: 0%;
    opacity: 0;
  }
}
</style>
