<template>
  <div v-swap="html"></div>
</template>

<script>
export default {
  directives: {
    swap: {
      mounted(el, binding) {
        let frag = document.createRange().createContextualFragment(binding.value);
        el.replaceWith(frag);
      },
    },
  },
  props: {
    html: {
      type: String,
      required: true,
    },
  },
};
</script>
