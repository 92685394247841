<template>
  <WaitingList location="checkout"></WaitingList>
</template>

<script>
import WaitingList from "../components/WaitingList.vue";

export default {
  components: {
    WaitingList,
  },
};
</script>
