<template>
  <OrderOverview ref="orderOverview" :loading="loading" :fetch-orders="fetchOrders"></OrderOverview>
  <div
    v-if="(!$refs.orderOverview || $refs.orderOverview.orders.length == 0) && !loading"
    class="tailwind"
  >
    <div class="#mx-[15px] #my-[20px] md:#mx-[30px]">
      <p class="#text-center">{{ __("Er zijn geen bestellingen gevonden.") }}</p>
    </div>
  </div>
</template>

<script>
import OrderOverview from "../components/OrderOverview.vue";
import { lang } from "../mixins";
import { mapGetters } from "vuex";

export default {
  components: {
    OrderOverview,
  },
  mixins: [lang],
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  mounted() {
    this.$refs.orderOverview.getOrders();
  },
  methods: {
    async fetchOrders(info) {
      this.loading = true;
      let obj = {
        from: info.from,
        to: info.to,
        column: info.column,
        direction: info.direction,
        status: info.status,
        page: info.page,
      };

      let response = await fetch(this.route("orders"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(obj),
      });

      this.loading = false;

      return response;
    },
  },
};
</script>
