<script setup>
import NumberOption from "../options/NumberOption.vue";
import DocumentOption from "../options/DocumentOption.vue";
import SizeA7Option from "../options/SizeA7Option.vue";
import SizeA6Option from "../options/SizeA6Option.vue";
import OptionSection from "../OptionSection.vue";
import SizeA5Option from "../options/SizeA5Option.vue";
import SizeA4Option from "../options/SizeA4Option.vue";
import SizeDinLongOption from "../options/SizeDinLongOption.vue";
import ColorAllOption from "../options/ColorAllOption.vue";
import ColorNoneOption from "../options/ColorNoneOption.vue";
import PrintSideDoubleOption from "../options/PrintSideDoubleOption.vue";
import PrintSideSingleOption from "../options/PrintSideSingleOption.vue";
import PaperStandardOption from "../options/PaperStandardOption.vue";
import PaperRomanBiotopOption from "../options/PaperRomanBiotopOption.vue";
import PaperFirmOption from "../options/PaperFirmOption.vue";
import PaperExtraFirmOption from "../options/PaperExtraFirmOption.vue";
import PaperGlossyOption from "../options/PaperGlossyOption.vue";
import PaperVeryFirmOption from "../options/PaperVeryFirmOption.vue";
import PaperNaturalCardOption from "../options/PaperNaturalCardOption.vue";
import PaperCardOption from "../options/PaperCardOption.vue";
import PaperEcoFiberOption from "../options/PaperEcoFiberOption.vue";
import PaperNevertearOption from "../options/PaperNevertearOption.vue";
import PaperNevertearExtraOption from "../options/PaperNevertearExtraOption.vue";
import TopcoatNoneOption from "../options/TopcoatNoneOption.vue";
import TopcoatOption from "../options/TopcoatOption.vue";
import SizeCustomOption from "../options/SizeCustomOption.vue";
import CoatingFrontOption from "../options/CoatingFrontOption.vue";
import CoatingDoubleOption from "../options/CoatingDoubleOption.vue";
import CheckDocStandardOption from "../options/CheckDocStandardOption.vue";
import CheckDocAdvancedOption from "../options/CheckDocAdvancedOption.vue";
import BorderlessOption from "../options/BorderlessOption.vue";
import DefaultOptions from "../DefaultOptions.vue";
import RoundCornersOption from "../options/RoundCornersOption.vue";
import { getCurrentInstance } from "vue";
import HiddenField from "../fields/HiddenField.vue";

// briefpapier
// kleurplaat
// placemat

defineOptions({
  slug: "flyer",
  group: "flyer",
  title: "Flyer",
  aliases: [
    { slug: "a7-flyer", title: "A7 Flyer" },
    { slug: "a6-flyer", title: "A6 Flyer" },
    { slug: "a5-flyer", title: "A5 Flyer" },
    { slug: "a4-flyer", title: "A4 Flyer" },
    { slug: "a4-flyer", title: "A4 Flyer" },
    { slug: "flyer-din-long", title: "Din long flyer" },
    { slug: "flyer-eigen-formaat", title: "Eigen formaat flyer" },
  ],
});

const instance = getCurrentInstance().type;

const props = defineProps({
  product: {
    type: String,
    required: true,
  },
});

const showSize = (size) => {
  let customSizeAliases = [
    "a7-flyer",
    "a6-flyer",
    "a5-flyer",
    "a4-flyer",
    "flyer-din-long",
    "flyer-eigen-formaat",
  ];

  switch (size) {
    case "a7":
      return props.product === "a7-flyer" || !customSizeAliases.includes(props.product);
    case "a6":
      return props.product === "a6-flyer" || !customSizeAliases.includes(props.product);
    case "a5":
      return props.product === "a5-flyer" || !customSizeAliases.includes(props.product);
    case "a4":
      return props.product === "a4-flyer" || !customSizeAliases.includes(props.product);
    case "din-long":
      return props.product === "flyer-din-long" || !customSizeAliases.includes(props.product);
    case "custom":
      return props.product === "flyer-eigen-formaat" || !customSizeAliases.includes(props.product);
    default:
      return false;
  }
};
</script>

<template>
  <HiddenField name="copies" type="integer" :initial="1" />

  <OptionSection name="Oplage">
    <NumberOption :initial="1" />
  </OptionSection>
  <OptionSection name="Document" divider>
    <DocumentOption />
  </OptionSection>
  <OptionSection name="Formaat">
    <SizeA7Option
      v-if="showSize('a7')"
      :initial="['flyer', 'a7-flyer'].includes(product)"
      :hidden="product === 'a7-flyer'"
    />
    <SizeA6Option
      v-if="showSize('a6')"
      :initial="product === 'a6-flyer'"
      :hidden="product === 'a6-flyer'"
    />
    <SizeA5Option
      v-if="showSize('a5')"
      :initial="product === 'a5-flyer'"
      :hidden="product === 'a5-flyer'"
    />
    <SizeA4Option
      v-if="showSize('a4')"
      :initial="product === 'a4-flyer'"
      :hidden="product === 'a4-flyer'"
    />
    <SizeDinLongOption
      v-if="showSize('din-long')"
      :initial="product === 'flyer-din-long'"
      :hidden="product === 'flyer-din-long'"
    />
    <SizeCustomOption v-if="showSize('custom')" :initial="product === 'flyer-eigen-formaat'" />
  </OptionSection>
  <OptionSection name="Opdruk">
    <ColorAllOption initial />
    <ColorNoneOption />
  </OptionSection>
  <OptionSection name="Printwijze">
    <PrintSideSingleOption initial />
    <PrintSideDoubleOption />
    <BorderlessOption initial />
  </OptionSection>
  <OptionSection name="Papiersoort">
    <PaperStandardOption icon="leaf" icon-color="green" />
    <PaperRomanBiotopOption icon="leaf" icon-color="green" />
    <PaperFirmOption initial />
    <PaperExtraFirmOption />
    <PaperGlossyOption
      icon="star"
      icon-title="Aanbevolen"
      icon-content="170 grams is stevig maar niet te duur en heeft een duidelijke glans, wij raden dit papiersoort aan voor een goede flyer"
    />
    <PaperVeryFirmOption />
    <PaperNaturalCardOption />
    <PaperCardOption />
    <PaperEcoFiberOption icon="leaf" icon-color="green" />
    <PaperNevertearOption />
    <PaperNevertearExtraOption />
    <RoundCornersOption />
  </OptionSection>
  <OptionSection name="Toplaag">
    <TopcoatNoneOption initial />
    <TopcoatOption />
  </OptionSection>
  <OptionSection name="Coating">
    <CoatingFrontOption initial />
    <CoatingDoubleOption />
  </OptionSection>
  <OptionSection name="Document controle" divider>
    <CheckDocStandardOption initial />
    <CheckDocAdvancedOption />
  </OptionSection>

  <DefaultOptions :product="props.product" :group="instance.group" />
</template>
