<template>
  <div :class="s.container">
    <div :class="s.row">
      <Number :disabled="disabled" text="1" @press="() => pressNumber('1')" />
      <Number :disabled="disabled" text="2" @press="() => pressNumber('2')" />
      <Number :disabled="disabled" text="3" @press="() => pressNumber('3')" />
    </div>
    <div :class="s.row">
      <Number :disabled="disabled" text="4" @press="() => pressNumber('4')" />
      <Number :disabled="disabled" text="5" @press="() => pressNumber('5')" />
      <Number :disabled="disabled" text="6" @press="() => pressNumber('6')" />
    </div>
    <div :class="s.row">
      <Number :disabled="disabled" text="7" @press="() => pressNumber('7')" />
      <Number :disabled="disabled" text="8" @press="() => pressNumber('8')" />
      <Number :disabled="disabled" text="9" @press="() => pressNumber('9')" />
    </div>
    <div :class="s.row">
      <Number
        :disabled="disabled"
        icon="delete-left"
        inverted
        @press="remove"
        @longPress="remove"
      />
      <Number :disabled="disabled" text="0" @press="() => pressNumber('0')" />
      <Number :disabled="disabled" icon="right-long-to-line" inverted @press="submit" />
    </div>
    <audio ref="audio" src="/sound/click.mp3"></audio>
  </div>
</template>

<script>
import Number from "./Number.vue";
export default {
  components: { Number },
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
    pressNumber(btn) {
      this.playSound();
      this.$emit("change", this.value + btn);
    },
    remove() {
      if (this.value) {
        this.playSound();
        this.$emit("change", this.value.slice(0, -1));
      }
    },
    playSound() {
      this.$refs.audio.pause();
      this.$refs.audio.currentTime = 0;
      this.$refs.audio.play();
    },
  },
};
</script>

<style module="s">
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
</style>
