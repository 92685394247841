<template>
  <OrderOverview
    ref="orderOverview"
    :loading="loading"
    :fetch-orders="fetchOrders"
    :show-details="true"
    :show-filters="false"
  ></OrderOverview>

  <div class="#mx-[15px] #my-[20px] md:#mx-[30px]">
    <Button
      v-if="!user.is_verified && !isAdmin"
      btn-class="secondary"
      :disabled="verifying"
      @click="verify"
    >
      {{ __("Verifiëren") }}
    </Button>

    <Button
      v-else-if="$refs.orderOverview && $refs.orderOverview.orders.length > 0"
      btn-class="secondary"
      :disabled="merging"
      @click="mergeAccounts"
    >
      {{ __("Voeg nu samen") }}
    </Button>

    <div v-else class="#text-center">
      <p>
        {{ __("Er zijn geen bestellingen gevonden.") }}
      </p>
    </div>
  </div>
</template>

<script>
import OrderOverview from "../components/OrderOverview.vue";
import Button from "../components/Button.vue";
import { lang } from "../mixins";
import { mapGetters } from "vuex";

export default {
  components: {
    OrderOverview,
    Button,
  },
  mixins: [lang],
  data() {
    return {
      loading: true,
      orders: [],
      merging: false,
      verifying: false,
    };
  },
  computed: {
    ...mapGetters(["route", "isAdmin", "user"]),
  },
  watch: {
    "$refs.orderOverview.orders": {
      handler(newValue) {
        this.orders = newValue;
      },
      deep: true,
    },
  },
  mounted() {
    this.$refs.orderOverview.getOrders();
  },
  methods: {
    async fetchOrders(info) {
      this.loading = true;
      let obj = {
        from: info.from,
        to: info.to,
        column: info.column,
        direction: info.direction,
        status: info.status,
        page: info.page,
      };

      let response = await fetch(this.route("merge.orders"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(obj),
      });

      this.loading = false;

      return response;
    },
    async mergeAccounts() {
      this.merging = true;
      let response = await fetch(this.route("merge.accounts"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      if (response.ok) {
        await response.json();
      } else if (!response.ok) {
        await response.text();
      }

      this.merging = false;

      location.reload();
    },
    async verify() {
      this.verifying = true;
      const response = await fetch(this.route("verify"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      if (response.ok) {
        await response.json();
      } else if (!response.ok) {
        await response.text();
      }

      this.verifying = false;

      location.reload();
    },
  },
};
</script>
