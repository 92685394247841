<template>
  <div
    class="#bg-white #rounded-[6px] #w-full #p-[15px] xl:#p-[30px] #shadow-[0_0px_20px_-3px_rgba(0,0,0,0.16)]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>
