<script setup>
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";
import PaperEcoFiberOption from "./PaperEcoFiberOption.vue";
import PaperNevertearOption from "./PaperNevertearOption.vue";
import PaperNevertearExtraOption from "./PaperNevertearExtraOption.vue";
import { computed } from "vue";
import { FatalFormError } from "../composables/errors";
import TopcoatNoneOption from "./TopcoatNoneOption.vue";
import { useChecks, useCMS, useSetup } from "../composables/option";
import { useOrderForm } from "../../modules/orderModule";

defineOptions({
  key: "finishing2",
  value: "coating",
  label: "Coating",
});
const props = defineProps(useRadioOptionProps());
const { check, name, key, label, value } = useSetup(props);
const { disabled: cms_disabled, tooltipTitle, tooltipContent } = useCMS(name, check);
const { isRequired, isOneOf } = useChecks();
const form = useOrderForm();

check(isRequired());
check(isOneOf(props.choices));

check((option, form) => {
  if (!option.isChecked()) {
    return;
  }

  let checked = null;
  if (form.isChecked(PaperEcoFiberOption)) {
    checked = PaperEcoFiberOption;
  } else if (form.isChecked(PaperNevertearOption)) {
    checked = PaperNevertearOption;
  } else if (form.isChecked(PaperNevertearExtraOption)) {
    checked = PaperNevertearExtraOption;
  }

  if (checked) {
    throw new FatalFormError(`${checked.label} kunnen wij niet voorzien van coating. Kies voor`, {
      cta_text: "Geen toplaag",
      cta: () => form.updateItem({ name: TopcoatNoneOption.key, value: TopcoatNoneOption.value }),
    });
  }
});

const disabled = computed(() => {
  if (cms_disabled) {
    return true;
  }
  if (form.isChecked(PaperEcoFiberOption)) {
    return true;
  }
  if (form.isChecked(PaperNevertearOption)) {
    return true;
  }
  if (form.isChecked(PaperNevertearExtraOption)) {
    return true;
  }

  return false;
});
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :name="key"
    :value="value"
    :label="label"
  />
</template>
