<template>
  <SmallForm
    :succes-message="successMessage"
    :title="__('Wachtwoord vergeten')"
    :description="
      __(
        'Geef je emailadres op en je ontvangt een email met een link om het wachtwoord te herstellen.',
      )
    "
    :btn-text="__('Stuur mij een mail')"
    btn-color="secondary"
    btn-size="full-lg"
    :submitting="submitting"
    @submit="formSubmitted"
  >
    <InputField
      :error="firstError('email')"
      :model-value="form.email"
      class="#w-full #mb-[30px] #text-left"
      :label="__('E-mailadres')"
      type="text"
      :required="true"
      @update:model-value="(newValue) => (form.email = newValue)"
    />
  </SmallForm>
</template>

<script>
import SmallForm from "../components/SmallForm.vue";
import InputField from "../components/InputField.vue";
import { mapGetters } from "vuex";
import { lang, form } from "../mixins";

export default {
  components: {
    SmallForm,
    InputField,
  },
  mixins: [lang, form],
  data() {
    return {
      form: {
        email: "",
      },
      formErrors: {},
      successMessage: "",
      submitting: false,
    };
  },
  computed: {
    ...mapGetters(["route", "user"]),
  },
  methods: {
    formSubmitted() {
      this.formErrors = {};
      this.resetPassword();
    },
    updateInput(newValue) {
      this.form.email = newValue;
      this.formErrors = {};
      this.successMessage = "";
    },
    async resetPassword() {
      this.submitting = true;
      let response = await fetch(this.route("password.request"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          ...this.form,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        this.successMessage = data.message;
        this.form.email = "";
        this.submitting = false;
      } else if (response.status === 422) {
        const body = await response.json();
        this.formErrors = body.errors;
        this.submitting = false;
      } else {
        await response.text();
      }
    },
  },
};
</script>
