<template>
  <form
    class="#w-full #flex #justify-center #flex-col"
    :class="isOrderPage() ? '#items-start' : '#items-center'"
    autocomplete="false"
    @submit.prevent
  >
    <p
      v-if="registerMessage !== null"
      class="#mt-[5px] #text-center #text-base #font-semibold #text-[#2CD11A]"
    >
      {{ registerMessage }}
    </p>
    <template v-else>
      <div
        class="#flex #justify-center #gap-x-0 #relative #w-full #max-w-[260px] #my-[10px] min-[450px]:#gap-x-[10px]"
      >
        <a
          href="javascript:"
          class="#relative #flex #justify-center #py-4 #px-4 #text-center #font-medium #text-base hover:#opacity-100"
          :class="{ '#opacity-30': form.contact_type !== 'bedrijf' }"
          @click="form.contact_type = 'bedrijf'"
        >
          <svg
            class="#absolute #top-0 #w-[110%] #h-[130%] #-mt-[10px]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 266.67 149.97"
            preserveAspectRatio="none"
          >
            <defs>
              <clipPath id="businessclip">
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="m156.2,11.9c-6.3.5-11.6,1.2-15.2,1.8-1.8.3-3.2.5-4.2.7-1,.2-1.5.3-1.5.3-6.7,2-13.7,4.1-20.5,6.1C117.3,14,121,.7,116.5,0c-1.9,0-10,.3-20.9,2.8-2.7.6-5.6,1.3-8.7,2.2C43.1,17.3,7.9,51.9,1.9,73.7c-7.8,28.3,9.8,50.5,38,61.9,3.2,1.3,6.5,2.4,9.6,3.4,6.3,2,12.3,3.4,17.8,4.6,9.5,2,19.3,3.7,24.6,5.2,3-.5,12,.3,24.1.8,12.1.5,27.2.7,42,0,48.9-2.6,65.3-14.8,71.8-18.4,9.5-5.3,18.4-11.5,18.4-11.5,10.7-4.7,14.9-21.4,16.9-23.9,11.8-45.5-42.8-88.7-108.8-83.7Zm79.6,72.8c-2.5,17.8-36.1,28.5-54.3,32.7-8.6,2-2.9-.2-10.8.8-9.7,1.2-19.2,2-31.6,2.2-26.2.5-94.8-3.3-106.2-26.5-1.7-3.5-1.2-7-.6-7.2.7-.2-1.2-2.8-.3-3.4,4.1-15.3,20.8-30.7,38.8-40.6-1.8,8.4.4,24.3,4.1,27,.7.5,11.8-5.1,23-10.4,11.2-5.3,22.7-9.7,23.7-10.3,1.1-.7,2.4-.9,2.9-.5,0,0,.7-.1,1.9-.4,6.7-1.5,23.7-6,40.3-5.5,0,.5,1.2.7,2.9.3,1.7-.4,6.6-.7,13.2,0,18.3,1.7,33.3,9,41.7,16.4,4.7,4.2,7.4,8.3,8.3,10.2,1.9,4.3,3.4,10.4,3.2,15.2Z"
                  style="fill: none"
                />
              </clipPath>
            </defs>
            <g clip-path="url('#businessclip')">
              <path
                id="bedrijf"
                class="typecircle #stroke-brightcyan #stroke-[30px]"
                :class="getCircleClass('bedrijf')"
                d="m124.26,10.01S22.26,26.01,16.26,88.01c-5.01,51.76,127,48,137,48s96.72-4.66,98-56c1-40-58.01-54.76-86-54-37,1-73,17-102,33"
                style="fill: none"
              />
            </g>
          </svg>
          <p class="#relative #z-[1] #font-semibold">
            {{ __("Zakelijk") }}
          </p>
        </a>
        <a
          href="javascript:"
          class="#relative #flex #justify-center #py-4 #px-4 #text-center #font-medium #text-base hover:#opacity-100"
          :class="{ '#opacity-30': form.contact_type !== 'particulier' }"
          @click="form.contact_type = 'particulier'"
        >
          <svg
            class="#absolute #top-0 #w-[110%] #h-[130%] #-mt-[10px]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 266.67 149.97"
            preserveAspectRatio="none"
          >
            <defs>
              <clipPath id="privateclip">
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="m156.2,11.9c-6.3.5-11.6,1.2-15.2,1.8-1.8.3-3.2.5-4.2.7-1,.2-1.5.3-1.5.3-6.7,2-13.7,4.1-20.5,6.1C117.3,14,121,.7,116.5,0c-1.9,0-10,.3-20.9,2.8-2.7.6-5.6,1.3-8.7,2.2C43.1,17.3,7.9,51.9,1.9,73.7c-7.8,28.3,9.8,50.5,38,61.9,3.2,1.3,6.5,2.4,9.6,3.4,6.3,2,12.3,3.4,17.8,4.6,9.5,2,19.3,3.7,24.6,5.2,3-.5,12,.3,24.1.8,12.1.5,27.2.7,42,0,48.9-2.6,65.3-14.8,71.8-18.4,9.5-5.3,18.4-11.5,18.4-11.5,10.7-4.7,14.9-21.4,16.9-23.9,11.8-45.5-42.8-88.7-108.8-83.7Zm79.6,72.8c-2.5,17.8-36.1,28.5-54.3,32.7-8.6,2-2.9-.2-10.8.8-9.7,1.2-19.2,2-31.6,2.2-26.2.5-94.8-3.3-106.2-26.5-1.7-3.5-1.2-7-.6-7.2.7-.2-1.2-2.8-.3-3.4,4.1-15.3,20.8-30.7,38.8-40.6-1.8,8.4.4,24.3,4.1,27,.7.5,11.8-5.1,23-10.4,11.2-5.3,22.7-9.7,23.7-10.3,1.1-.7,2.4-.9,2.9-.5,0,0,.7-.1,1.9-.4,6.7-1.5,23.7-6,40.3-5.5,0,.5,1.2.7,2.9.3,1.7-.4,6.6-.7,13.2,0,18.3,1.7,33.3,9,41.7,16.4,4.7,4.2,7.4,8.3,8.3,10.2,1.9,4.3,3.4,10.4,3.2,15.2Z"
                  style="fill: none"
                />
              </clipPath>
            </defs>
            <g clip-path="url('#privateclip')">
              <path
                id="particulier"
                class="typecircle #stroke-brightcyan #stroke-[30px]"
                :class="getCircleClass('particulier')"
                d="m124.26,10.01S22.26,26.01,16.26,88.01c-5.01,51.76,127,48,137,48s96.72-4.66,98-56c1-40-58.01-54.76-86-54-37,1-73,17-102,33"
                style="fill: none"
              />
            </g>
          </svg>
          <p class="#relative #z-[1] #font-semibold">
            {{ __("Particulier") }}
          </p>
        </a>
        <a
          href="javascript:"
          class="#relative #flex #justify-center #py-4 #px-4 #text-center #font-medium #text-base hover:#opacity-100"
          :class="{ '#opacity-30': form.contact_type !== 'student' }"
          @click="form.contact_type = 'student'"
        >
          <svg
            class="#absolute #top-0 #w-[110%] #h-[130%] #-mt-[10px]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 266.67 149.97"
            preserveAspectRatio="none"
          >
            <defs>
              <clipPath id="studentclip">
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="m156.2,11.9c-6.3.5-11.6,1.2-15.2,1.8-1.8.3-3.2.5-4.2.7-1,.2-1.5.3-1.5.3-6.7,2-13.7,4.1-20.5,6.1C117.3,14,121,.7,116.5,0c-1.9,0-10,.3-20.9,2.8-2.7.6-5.6,1.3-8.7,2.2C43.1,17.3,7.9,51.9,1.9,73.7c-7.8,28.3,9.8,50.5,38,61.9,3.2,1.3,6.5,2.4,9.6,3.4,6.3,2,12.3,3.4,17.8,4.6,9.5,2,19.3,3.7,24.6,5.2,3-.5,12,.3,24.1.8,12.1.5,27.2.7,42,0,48.9-2.6,65.3-14.8,71.8-18.4,9.5-5.3,18.4-11.5,18.4-11.5,10.7-4.7,14.9-21.4,16.9-23.9,11.8-45.5-42.8-88.7-108.8-83.7Zm79.6,72.8c-2.5,17.8-36.1,28.5-54.3,32.7-8.6,2-2.9-.2-10.8.8-9.7,1.2-19.2,2-31.6,2.2-26.2.5-94.8-3.3-106.2-26.5-1.7-3.5-1.2-7-.6-7.2.7-.2-1.2-2.8-.3-3.4,4.1-15.3,20.8-30.7,38.8-40.6-1.8,8.4.4,24.3,4.1,27,.7.5,11.8-5.1,23-10.4,11.2-5.3,22.7-9.7,23.7-10.3,1.1-.7,2.4-.9,2.9-.5,0,0,.7-.1,1.9-.4,6.7-1.5,23.7-6,40.3-5.5,0,.5,1.2.7,2.9.3,1.7-.4,6.6-.7,13.2,0,18.3,1.7,33.3,9,41.7,16.4,4.7,4.2,7.4,8.3,8.3,10.2,1.9,4.3,3.4,10.4,3.2,15.2Z"
                  style="fill: none"
                />
              </clipPath>
            </defs>
            <g clip-path="url('#studentclip')">
              <path
                id="student"
                class="typecircle #stroke-brightcyan #stroke-[30px]"
                :class="getCircleClass('student')"
                d="m124.26,10.01S22.26,26.01,16.26,88.01c-5.01,51.76,127,48,137,48s96.72-4.66,98-56c1-40-58.01-54.76-86-54-37,1-73,17-102,33"
                style="fill: none"
              />
            </g>
          </svg>
          <p class="#relative #z-[1] #font-semibold">
            {{ __("Student") }}
          </p>
        </a>
      </div>
      <div v-if="form.contact_type === 'bedrijf'" class="#w-full #mb-[30px]">
        <h2 class="#w-full #text-lg #text-left #font-semibold #mb-[10px]">
          {{ __("Factuurgegevens") }}
        </h2>
        <InputField
          v-model="form.company"
          class="#w-full #mb-[15px]"
          :label="__('Bedrijfsnaam')"
          :error="firstError('company')"
          :warning="formWarnings.company"
          type="text"
          :required="true"
        />
        <InputField
          v-if="
            isLoggedIn() && from_europe && isOrderPage() && initialForm && initialForm.vat_number
          "
          :model-value="form.vat_number"
          class="#w-full"
          :label="__('BTW nummer')"
          type="text"
          :input-class="vies_border"
          @update:model-value="(value) => filterVatNumber(value)"
          @blur="validateVatNumber(form.vat_number)"
        />
        <p
          v-if="vatResponse && vatResponse.error_text && (showFatalErrors || showWarnings)"
          class="#text-sm #font-semibold #mt-2"
          :class="vies_text"
        >
          {{ vatResponse.error_text }}
        </p>

        <InfoDropdown
          v-if="vatResponse && vatResponse.show_help && (showFatalErrors || showWarnings)"
          class="#max-w-[500px] #mt-2"
          :dropdown-class="vies_text"
        >
          <template #info>
            <p class="#text-sm #mb-2 #mt-2">
              {{
                __(
                  "Bij het invullen van het BTW nummer is het belangrijk dat deze voldoet aan alle eisen van de EU.",
                )
              }}
            </p>
            <p class="#text-sm">
              {{
                __(
                  "Is dat het geval? Neem dan contact op met de KVK om te controleren wat er mis is.",
                )
              }}
            </p>
            <div class="#flex #flex-wrap #gap-y-[10px] #gap-x-[15px] #mt-6">
              <Button
                :text="__('BTW valideren')"
                target="_blank"
                href="https://ec.europa.eu/taxation_customs/vies/#/vat-validation"
              ></Button>
              <Button
                :text="__('Contact KVK')"
                href="https://www.kvk.nl/hulp-en-contact/"
                target="_blank"
                btn-class="primary-outline"
              ></Button>
            </div>
          </template>
        </InfoDropdown>

        <div v-if="!form.default_invoice_address" class="#mt-[15px]">
          <div class="#flex #items-start #gap-x-6 #w-full">
            <InputField
              :model-value="form.invoice_zipcode"
              class="#mb-6"
              size="sm"
              :label="__('Postcode')"
              type="text"
              :required="true"
              @update:model-value="(value) => filterInvoiceZipCode(value)"
            />
            <InputField
              v-model="form.invoice_city"
              class="#mb-6 #flex-1"
              size="lg"
              :label="__('Plaats')"
              type="text"
              :required="true"
            />
          </div>
          <div class="#w-full #flex #items-start #gap-x-6">
            <InputField
              v-model="form.invoice_street"
              :error="firstError('street')"
              class="#mb-6 #flex-1"
              size="lg"
              :label="__('Straatnaam')"
              type="text"
              :required="true"
            />
            <InputField
              ref="streetNumber"
              v-model="form.invoice_streetnumber"
              :error="firstError('streetnumber')"
              class="#mb-6"
              size="sm"
              :label="__('Nummer')"
              type="text"
              :required="true"
            />
          </div>

          <InputDropdown
            v-model="form.invoice_country"
            :items="countries"
            :error="firstError('country')"
            :label="__('Land')"
            class="#w-full #relative"
          />
        </div>
      </div>
      <h2 class="#w-full #text-lg #text-left #font-semibold #mb-[10px]">
        {{ __("Persoonlijke gegevens") }}
      </h2>
      <InputField
        v-model="form.name_contact"
        class="#w-full #mb-[15px]"
        :label="__('Naam')"
        type="text"
        :required="true"
        :error="firstError('name_contact')"
      />
      <InputField
        :model-value="form.email"
        :error="firstError('email')"
        class="#w-full"
        :class="isOrderPage() ? '#mb-[5px]' : '#mb-[15px]'"
        :label="__('E-mailadres')"
        type="text"
        :required="true"
        auto-complete="new-password"
        :disabled="disabledMail"
        @update:model-value="(value) => filterEmailAddress(value)"
        @blur="checkForUser"
      />
      <InputField
        v-if="isRegisterPage() || makeAccount"
        v-model="form.password"
        :error="firstError('password')"
        class="#w-full #mb-[10px]"
        :class="isOrderPage() ? '#mt-[10px] #mb-[5px]' : ''"
        :label="__('Wachtwoord')"
        type="password"
        :required="true"
        auto-complete="new-password"
      />
      <LabeledInput
        v-if="isOrderPage() && !$store.getters.user && !existingCustomer"
        v-model="makeAccount"
        :title="__('Account aanmaken')"
        :register-form="true"
        class="#w-full #mb-[10px]"
      />
      <div
        v-if="form.email && existingCustomer"
        class="#bg-[#d9edf7] #text-sm #p-[10px] #mb-[15px] #mt-[5px] #border-[1px] #border-solid #border-[#bce8f1] #text-[#31708f] #rounded-[4px]"
      >
        {{
          __(
            "Het lijkt erop dat dit e-mailadres al wordt gebruikt voor een ander account. Is dit jouw account? Je kunt ook eerst inloggen om je bestelling later makkelijk terug te vinden.",
          )
        }}
      </div>
      <InputField
        :model-value="form.telephone"
        class="#w-full #mb-[5px] #mt-[5px]"
        :class="{ '!#mt-[10px]': isLoggedIn() }"
        :label="__('Telefoonnummer')"
        type="text"
        :required="false"
        :error="firstError('telephone')"
        @update:model-value="(value) => filterPhoneNumber(value)"
      />
      <div class="#flex #items-start #gap-x-6 #w-full">
        <InputField
          :model-value="form.zipcode"
          :error="firstError('zipcode')"
          class="#mb-6 #mt-12"
          size="sm"
          :label="__('Postcode')"
          type="text"
          :required="true"
          :disabled="autocompleting"
          @update:model-value="(value) => filterZipCode(value)"
          @blur="autocompleteAddress(form.zipcode)"
        >
          <div
            v-if="autocompleting"
            class="#hidden #absolute #h-full #w-full sm:#flex #justify-end #items-center #pointer-events-none #pr-2"
          >
            <LoaderSpinner class="!#w-max !#mx-0" size="sm"></LoaderSpinner>
          </div>
        </InputField>
        <InputField
          v-model="form.city"
          :error="firstError('city')"
          class="#mb-6 #mt-12 #flex-1"
          :disabled="autocompleting"
          size="lg"
          :label="__('Plaats')"
          type="text"
          :required="true"
        />
      </div>
      <div class="#w-full #flex #items-start #gap-x-6">
        <InputField
          v-model="form.street"
          :error="firstError('street')"
          class="#mb-6 #flex-1"
          :disabled="autocompleting"
          size="lg"
          :label="__('Straatnaam')"
          type="text"
          :required="true"
        />
        <InputField
          ref="streetNumber"
          v-model="form.streetnumber"
          :error="firstError('streetnumber')"
          class="#mb-6"
          :disabled="autocompleting"
          size="sm"
          :label="__('Nummer')"
          type="text"
          :required="true"
        />
      </div>

      <InputDropdown
        v-model="form.country"
        :items="countriesForSelect"
        :error="firstError('country')"
        :label="__('Land')"
        class="#w-full #relative"
        :required="true"
      />

      <div v-if="location === 'registerpage'" class="#flex #justify-start #w-full #mt-3 #mb-6">
        <CheckBox v-model="form.keep_updated" input-id="newsletter">
          <span>
            {{ __("Ik wil graag de nieuwsbrief van Print&Bind ontvangen") }}
          </span>
        </CheckBox>
      </div>

      <div class="#flex #justify-between #items-center #w-full">
        <Button
          v-if="isOrderPage()"
          class="#mt-[15px] lg:!#text-lg lg:!#px-[18px] lg:!#py-[12px]"
          text="Opdracht"
          btn-class="grey"
          icon="angle-left"
          icon-type="regular"
          icon-placement="left"
          @click="backToOrder"
        />
        <SubmitButton
          class="#mt-[15px]"
          :class="{
            '#w-full #text-center': isRegisterPage(),
            'lg:!#text-lg lg:!#px-[18px] lg:!#py-[12px]': isOrderPage(),
          }"
          :text="isOrderPage() ? __('Kies leverwijze') : __('Aanmelden')"
          btn-class="secondary"
          :disabled="submitting.details"
          :size="isRegisterPage() ? 'lg' : 'sm'"
          icon="angle-right"
          icon-type="regular"
          @click="submitForm"
        />
      </div>
    </template>
  </form>
</template>

<script>
import { lang, form, autoComplete } from "../mixins";
import InputField from "../components/InputField.vue";
import InputDropdown from "../components/InputDropdown.vue";
import SubmitButton from "../components/SubmitButton.vue";
import InfoDropdown from "../components/InfoDropdown.vue";
import Button from "../components/Button.vue";
import LabeledInput from "../components/LabeledInput.vue";
import CheckBox from "../components/CheckBox.vue";
import LoaderSpinner from "../components/LoaderSpinner.vue";
import { toRaw } from "vue";
import { mapGetters } from "vuex";
import { removeSpaces, removeLetters, removeSpecialCharacters } from "../helpers.js";

export default {
  components: {
    InputField,
    InputDropdown,
    SubmitButton,
    Button,
    LabeledInput,
    InfoDropdown,
    CheckBox,
    LoaderSpinner,
  },
  mixins: [lang, form, autoComplete],
  props: {
    location: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      initialType: null,
      vatResponse: null,
      initialForm: null,
      form: {
        contact_type: this.findCustomerType(),
        name_contact: (this.isLoggedIn() && this.$store.getters.user.name) || "",
        email: (this.isLoggedIn() && this.$store.getters.user.email) || "",
        telephone: (this.isLoggedIn() && this.$store.getters.user.telephone) || "",
        password: (this.isLoggedIn() && this.$store.getters.user.password) || null,
        zipcode: (this.isLoggedIn() && this.$store.getters.user.zipcode) || "",
        city: (this.isLoggedIn() && this.$store.getters.user.city) || "",
        street: (this.isLoggedIn() && this.$store.getters.user.street) || "",
        streetnumber: (this.isLoggedIn() && this.$store.getters.user.streetnumber) || "",
        country: (this.isLoggedIn() && this.$store.getters.user.country) || "",
        company: (this.isLoggedIn() && this.$store.getters.user.company) || "",
        vat_number: (this.isLoggedIn() && this.$store.getters.user.vat_number) || "",
        allow_sms: this.isLoggedIn() ? this.$store.getters.user.allow_sms : false,
        keep_updated: this.isLoggedIn() ? this.$store.getters.user.keep_updated : false,
        default_invoice_address: true,
        invoice_zipcode: "",
        invoice_city: "",
        invoice_street: "",
        invoice_streetnumber: "",
        invoice_country: "",
        is_guest: this.isRegisterPage() === true ? false : true,
      },
      pay_on_account: (this.isLoggedIn() && this.$store.getters.user.pay_on_account) || 0,
      pay_on_account_requested:
        (this.isLoggedIn() && this.$store.getters.user.pay_on_account_requested) || false,
      existingCustomer: false,
      makeAccount: false,
      countries: {},
      countriesForSelect: {},
      submitting: {
        details: false,
        label: false,
        password: false,
        pay_on_account: false,
      },
      formErrors: {},
      formWarnings: {
        company: null,
      },
      registerMessage: null,
      disabledMail: false,
    };
  },
  computed: {
    ...mapGetters(["route", "user", "isAdmin", "config"]),
    vies_text() {
      return {
        "#text-[#EB980C]":
          this.vatResponse &&
          this.vatResponse.error_text &&
          this.vatResponse.allow_save &&
          !this.isOrderPage(),
        "#text-[#d9534f]":
          this.vatResponse && this.vatResponse.error_text && !this.vatResponse.allow_save,
      };
    },
    vies_border() {
      return {
        "!#border-[#EB980C]":
          this.vatResponse &&
          this.vatResponse.error_text &&
          this.vatResponse.allow_save &&
          !this.isOrderPage(),
        "!#border-[#d9534f]":
          this.vatResponse && this.vatResponse.error_text && !this.vatResponse.allow_save,
      };
    },
    from_europe() {
      if (Object.keys(this.countries).length > 0 && this.form.country) {
        return this.countries[this.form.country].from_eu;
      }

      if (Object.keys(this.countries).length > 0 && !this.form.country) {
        return false;
      }

      return true;
    },
    showFatalErrors() {
      return this.vatResponse && !this.vatResponse.allow_save;
    },
    showWarnings() {
      return !this.isOrderPage() && this.vatResponse && !this.vatResponse.allow_save;
    },
  },
  watch: {
    makeAccount(newValue) {
      this.form.is_guest = !newValue;
    },
    existingCustomer(newValue) {
      if (newValue) {
        this.makeAccount = false;
      }
    },
    "form.zipcode"(newValue) {
      this.autocompleteAddress(newValue, this.form.country);
    },
    "form.email"() {
      this.existingCustomer = false;
    },
    "form.vat_number"() {
      this.vatResponse = null;
    },
    "form.country"(newValue) {
      if (newValue && this.form.vat_number) {
        this.validateVatNumber(this.form.vat_number);
      }
    },
    "formErrors.vat_number"(newValue) {
      if (newValue) {
        this.validateVatNumber(this.form.vat_number);
      }
    },
    "form.company"(newValue) {
      if (
        newValue !== this.initialForm.company &&
        (this.pay_on_account === 1 || (this.pay_on_account_requested && this.pay_on_account === 0))
      ) {
        this.formWarnings.company = this.__(
          'Als je de bedrijfsnaam aanpast, zal je de betaalmethode "Bestellen op rekening" opnieuw moeten aanvragen.',
        );
      } else {
        this.formWarnings.company = null;
        this.formWarnings.kvk_number = null;
      }
    },
  },
  created() {
    this.getCountries();
    for (const [key] of Object.entries(this.form)) {
      this.$watch(`form.${key}`, () => {
        this.onFormChange(key);
      });
    }
  },
  mounted() {
    if (this.form.vat_number && this.form.contact_type === "bedrijf") {
      this.filterVatNumber(this.form.vat_number);
      this.validateVatNumber(this.form.vat_number);
    }
    if (this.isLoggedIn()) {
      this.disabledMail = true;
    }
    if (
      this.$store.getters.selection &&
      this.$store.getters.selection.type === "waitinglist_customer_invite"
    ) {
      this.disabledMail = true;
      this.form.email = this.$store.getters.selection.customer_email;
    }

    if (this.config("app.locale") === "nl" && !this.isLoggedIn()) {
      this.form.country = "NL";
    }
    this.initialType = this.form.contact_type;
    this.initialForm = JSON.parse(JSON.stringify(toRaw(this.form)));
    document.addEventListener("mousedown", this.hidePopup);

    if (document.getElementById("submit-contact-info-button")) {
      document.getElementById("submit-contact-info-button").addEventListener("click", (event) => {
        event.preventDefault();
        this.submitForm();
      });
    }
  },
  unmounted() {
    if (document.getElementById("submit-contact-info-button")) {
      document
        .getElementById("submit-contact-info-button")
        .removeEventListener("click", (event) => {
          event.preventDefault();
          this.submitForm();
        });
    }
  },
  methods: {
    async getCountries() {
      let response = await fetch(this.route("countries"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      if (response.ok) {
        this.countries = await response.json();
        for (const [key, value] of Object.entries(this.countries)) {
          this.countriesForSelect[key] = value.label;
        }
      } else {
        await response.text();
      }
    },
    async registerCustomer() {
      this.submitting.details = true;
      this.registerMessage = null;
      let response = await fetch(this.route("register"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          ...this.form,
          redirectRoute: this.isRegisterPage() ? "homepage" : "article3",
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.message) {
          this.registerMessage = data.message;
          this.submitting.details = false;
        } else {
          window.location = data.redirectUrl;
        }
      } else if (response.status === 422) {
        const body = await response.json();
        this.formErrors = body.errors;
        this.submitting.details = false;
      } else {
        await response.text();
      }
    },
    async updateCustomer() {
      this.submitting.details = true;

      let response = await fetch(this.route("profile"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          ...this.form,
          redirectRoute: this.isRegisterPage() ? "homepage" : "article3",
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success && data.message) {
          if (this.formWarnings.company && (this.pay_on_account || this.pay_on_account_requested)) {
            this.pay_on_account = 0;
            this.pay_on_account_requested = false;
            this.pay_on_account_requesting = false;
            this.formWarnings.company = null;
            // this.form.kvk_number = null;
          }
        }
        window.location = data.redirectUrl;
      } else if (response.status === 422) {
        const body = await response.json();
        this.formErrors = body.errors;
        this.submitting.details = false;
      } else {
        await response.text();
      }
    },
    async validateVatNumber(value) {
      let response = await fetch(
        this.route("vat_number", { country: this.form.country, vatNumber: value }),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        },
      );

      if (response.ok) {
        this.vatResponse = await response.json();
      }
    },
    submitForm() {
      if (this.form.contact_type !== "bedrijf") {
        this.form.vat_number = "";
        this.form.company = "";
      }

      if (this.form.contact_type === "bedrijf" && !this.from_europe) {
        this.form.vat_number = "";
      }

      if (this.isLoggedIn()) {
        delete this.form.password;
        delete this.form.is_guest;
        this.updateCustomer();
      } else {
        this.registerCustomer();
      }
    },
    onFormChange(key) {
      this.dirty = JSON.stringify(this.form) !== JSON.stringify(this.initialForm);
      this.clearErrors(key);
      this.initialType = null;
    },
    async checkForUser() {
      if (!this.isOrderPage()) {
        return;
      }
      if (this.form.email === "") {
        return;
      }
      let response = await fetch(this.route("validate.email"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({ email: this.form.email }),
      });
      let data = await response.json();
      this.existingCustomer = data.success;
    },
    getCircleClass(type) {
      return {
        begincircle: this.initialType === type,
        circletype: this.form.contact_type === type,
      };
    },
    backToOrder() {
      window.location.href = this.route("shoppingcart");
    },
    isRegisterPage() {
      return this.location === "registerpage";
    },
    isOrderPage() {
      return this.location === "article2";
    },
    isLoggedIn() {
      return this.$store.getters.user !== null;
    },
    findCustomerType() {
      if (this.isLoggedIn() && this.$store.getters.user.type) {
        return this.$store.getters.user.type;
      }
      if (this.$store.getters.customer_type && this.$store.getters.customer_type !== "mixed") {
        return this.$store.getters.customer_type;
      }

      return "particulier";
    },
    filterPhoneNumber(value) {
      value = removeLetters(value);
      value = removeSpaces(value);

      this.form.telephone = value;
    },
    filterEmailAddress(value) {
      value = removeSpaces(value);

      this.form.email = value;
    },
    filterVatNumber(value) {
      value = removeSpaces(value);
      value = removeSpecialCharacters(value);

      this.form.vat_number = value.toUpperCase();
    },
    filterZipCode(value) {
      value = removeSpaces(value);
      value = removeSpecialCharacters(value);

      this.form.zipcode = value.toUpperCase();
    },
    filterInvoiceZipCode(value) {
      value = removeSpaces(value);
      value = removeSpecialCharacters(value);

      this.form.invoice_zipcode = value.toUpperCase();
    },
  },
};
</script>
