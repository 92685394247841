<template>
  <div>
    <a
      href="javascript:;"
      class="#group #flex #justify-between #w-full #text-sm #mb-3"
      @click="show = !show"
    >
      <span
        class="#w-max #border-b #border-dotted #border-current group-hover:#border-vividmagenta group-hover:#text-vividmagenta"
      >
        {{ __("Kortingscode") }}
      </span>
      <svg-use
        id="angle-down"
        type="solid"
        class="#blcok #w-[0.9em] #h-[0.9em] #mt-[2px] #fill-inkdropdark #cursor-pointer group-hover:#fill-vividmagenta"
        :class="{ '#rotate-180': show }"
      />
    </a>
    <div class="#grid #transition-all" :class="show ? '#grid-rows-[1fr]' : '#grid-rows-[0fr]'">
      <div class="#flex #items-start #justify-between #gap-x-[10px] #pt-2 #overflow-hidden">
        <InputField
          ref="couponCode"
          :model-value="modelValue"
          class="#w-full #mb-6 #pt-2"
          :label="__('Kortingscode')"
          type="text"
          :error="error"
          @update:model-value="(value) => $emit('update:modelValue', value)"
          @keyupenter="$emit('submit')"
        />
        <Button
          :text="__('Activeren')"
          btn-class="primary"
          class="#text-center #mt-2"
          @click="$emit('submit')"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import { lang } from "../mixins";
import SvgUse from "../components/SvgUse.vue";
import Button from "../components/Button.vue";
import InputField from "./InputField.vue";

export default {
  name: "CouponCode",
  components: {
    SvgUse,
    Button,
    InputField,
  },
  mixins: [lang],
  props: {
    error: {
      type: String,
      required: false,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "submit"],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closemenu() {
      this.show = false;
    },
  },
};
</script>
