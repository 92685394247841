<template>
  <label class="#group #w-full #mb-0" :class="{ '#cursor-pointer': modelValue !== optionValue }">
    <input
      :id="inputId"
      v-model="value"
      type="radio"
      name="paymentmethod"
      :value="optionValue"
      :disabled="disabled"
      class="#peer/radio #sr-only"
    />
    <div
      class="#w-[16px] #min-w-[16px] #h-[16px] #min-h-[16px] #rounded-full #border-[1px] #border-solid #border-[#d5d5d5] #inline-flex #justify-center #items-center peer-checked/radio:*:#bg-brightcyan"
      :class="{ 'group-hover:#border-brightcyan': modelValue !== optionValue }"
    >
      <div class="#w-[8px] #h-[8px] #rounded-full"></div>
    </div>
    <slot></slot>
  </label>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    error: {
      type: Boolean,
      default: false,
    },
    inputId: {
      type: String,
      default: "",
    },
    optionValue: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
