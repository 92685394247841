<template>
  <div
    class="imperfect-rectangle #flex #gap-x-4 #justify-between #items-center #px-6 #pt-6 #pb-4 sm:#py-4 md:#sticky #bottom-6 #min-h-[70px] #max-w-[1000px] #mx-auto"
    :class="messageStyling"
  >
    <div class="#flex #items-start #justify-start #gap-x-2">
      <svg v-if="type === 'info'" class="#h-[1em] #min-w-[17px] #w-[17px] #fill-white">
        <use xlink:href="/icons/solid.svg#circle-info"></use>
      </svg>
      <svg v-else-if="type === 'error'" class="#h-[1em] #min-w-[17px] #w-[17px] #fill-white">
        <use xlink:href="/icons/solid.svg#circle-exclamation"></use>
      </svg>
      <div
        class="#text-xs #text-white #font-semibold sm:#text-sm [&_a]:#border-b [&_a]:#border-dotted [&_a]:#border-current [&_a]:hover:!#text-vividmagenta [&_a]:#cursor-pointer"
        v-html="message.label"
      ></div>
    </div>
    <div v-if="timer" class="#self-start #flex #items-center #gap-x-4">
      <div class="#relative #rounded-full #overflow-hidden #w-10 #h-2 #mt-2 #bg-inkdropdark">
        <div
          class="#bg-white #w-10 #h-2 #origin-left #transition-all #animate-[messageTimer_5s_linear_forwards]"
        ></div>
      </div>
      <a href="javascript:" class="#text-[25px] #font-bold #leading-none #text-white" @click="clear"
        >x</a
      >
    </div>
  </div>
</template>

<script>
import { lang } from "../mixins";
export default {
  mixins: [lang],
  props: {
    message: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "info",
    },
    timer: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["clear"],
  computed: {
    messageStyling() {
      return {
        "#bg-brightcyan": this.type === "info",
        "!#bg-[#d9534f]": this.type === "error",
      };
    },
  },
  mounted() {
    if (this.type !== "error") {
      this.addMessageTimeout();
    }
  },
  methods: {
    addMessageTimeout() {
      this.messagesTimeout = setTimeout(() => {
        this.clear();
      }, 5000);
    },
    clear() {
      clearTimeout(this.messagesTimeout);
      this.$emit("clear", this.message.id);
    },
  },
};
</script>
